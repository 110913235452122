export default {
    type: [
        {
            uid: 'one',
            name: '1',
        },
        {
            uid: 'two',
            name: '2',
        },
        {
            uid: 'three',
            name: '3',
        },
        {
            uid: 'four',
            name: '4',
        },
        {
            uid: 'five',
            name: '5+',
        },
    ],
};