import {
    RESET_INBOX,
    UPDATE_INBOX,
    FETCH_INBOX,
    FETCH_INBOX_DONE,
    FETCH_INBOX_FAIL,
    FETCH_CHAT,
    FETCH_CHAT_DONE,
    FETCH_CHAT_FAIL,
    UPDATE_CHAT,
    FETCH_CHAT_MESSAGE,
    FETCH_CHAT_MESSAGE_DONE,
    UPDATE_INBOX_SEEN,
} from 'actions/inbox';

const initialState = {
    loading: false,
    list: [],
    chat:{
        loading: false,
        isActive: false,
        profile: {
            name: '',
            img: '',
            uid: '',
        },
        messages: [],
        text: '',
        isValid: false,
    },
    successMessage: '',
    failMessage: '',
};

const inboxReducer = (state = initialState, action) => {
    switch (action.type) {
    case RESET_INBOX:
        return {
            ...state,
            chat: {
                loading: false,
                isActive: false,
                profile: {
                    name: '',
                    img: '',
                    uid: '',
                },
                messages: [],
                text: '',
                isValid: false,
            },
            successMessage: '',
            failMessage: '',
        };
    case UPDATE_INBOX:{
        return {
            ...state,
            chat: {
                ...state.chat,
                loading: false,
                isActive: true,
                profile: {
                    name: action.name,
                    img: action.img,
                    uid: action.listenerId,
                },
                text: '',
                isValid: false,
            },
            successMessage: '',
            failMessage: '',
        };
    }
    case UPDATE_CHAT:
        return {
            ...state,
            chat: {
                ...state.chat,
                text: action.newValue,
                isValid: !action.errors,
            },
        };
    case FETCH_INBOX:
        return {
            ...state,
            loading: true,
        };
    case FETCH_INBOX_DONE:
        return {
            ...state,
            loading: false,
            list: action.list,
        };
    case FETCH_INBOX_FAIL:
        return {
            ...state,
            loading: false,
            failMessage: action.message,
        };
    case FETCH_CHAT:
        return {
            ...state,
            chat: {
                ...state.chat,
                loading: true,
                isActive: false,
                messages: [],
                text: '',
                isValid: false,
            },
        };
    case UPDATE_INBOX_SEEN:{
        const index = state.list.findIndex(data => data.uid === action.inboxId);
        const newList = [...state.list];
        newList[index] = {
            ...newList[index],
            seen: true,
        };
        return {
            ...state,
            list: newList,
        };
    }
    case FETCH_CHAT_DONE:{
        return {
            ...state,
            chat: {
                ...state.chat,
                loading: false,
                isActive: true,
                messages: action.messages,
            },
        };
    }
    case FETCH_CHAT_FAIL:
        return {
            ...state,
            chat: {
                ...state.chat,
                loading: false,
            },
            failMessage: action.message,
        };
    case FETCH_CHAT_MESSAGE:
        return {
            ...state,
            chat: {
                ...state.chat,
                loading: true,
            },
        };
    case FETCH_CHAT_MESSAGE_DONE:
        return {
            ...state,
            chat: {
                ...state.chat,
                loading: false,
                messages: action.data,
                text: '',
                isValid: false,
            },
        };
    default:
        return state;
    }
};
export default inboxReducer;
