/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const styles = (theme) => ({
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    left: {
        flexDirection: 'row',
    },
    right: {
        flexDirection: 'row-reverse',
    },
    hide:{
        opacity: 0,
    },
    media: {
        width: 40,
        height: 40,
        textTransform: 'uppercase',
        backgroundColor: "#00abbd",
        [theme.breakpoints.down('sm')]: {
            width: 25,
            height: 25,
            fontSize: '1rem',
        },
    },
    chip:{
        height: 'auto',
    },
    p:{
        whiteSpace: 'normal',
        margin: 0,
        lineHeight: 1.4,
        padding: '8px 0',
    }
});

function Message(props) {
    const {
        classes,
        message,
        side,
        isLast,
        name,
        img,
        time,
    } = props;

    return (
        <Box 
            mt={1} 
            className={clsx(classes.message, side === 'left' ? classes.left : classes.right)}
        >
            {
                isLast ?
                    <>
                        <Box>
                            {
                                img ?
                                    <Avatar alt={name} src={img} className={clsx(classes.media, !isLast && classes.hide)} />
                                    :
                                    <Avatar color="primary" className={clsx(classes.media, !isLast && classes.hide)}>{name ? name.charAt(0) : ''}</Avatar>
                            }
                        </Box>
                        <Box ml={{ xs: 1, md: 2 }} mr={{ xs: 1, md: 2 }}>
                            <Chip
                                className={classes.chip}
                                label={
                                    <p className={classes.p}>
                                        {message}
                                    </p>
                                }
                                color={side === 'right' ? "primary" : "default"}
                            />
                        </Box>
                    </>
                    :
                    <>
                        {
                            side === 'right' ?
                                <Box ml={{ xs: 1, md: 2 }}>
                                    <Chip
                                        className={classes.chip}
                                        label={
                                            <p className={classes.p}>
                                                {message}
                                            </p>
                                        }
                                        color="primary"
                                    />
                                </Box>
                                :
                                <Box mr={{ xs: 1, md: 2 }}>
                                    <Chip
                                        className={classes.chip}
                                        color="default"
                                        label={
                                            <p className={classes.p}>
                                                {message}
                                            </p>
                                        }
                                    />
                                </Box>
                        }
                    </>
            }
            <Box>
                <Typography variant="caption" style={{color: '#c7c7c7'}}>
                    {time}
                </Typography>
            </Box>
        </Box>
    );
}

Message.propTypes = {
    // lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    side: PropTypes.string.isRequired,
    isLast: PropTypes.bool,
    name: PropTypes.string.isRequired,
    img: PropTypes.string,
    time: PropTypes.string.isRequired,
};

Message.defaultProps = {
    isLast: false,
    img: '',
};

export default withStyles(styles)(Message);
