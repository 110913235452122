import {
    SET_ARTICLE_LANG,
    SET_ARTICLE_CATEGORY,
    SET_ARTICLE_TAG,
    REMOVE_ARTICLE_TAG,
    SET_ARTICLE_LOCATION,
    SET_ARTICLE_COVER,
    UPDATE_ARTICLE,
    RESET_ARTICLE,
    FETCH_ARTICLE,
    FETCH_ARTICLE_DATA_DONE,
    FETCH_ARTICLE_DONE,
    FETCH_ARTICLE_FAIL,
    UPDATE_ARTICLE_SEARCH,
    FETCH_ARTICLE_LIST_DONE,
    DELETE_ARTICLE,
    FETCH_ARTICLE_CREATE_DATA_DONE,
    CHANGE_ARTICLE_STATUS,
    FETCH_ARTICLE_LOADMORE,
    FETCH_ARTICLE_LOADMORE_DONE,
    FETCH_ARTICLE_SHOWLESS,
    UPDATE_ARTICLE_INDEX,
    FETCH_ARTICLE_INDEX_DONE,
} from '../actions/articles';

const initialState = {
    loading: false,
    lang: 'th',
    isSearching: false,
    list: [],
    loadingMore: false,
    showLoadingMore: true,
    startIndex: 1,
    endIndex: 25,
    numberOfDocs: 0,
    data: {
        categoryId: '',
        subCatId: '',
        tagId: [],
        locationId: {},
        cover: '',
        search: '',
        textOverCover: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
        name: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
        desc: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
    articleStatus: '',
};

const articleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ARTICLE_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case FETCH_ARTICLE_CREATE_DATA_DONE:
            return {
                ...state,
                loading: false,
            };
        case SET_ARTICLE_CATEGORY:
            return {
                ...state,
                data: {
                    ...state.data,
                    categoryId: action.catId,
                    subCatId: action.subCatId,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case SET_ARTICLE_TAG:
            return {
                ...state,
                data: {
                    ...state.data,
                    tagId: action.tags,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case REMOVE_ARTICLE_TAG: {
            const tagList = [...state.data.tagId];
            tagList.splice(action.index, 1);
            return {
                ...state,
                data: {
                    ...state.data,
                    tagId: tagList,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        }
        case SET_ARTICLE_LOCATION:
            return {
                ...state,
                data: {
                    ...state.data,
                    locationId: action.locationId,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case SET_ARTICLE_COVER:
            return {
                ...state,
                data: {
                    ...state.data,
                    cover: action.cover,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case RESET_ARTICLE:
            return {
                ...initialState,
                loading: true,
                data: {
                    categoryId: '',
                    subCatId: '',
                    tagId: [],
                    locationId: '',
                    cover: '',
                    search: '',
                    textOverCover: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                    name: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                    desc: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                },
            };
        case UPDATE_ARTICLE:
            if (action.lang === 'th' && action.fieldName === 'name') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        search: action.newValue,
                        name: {
                            ...state.data.name,
                            [action.lang]: action.newValue,
                        },
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: {
                        ...state.data[action.fieldName],
                        [action.lang]: action.newValue,
                    },
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case UPDATE_ARTICLE_SEARCH:
            return {
                ...state,
                isSearching: action.newValue !== '',
            };
        case FETCH_ARTICLE:
            return {
                ...state,
                loading: true,
                loadingMore: false,
                showLoadingMore: true,
            };
        case FETCH_ARTICLE_LOADMORE:
            return {
                ...state,
                loadingMore: true,
            };
        case FETCH_ARTICLE_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_ARTICLE_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
                articleStatus: action.status,
            };
        case FETCH_ARTICLE_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        case FETCH_ARTICLE_LIST_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                startIndex: 1,
                endIndex: action.list.length,
                numberOfDocs: action.numberOfDocs,
                showLoadingMore: action.list.length < action.numberOfDocs,
            };
        case FETCH_ARTICLE_INDEX_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                showLoadingMore: (state.startIndex + action.list.length - 1) < action.numberOfDocs,
                endIndex: (state.startIndex + action.list.length - 1),
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_ARTICLE_LOADMORE_DONE:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: (state.endIndex + action.list.length) < state.numberOfDocs,
                endIndex: state.endIndex + action.list.length,
                list: [
                    ...state.list,
                    ...action.list,
                ],
                numberOfDocs: action.list.length < 25 ? state.endIndex + action.list.length : action.numberOfDocs,
            };
        case FETCH_ARTICLE_SHOWLESS:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: true,
                list: [
                    ...state.list.slice(0, state.list.length - 25),
                ],
                endIndex: state.endIndex - 25,
            };
        case UPDATE_ARTICLE_INDEX:
            return {
                ...state,
                startIndex: action.startIndex,
            };
        case DELETE_ARTICLE:
            const index = state.list.findIndex(data => data.uid === action.uid);
            let newList = [...state.list];
            newList.splice(index, 1);
            return {
                ...state,
                list: newList
            };
        case CHANGE_ARTICLE_STATUS:
            const index2 = state.list.findIndex(data => data.uid === action.uid);
            let newList2 = [...state.list];
            newList2[index2] = {
                ...newList2[index2],
                isHide: action.newStatus
            };
            return {
                ...state,
                list: newList2,
            };
        default:
            return state;
    }
};
export default articleReducer;
