import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { BeatLoader } from 'react-spinners';

const styles = theme => ({
    button: {
        width: '100%',
        height: 56,
        [theme.breakpoints.down('sm')]: {
            height: 48,
        },
        '& span':{
            '& div':{
                display: 'flex',
            },
        }
    },
    noShadow:{
        boxShadow: 'none',
        '&:active':{
            boxShadow: 'none',
        }
    },
});

function MyButton(props) {
    const { 
        color, 
        children, 
        variant, 
        classes, 
        onClick, 
        noShadow,
        disabled,
        loading,
    } = props;
    return (
        <>
            {
                loading ? 
                    <Button 
                        variant={variant}
                        color={color}
                        className={clsx(classes.button, noShadow && classes.noShadow)}
                    >
                        <BeatLoader
                            sizeUnit="px"
                            size={15}
                            color='#ffffff'
                        />
                    </Button>
                    :
                    <Button
                        variant={variant}
                        color={color}
                        className={clsx(classes.button, noShadow && classes.noShadow)}
                        onClick={onClick}
                        disabled={disabled}
                    >
                        {children}
                    </Button>
            }
        </>
    );
}

MyButton.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    color: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    noShadow: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
};

MyButton.defaultProps = {
    onClick() { console.log('Button Click'); },
    noShadow: false,
    disabled: false,
    loading: false,
};

export default withStyles(styles)(MyButton);