import {
    SET_TAG_LANG,
    UPDATE_TAG,
    FETCH_TAG,
    FETCH_TAG_DATA_DONE,
    FETCH_TAG_DONE,
    FETCH_TAG_FAIL,
    RESET_TAG,
    FETCH_TAG_LIST_DONE,
    DELETE_TAG,
    FETCH_TAG_LOADMORE,
    FETCH_TAG_LOADMORE_DONE,
    FETCH_TAG_SHOWLESS,
    UPDATE_TAG_INDEX,
    FETCH_TAG_INDEX_DONE,
    UPDATE_TAG_SEARCH,
} from '../actions/tags';

const initialState = {
    loading: false,
    lang: 'th',
    isSearching: false,
    tagList: [],
    loadingMore: false,
    showLoadingMore: true,
    startIndex: 1,
    endIndex: 25,
    numberOfDocs: 0,
    tag: {
        search: '',
        name: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_TAG_SEARCH:
            return {
                ...state,
                isSearching: action.text !== '',
            };
        case SET_TAG_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case RESET_TAG:
            return {
                ...initialState,
                tag: {
                    search: '',
                    name: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                },
            };
        case UPDATE_TAG:
            if (action.lang === 'th'){
                return {
                    ...state,
                    tag: {
                        ...state.tag,
                        search: action.newValue,
                        name: {
                            ...state.tag.name,
                            [action.lang]: action.newValue,
                        },
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            }
            return {
                ...state,
                tag: {
                    ...state.tag,
                    name: {
                        ...state.tag.name,
                        [action.lang]: action.newValue,
                    },
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case FETCH_TAG:
            return {
                ...state,
                loading: true,
                loadingMore: false,
                showLoadingMore: true,
            };
        case FETCH_TAG_LOADMORE:
            return {
                ...state,
                loadingMore: true,
            };
        case FETCH_TAG_DATA_DONE:
            return {
                ...state,
                loading: false,
                tag: action.tag,
            };
        case FETCH_TAG_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_TAG_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        case FETCH_TAG_LIST_DONE:
            return {
                ...state,
                loading: false,
                tagList: action.tagList,
                loadingMore: false,
                showLoadingMore: action.tagList.length < action.numberOfDocs,
                startIndex: 1,
                endIndex: action.tagList.length,
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_TAG_INDEX_DONE:
            return {
                ...state,
                loading: false,
                tagList: action.tagList,
                loadingMore: false,
                showLoadingMore: (state.startIndex + action.tagList.length - 1) < action.numberOfDocs,
                endIndex: (state.startIndex + action.tagList.length - 1),
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_TAG_LOADMORE_DONE:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: (state.endIndex + action.list.length) < state.numberOfDocs,
                endIndex: state.endIndex + action.list.length,
                tagList: [
                    ...state.tagList,
                    ...action.list,
                ],
                numberOfDocs: action.list.length < 25 ? state.endIndex + action.list.length : action.numberOfDocs,
            };
        case FETCH_TAG_SHOWLESS:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: true,
                tagList: [
                    ...state.tagList.slice(0, state.tagList.length - 25),
                ],
                endIndex: state.endIndex - 25,
            };
        case UPDATE_TAG_INDEX:
            return {
                ...state,
                startIndex: action.startIndex,
            };
        case DELETE_TAG:
            const index = state.tagList.findIndex(tag => tag.uid === action.uid);
            let newList = [...state.tagList];
            newList.splice(index, 1);
            return {
                ...state,
                tagList: newList
            };
        default:
            return state;
    }
};
export default tagsReducer;
