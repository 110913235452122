import {
    UPDATE_PASSWORD,
    IS_LOADING,
    RESET_PASSWORD,
    LOADING_DONE,
    LOADING_FAIL,
} from '../actions/resetPassword';

const initialState = {
    isValid: false,
    loading: false,
    data: {
        password: '',
        confirmPassword: '',
    },
    errors: {},
    touched: {},
    successMessage: '',
    failMessage: '',
};

const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PASSWORD:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: action.newValue
                },
                touched: {
                    ...state.touched,
                    [action.fieldName]: true,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case IS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case RESET_PASSWORD:
            return {
                ...state,
                loading: true,
            };
        case LOADING_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.successMessage,
                failMessage: '',
            };
        case LOADING_FAIL:
            return {
                ...state,
                loading: false,
                successMessage: '',
                failMessage: action.failMessage,
            };
        default:
            return state;
    }
};
export default resetPasswordReducer;