import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import {
    connectHits,
} from 'react-instantsearch-dom';
import moment from 'moment';
import Loadmore from 'components/Loadmore';
import List from './List';
import LoadingList from './LoadingList';

const useStyles = makeStyles(() => ({
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const Hits = ({ hits, resetData, postDelete }) => {
    return (
        <>
            {
                hits.map(hit => {
                    const item = {
                        uid: hit.uid,
                        timestamp: hit.timestamp,
                        search: hit.nameTh,
                        title: {
                            th: hit.nameTh,
                            en: hit.nameEn,
                            ch: hit.nameCh,
                            jp: hit.nameJp,
                        },
                        img: hit.img,
                        typeId: hit.typeId,
                        yearDone: hit.yearDone,
                        createdAt: moment(hit.timestamp).format('D MMM YYYY, HH:mm'),
                    }
                    return (
                        <List
                            data={item}
                            key={item.uid}
                            resetData={resetData}
                            postDelete={postDelete}
                        />
                    );
                })
            }
        </>
    );
};
const CustomHits = connectHits(Hits);

const UsersTable = props => {
    const { 
        isSearching,
        list,
        resetData,
        loading,
        postDelete,
        loadingMore,
        showLoadingMore,
        startIndex,
        endIndex,
        numberOfDocs,
        loadmoreFunc,
        showlessFunc,
        queryFunc,
    } = props;

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Year Done</TableCell>
                                    <TableCell>Language</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isSearching ?
                                    <CustomHits
                                        resetData={resetData}
                                        postDelete={postDelete}
                                    />
                                    :
                                    <>
                                        {loading ?
                                            _.times(10, i =>
                                                <LoadingList key={i} />
                                            )
                                            :
                                            list.map(item => (
                                                <List
                                                    data={item}
                                                    key={item.uid}
                                                    resetData={resetData}
                                                    postDelete={postDelete}
                                                />
                                            ))
                                        }
                                        {
                                            loadingMore &&
                                            _.times(10, i =>
                                                <LoadingList key={i} />
                                            )
                                        }
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            {(!loading && !loadingMore && !isSearching) &&
                <Loadmore
                    startIndex={startIndex}
                    endIndex={endIndex}
                    listLength={numberOfDocs}
                    loadmoreFunc={loadmoreFunc}
                    showlessFunc={showlessFunc}
                    queryFunc={queryFunc}
                    showlessDisabled={list.length <= 25}
                    showLoadingMore={showLoadingMore}
                />
            }
        </Card>
    );
};

export default UsersTable;
