import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    CardContent,
    Divider,
    Button,
    Typography,
    Box,
} from '@material-ui/core';
import ArticleSearch from 'components/ArticleSearch';
import {
    changeArticleData,
    savePageArticle,
} from 'actions/pages';

const useStyles = makeStyles(() => ({
    content: {
        padding: 16,
        position: 'relative',
    },
    searchResult: {
        borderRadius: 4,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #e9e9e9',
        maxHeight: '50vh',
        minHeight: 0,
        height: 'auto',
        overflow: 'scroll',
        padding: 0,
    },
    list: {
        padding: '12px 30px',
        '&:hover svg': {
            color: "#00abbd",
        }
    },
    lists: {
        padding: 0,
    }
}));

const AccountDetails = (props) => {
    const classes = useStyles();
    const {
        uid,
        loading,
        topics,
        isValid,
        changeValue,
        saveChange,
    } = props;

    console.log(topics)

    // Submit Form
    const handleSave = () => {
        saveChange(uid,topics);
    };

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <Box mb={2}>
                    <Typography
                        gutterBottom
                        variant="h5"
                    >
                        Recommened Article
                    </Typography>
                </Box>
                <Box mb={2}>
                    <ArticleSearch
                        loading={loading}
                        selectedValue={topics ? topics[0] : ''}
                        update={(loc) => changeValue(loc, 0)}
                    />
                </Box>
                <Box mb={2}>
                    <ArticleSearch
                        loading={loading}
                        selectedValue={topics ? topics[1] : ''}
                        update={(loc) => changeValue(loc, 1)}
                    />
                </Box>
                {
                    uid === 'home' &&
                    <>
                        <Box mb={2}>
                            <ArticleSearch
                                loading={loading}
                                selectedValue={topics ? topics[2] : ''}
                                update={(loc) => changeValue(loc, 2)}
                            />
                        </Box>
                        <Box mb={2}>
                            <ArticleSearch
                                loading={loading}
                                selectedValue={topics ? topics[3] : ''}
                                update={(loc) => changeValue(loc, 3)}
                            />
                        </Box>
                        <Box mb={3}>
                            <ArticleSearch
                                loading={loading}
                                selectedValue={topics ? topics[4] : ''}
                                update={(loc) => changeValue(loc, 4)}
                            />
                        </Box>
                    </>
                }
            </CardContent>
            
            <Divider />
            <CardActions style={{ padding: 16 }}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </CardActions>
        </Card>
    );
};

const mapStateToProps = state => ({
    isValid: state.pages.articleIsValid,
});

const mapDispatchToProps = {
    changeValue : changeArticleData,
    saveChange: savePageArticle,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountDetails);
