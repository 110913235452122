import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Toolbar, Table } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));

const AllList = (props) => {
    const classes = useStyles();
    const { 
        loading,
        adsList,
        getAllAdsList,
        resetAds,
        toggleStatus,
        postDeleteAds,
        loadingMore,
        showLoadingMore,
        startIndex,
        endIndex,
        numberOfDocs,
        loadmore,
        showless,
        queryByIndex,
    } = props;

    const lastVisibleUid = adsList[adsList.length - 1] ? adsList[adsList.length - 1].uid : '';

    React.useEffect(() => {
        getAllAdsList();
    }, [getAllAdsList]);

    return (
        <div className={classes.root}>
            <Toolbar 
                tabActive="all" 
                resetAds={resetAds}
            />
            <div className={classes.content}>
                <Table 
                    adsPage="all"
                    list={adsList} 
                    resetAds={resetAds}
                    loading={loading}
                    toggleStatus={toggleStatus}
                    postDeleteAds={postDeleteAds} 
                    loadingMore={loadingMore}
                    showLoadingMore={showLoadingMore}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    numberOfDocs={numberOfDocs}
                    loadmoreFunc={() => loadmore('all',lastVisibleUid)}
                    showlessFunc={showless}
                    queryFunc={(start,end) => queryByIndex('all', start, end)}
                />
            </div>
        </div>
    );
};

export default AllList;
