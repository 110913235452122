import { connect } from 'react-redux';
import { 
    updateUserEdit,
    postUserEdit, 
    postProfile, 
    removeProfile,
    getUserEdit,
} from 'actions/userUpdate';

import UserUpdate from 'views/User/Update';

const mapStateToProps = state => ({
    loading: state.userUpdate.loading,
    user: state.userUpdate.user,
    userErrors: state.userUpdate.userErrors,
    userIsValid: state.userUpdate.userIsValid,
    userTouched: state.userUpdate.userTouched,
    userSuccessMessage: state.userUpdate.userSuccessMessage,
    userFailMessage: state.userUpdate.userFailMessage,
    profileIsLoading: state.userUpdate.profileIsLoading,

});

const mapDispatchToProps = {
    updateUserEdit,
    postUserEdit, 
    postProfile,
    removeProfile,
    getUserEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserUpdate);

