import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Toolbar, DraftTable } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));

const UserList = (props) => {
    const classes = useStyles();
    const {
        loading,
        list,
        getList,
        postDelete,
        resetData,
        loadingMore,
        showLoadingMore,
        startIndex,
        endIndex,
        numberOfDocs,
        loadmore,
        showless,
        queryByIndex,
        location,
        showSnack, 
    } = props;

    const lastVisibleUid = list[list.length - 1] ? list[list.length - 1].uid : '';

    let categoryId = location.pathname.split('/').slice(-1)[0];
    categoryId = categoryId === 'draft' ? 'all' : categoryId;

    const categoryListId = [
        {
            title: 'All',
            href: '/articles/draft',
            value: 'all',
        },
        {
            title: 'Finance',
            href: '/articles/draft/finance',
            value: 'finance',
        },
        {
            title: 'Inspiration',
            href: '/articles/draft/inspiration',
            value: 'inspiration',
        },
        {
            title: 'Lifestyle',
            href: '/articles/draft/lifestyle',
            value: 'lifestyle',
        },
        {
            title: 'Live',
            href: '/articles/draft/live',
            value: 'live',
        },
    ];

    React.useEffect(() => {
        getList('draft', categoryId === 'all' ? '' : categoryId );
    }, [getList, categoryId]);

    return (
        <div className={classes.root}>
            <Toolbar 
                tabActive="draft" 
                resetData={resetData}
                categoryId={categoryId}
                categoryListId={categoryListId}
            />
            <div className={classes.content}>
                <DraftTable 
                    list={list}
                    resetData={resetData}
                    loading={loading}
                    postDelete={postDelete}
                    loadingMore={loadingMore}
                    showLoadingMore={showLoadingMore}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    numberOfDocs={numberOfDocs}
                    loadmoreFunc={() => loadmore('draft', categoryId, lastVisibleUid)}
                    showlessFunc={showless}
                    queryFunc={(start,end) => queryByIndex('draft', categoryId, start, end)}
                    showSnack={showSnack}
                />
            </div>
        </div>
    );
};

export default withRouter(UserList);
