import { connect } from 'react-redux';
import {
    getSideAdsList,
    toggleStatus,
    postDeleteAds,
    resetAds,
    loadmore,
    showless,
    queryByIndex,
} from 'actions/ads';
import AdsHome from 'views/Ads/Side';

const mapStateToProps = state => ({
    loading: state.ads.loading,
    adsList: state.ads.adsList,
    loadingMore: state.ads.loadingMore,
    showLoadingMore: state.ads.showLoadingMore,
    startIndex: state.ads.startIndex,
    endIndex: state.ads.endIndex,
    numberOfDocs: state.ads.numberOfDocs,
});

const mapDispatchToProps = {
    getSideAdsList,
    resetAds,
    toggleStatus,
    postDeleteAds,
    loadmore,
    showless,
    queryByIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsHome);
