export default {
    type: [
        {
            uid: 'condo',
            name: 'คอนโด',
        },
        {
            uid: 'house',
            name: 'บ้านเดี่ยว',
        },
        {
            uid: 'townhouse',
            name: 'ทาวน์เฮ้าส์',
        },
        {
            uid: 'land',
            name: 'ที่ดิน',
        },
        {
            uid: 'apartment',
            name: 'อพาร์ทเม้นท์',
        },
        {
            uid: 'commercialBuilding',
            name: 'อาคารพาณิชย์',
        },
        {
            uid: 'office',
            name: 'ออฟฟิศ',
        },
        {
            uid: 'retailSpace',
            name: 'พื้นที่ค้าปลีก',
        },
    ],
};
