import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Details } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Create = (props) => {
    const classes = useStyles();
    const {
        loading,
        ads,
        errors,
        touched,
        isValid,
        successMessage,
        failMessage,
        updateAds,
        postImage,
        postAdsCreate,
    } = props;

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid item xs={12}>
                    <Details 
                        title="Create Ads" 
                        loading={loading}
                        ads={ads}
                        errors={errors}
                        touched={touched}
                        isValid={isValid}
                        successMessage={successMessage}
                        failMessage={failMessage}
                        updateAds={updateAds}
                        postImage={postImage}
                        postAdsCreate={postAdsCreate}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default Create;
