import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardMedia,
    Typography,
    Box,
} from '@material-ui/core';
import ImageExample from './example.svg';

const useStyles = makeStyles(() => ({
    root: {
        padding: 16,
        position: 'relative',
    },
    media: {
        width: '100%',
        height: 'auto',
        boxSizing: 'border-box',
        borderRadius: 0,
    },
}));

const AccountDetails = () => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <Box mb={3}>
                <Typography
                    gutterBottom
                    variant="h5"
                >
                    Layout
                </Typography>
            </Box>
            <CardMedia
                component='img'
                src={ImageExample}
                className={classes.media}
            />
        </Card>
    );
};

export default AccountDetails;
