import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { BeatLoader } from 'react-spinners';
import FacebookIcon from '@material-ui/icons/Facebook';
import GoogleIcon from './google.svg';

const styles = theme => ({
    button: {
        width: '100%',
        height: 56,
        boxShadow: 'none',
        '&:active': {
            boxShadow: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            height: 48,
        },
        '& span':{
            '& div':{
                display: 'flex',
            },
        }
    },
    facebook:{
        backgroundColor: '#4268b2',
        '&:hover':{
            backgroundColor: '#335aa7',
        }
    },
    google:{
        backgroundColor: '#666666',
        '&:hover': {
            backgroundColor: '#545454',
        }
    }
});

function MyButton(props) {
    const { 
        children, 
        social, 
        classes, 
        onClick, 
        disabled,
        loading,
    } = props;
    return (
        <>
            {
                loading ? 
                    <Button
                        color="primary" 
                        variant="contained"
                        className={clsx(classes.button, social === 'facebook' ? classes.facebook : classes.google)}
                    >
                        <BeatLoader
                            sizeUnit="px"
                            size={15}
                            color='#ffffff'
                        />
                    </Button>
                    :
                    <Button
                        color="primary"
                        variant="contained"
                        className={clsx(classes.button, social === 'facebook' ? classes.facebook : classes.google)}
                        onClick={onClick}
                        disabled={disabled}
                    >
                        {
                            social === 'facebook' ? 
                                <FacebookIcon
                                    style={{
                                        fontSize: 32,
                                        marginRight: 8,
                                    }}
                                />
                                :
                                <img 
                                    style={{
                                        width: 24,
                                        marginRight: 8,
                                    }}
                                    src={GoogleIcon} 
                                    alt="google icon" 
                                />
                        }
                        {children}
                    </Button>
            }
        </>
    );
}

MyButton.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    social: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
};

MyButton.defaultProps = {
    onClick() { console.log('Button Click'); },
    disabled: false,
    loading: false,
};

export default withStyles(styles)(MyButton);