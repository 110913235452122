import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TableCell, TableRow } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

const useStyles = makeStyles(theme => ({
    root: {
        left: -12,
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2),
        textTransform: 'uppercase',
        backgroundColor: "#00abbd",
    },
}));

const UsersTableList = () => {
    const classes = useStyles();
    
    return (
        <TableRow
            className={classes.tableRow}
        >
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
        </TableRow>             
    );
};

export default UsersTableList;
