import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from 'react-loading-skeleton';

const styles = (theme) => ({
    card: {
        borderRadius: 0,
        padding: 16,
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            borderBottom: 'solid 1px #c7c7c7',
        },
    },
    seen:{
        alignItems: 'flex-end',
    },
    active:{
        backgroundColor: '#f5fcfd',
    },
    left:{
        display: 'flex',
    },
    media: {
        width: 53,
        height: 53,
        textTransform: 'uppercase',
        backgroundColor: "#00abbd",
    },
    content: {
        padding: '0 0 0 12px !important',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    name:{
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.71,
        letterSpacing: 'normal',
    },
    date:{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    notSeen:{
        width: 10,
        height: 10,
        backgroundColor: "#f06642",
        borderRadius: '50%',
    },
    link:{
        textDecoration: 'none',
        color: 'inherit',
    }
});

function CardPaper(props) {
    const { 
        classes, 
        loading,
        uid,
        img, 
        name, 
        desc, 
        date,
        seen,
        active,
        onClick, 
    } = props;

    return (
        <Link to={`/inbox/${uid}`} className={classes.link}>
            <CardActionArea 
                className={clsx(classes.card, active && classes.active, seen && classes.seen)}
                onClick={onClick}
            >
                <div className={classes.left}>
                    <div className={classes.profile}>
                        {
                            loading ? 
                                <Skeleton width={53} height={53} /> 
                                : 
                                <>
                                    {
                                        img ?
                                            <Avatar alt={name} src={img} className={classes.media} />
                                            :
                                            <Avatar color="primary" className={classes.media}>{name.charAt(0)}</Avatar>
                                    }
                                </>
                        }
                    </div>
                    <CardContent className={classes.content}>
                        <Box>
                            <Typography 
                                variant="body1" 
                                color="textPrimary"
                                className={classes.name}
                            >
                                {loading ? <Skeleton width={150} /> : name}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="textSecondary">
                                {loading ? <Skeleton width={70} /> : desc}
                            </Typography>
                        </Box>
                    </CardContent>
                </div>
                <div className={classes.date}>
                    {
                        (!seen && !loading) && 
                            <Box>
                                <div className={classes.notSeen} />
                            </Box>
                    }
                    <Box>
                        <Typography variant="overline" style={{ color: '#c7c7c7' }}>
                            {loading ? <Skeleton width={50} /> : date}
                        </Typography>
                    </Box>
                </div>
            </CardActionArea>
        </Link>
    );
}

CardPaper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    uid: PropTypes.string,
    img: PropTypes.string,
    name: PropTypes.string,
    desc: PropTypes.string,
    date: PropTypes.string,
    seen: PropTypes.bool,
    active: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
};

CardPaper.defaultProps = {
    uid: '',
    img: '',
    name: '',
    desc: '',
    date: '',
    seen: true,
    active: false,
    loading: true,
    onClick: console.log('click'),
};

export default withStyles(styles)(CardPaper);