import {
    FETCH_USER,
    FETCH_USER_DONE,
    UPDATE_DETAIL,
    FETCH_DETAIL,
    FETCH_DETAIL_DONE,
    UPDATE_EMAIL,
    FETCH_EMAIL,
    FETCH_EMAIL_DONE,
    FETCH_EMAIL_FAIL,
    UPDATE_PASSWORD,
    FETCH_PASSWORD,
    FETCH_PASSWORD_DONE,
    FETCH_PASSWORD_FAIL,
    FETCH_PROFILE,
    FETCH_PROFILE_DONE,
    FETCH_PROFILE_FAIL,
} from '../actions/settings';

const initialState = {
    loading: false,
    user: {
        fname: '',
        lname: '',
        desc: '',
        email: '',
    },
    userErrors: {},
    userTouched: {},
    detailIsLoading: false,
    email: {
        email: '',
        password: '',
    },
    emailErrors: {},
    emailTouched: {},
    emailIsLoading: false,
    emailIsValid: false,
    emailSuccessMessage: '',
    emailFailMessage: '',

    password: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    },
    passwordErrors: {},
    passwordTouched: {},
    passwordIsLoading: false,
    passwordIsValid: false,
    passwordSuccessMessage: '',
    passwordFailMessage: '',

    profileIsLoading: false,
    profileSuccessMessage: '',
    profileFailMessage: '',
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_USER:
        return {
            ...state,
            loading: true,
        };
    case FETCH_USER_DONE:
        return {
            ...state,
            loading: false,
            user: action.user,
            email: {
                ...state.email,
                email: action.user.email,
            },
        };
    case UPDATE_DETAIL:
        return {
            ...state,
            user: {
                ...state.user,
                [action.fieldName]: action.newValue
            },
            userTouched: {
                ...state.userTouched,
                [action.fieldName]: true,
            },
            userErrors: action.errors,
        };
    case FETCH_DETAIL:
        return {
            ...state,
            detailIsLoading: true,
        };
    case FETCH_DETAIL_DONE:
        return {
            ...state,
            detailIsLoading: false,
        };
    case UPDATE_EMAIL:
        return {
            ...state,
            email: {
                ...state.email,
                [action.fieldName]: action.newValue
            },
            emailTouched: {
                ...state.emailTouched,
                [action.fieldName]: true,
            },
            emailErrors: action.errors,
            emailIsValid: !action.errors,
        };
    case FETCH_EMAIL:
        return {
            ...state,
            emailIsLoading: true,
        };
    case FETCH_EMAIL_DONE:
        return {
            ...state,
            emailIsLoading: false,
            emailSuccessMessage: action.message,
            emailFailMessage: '',
        };
    case FETCH_EMAIL_FAIL:
        return {
            ...state,
            emailIsLoading: false,
            emailSuccessMessage: '',
            emailFailMessage: action.message,
        };
    case UPDATE_PASSWORD:
        return {
            ...state,
            password: {
                ...state.password,
                [action.fieldName]: action.newValue
            },
            passwordTouched: {
                ...state.passwordTouched,
                [action.fieldName]: true,
            },
            passwordErrors: action.errors,
            passwordIsValid: !action.errors,
        };
    case FETCH_PASSWORD:
        return {
            ...state,
            passwordIsLoading: true,
        };
    case FETCH_PASSWORD_DONE:
        return {
            ...state,
            passwordIsLoading: false,
            passwordSuccessMessage: action.message,
            passwordFailMessage: '',
        };
    case FETCH_PASSWORD_FAIL:
        return {
            ...state,
            passwordIsLoading: false,
            passwordSuccessMessage: '',
            passwordFailMessage: action.message,
        };
    case FETCH_PROFILE:
        return {
            ...state,
            profileIsLoading: true,
        };
    case FETCH_PROFILE_DONE:
        return {
            ...state,
            profileIsLoading: false,
            profileSuccessMessage: action.message,
            profileFailMessage: '',
        };
    case FETCH_PROFILE_FAIL:
        return {
            ...state,
            profileIsLoading: false,
            profileSuccessMessage: '',
            profileFailMessage: action.message,
        };
    default:
        return state;
    }
};
export default settingsReducer;