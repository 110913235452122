import React, { forwardRef } from 'react';
import { NavLink as RouterLink, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button,
    TextField,
    OutlinedInput,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
} from '@material-ui/core';
import _ from 'lodash';

import ImageField from 'components/ImageField';
import imageSize from './imageSize';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
        marginRight: 16,
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const AccountDetails = (props) => {
    const classes = useStyles();
    const {
        uid,
        title,
        subheader,
        loading,
        ads,
        isValid,
        successMessage,
        // failMessage,
        updateAds,
        postImage,
        postAdsCreate,
    } = props;

    // Select Data
    const pages = _.keys(imageSize.page);
    const positions = imageSize.page[ads.page] ? _.keys(imageSize.page[ads.page].value) : _.keys(imageSize.page[ads.page]);
    
    let desktop = {};
    let mobile = {};
    if (imageSize.page[ads.page] && imageSize.page[ads.page].value[ads.position]){
        desktop = imageSize.page[ads.page].value[ads.position].desktop;
        mobile = imageSize.page[ads.page].value[ads.position].mobile;
    }

    // Select
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    // Select & Typing
    const handleChange = event => {
        updateAds(ads, event.target.name, event.target.value);
    };

    // Submit Form
    const handleSave = () => {
        if (_.isEmpty(uid)) {
            postAdsCreate(ads);
        } else {
            postAdsCreate(uid,ads);
        }
    };

    if (!_.isEmpty(successMessage)) {
        return (
            <Redirect to={`/ads/${ads.page}`} />
        );
    }

    return (
        <Card className={classes.root}>
            <CardHeader
                title={title}
                subheader={subheader}
            />
            <Divider />
            <CardContent>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} htmlFor="page">
                        Select Type
                    </InputLabel>
                    <Select
                        disabled={loading}
                        style={{ textTransform: 'capitalize' }}
                        value={ads.page}
                        onChange={handleChange}
                        input={<OutlinedInput labelWidth={labelWidth} name="page" id="page" />}
                    >
                        { 
                            pages.map((pageItem) => 
                                <MenuItem 
                                    key={pageItem} 
                                    value={pageItem} 
                                    style={{textTransform: 'capitalize'}}
                                >
                                    {imageSize.page[pageItem].name}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} htmlFor="position">
                        Select Position
                    </InputLabel>
                    <Select
                        disabled={loading}
                        style={{ textTransform: 'capitalize' }}
                        value={ads.position}
                        onChange={handleChange}
                        input={<OutlinedInput labelWidth={labelWidth} name="position" id="position" />}
                    >
                        {
                            positions.map((posItem) =>
                                <MenuItem
                                    key={posItem}
                                    value={posItem}
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    {posItem}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </CardContent>
            { 
                (!_.isEmpty(desktop) && !_.isEmpty(mobile)) &&
                <>
                    <ImageField
                        title={"Desktop*"}
                        width={desktop.width}
                        height={desktop.height}
                        loading={loading}
                        url={ads.desktop}
                        uploadFunc={postImage}
                        oldData={ads}
                        fieldName="desktop"
                        removeFunc={() => updateAds(ads, 'desktop', '')}
                    />
                    <ImageField
                        title={"Mobile*"}
                        width={mobile.width}
                        height={mobile.height}
                        loading={loading}
                        url={ads.mobile}
                        uploadFunc={postImage}
                        oldData={ads}
                        fieldName="mobile"
                        removeFunc={() => updateAds(ads, 'mobile', '')}
                    />
                    <CardContent>
                        <TextField
                            fullWidth
                            label="URL"
                            margin="dense"
                            name="url"
                            onChange={handleChange}
                            value={ads.url}
                            variant="outlined"
                            disabled={loading}
                        />
                    </CardContent>
                </>
            }
            <Divider />
            <CardActions style={{padding: 16}}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid || _.isEmpty(desktop) || _.isEmpty(mobile)}
                    onClick={handleSave}
                >
                    Save
                </Button>
                <Button
                    color="default"
                    variant="outlined"
                    component={CustomRouterLink}
                    to={`/ads/${ads.page}`}
                >
                    Cancel
                </Button>
            </CardActions>
        </Card>
    );
};

AccountDetails.propTypes = {
    title: PropTypes.string.isRequired,
};

export default AccountDetails;
