import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Box } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import { CSVLink } from 'react-csv';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    spacer: {
        flexGrow: 1,
    },
    importButton: {
        marginRight: theme.spacing(1),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
}));

const UsersToolbar = props => {
    const { className, start, end, update, data, ...rest } = props;

    const classes = useStyles();

    const startDate = moment.unix(start / 1000).format('MM/DD/YYYY');
    const endDate = moment.unix(end / 1000).format('MM/DD/YYYY');

    function handleDateChange(field, date) {
        update(field, moment(date).valueOf());
    }

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <div className={classes.row}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box mr={2}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Start Date"
                            value={startDate}
                            onChange={date => handleDateChange('start', date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Box>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="End Date"
                        value={endDate}
                        onChange={date => handleDateChange('end', date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <span className={classes.spacer} />
                <CSVLink
                    data={data}
                    filename={`orders-${startDate}-to-${endDate}.csv`}>
                    <Button color="primary" variant="contained">
                        <SaveOutlinedIcon
                            style={{ marginRight: 10, width: 18, height: 18 }}
                        />{' '}
                        to CSV
                    </Button>
                </CSVLink>
            </div>
        </div>
    );
};

UsersToolbar.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
};

export default UsersToolbar;
