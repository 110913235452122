import React from 'react';
import { makeStyles } from '@material-ui/styles';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import { Toolbar, Table } from './components';

const searchClient = algoliasearch(
    'SJS3S5FAVR',
    'fbf789ef62160863f04f7f39da3e7fe7',
);

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
}));

const UserList = props => {
    const classes = useStyles();
    const {
        loading,
        list,
        packages,
        getList,
        postDelete,
        postClose,
        postBringBack,
        postPackage,
        resetData,
        querySearch,
        loadingMore,
        showLoadingMore,
        startIndex,
        endIndex,
        loadmore,
        showless,
        updateTime,
        start,
        end,
    } = props;

    const lastVisibleUid = list[list.length - 1]
        ? list[list.length - 1].uid
        : '';

    React.useEffect(() => {
        getList('promote', start, end);
    }, [getList, start, end]);

    return (
        <div className={classes.root}>
            <InstantSearch searchClient={searchClient} indexName="projects">
                <Toolbar
                    changeSearch={querySearch}
                    resetData={resetData}
                    tabActive="promoted"
                    update={updateTime}
                    start={start}
                    end={end}
                />
                <div className={classes.content}>
                    <Table
                        status="promote"
                        list={list}
                        packages={packages}
                        resetData={resetData}
                        loading={loading}
                        postDelete={postDelete}
                        postClose={postClose}
                        postBringBack={postBringBack}
                        postPackage={postPackage}
                        loadingMore={loadingMore}
                        showLoadingMore={showLoadingMore}
                        startIndex={startIndex}
                        endIndex={endIndex}
                        loadmoreFunc={() => loadmore('promote', lastVisibleUid)}
                        showlessFunc={showless}
                    />
                </div>
            </InstantSearch>
        </div>
    );
};

export default UserList;
