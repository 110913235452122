import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    OutlinedInput,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
} from '@material-ui/core';
import _ from 'lodash';
import Categories from 'common/category';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
    },
}));

const CategoryCom = (props) => {
    const { 
        loading,
        categoryId,
        subCatId,
        changeValue,
    } = props;
    const classes = useStyles();
    
    const catData = _.find(Categories.category, ['uid', categoryId]);
    const subCatList = _.isEmpty(catData) ? [] : catData.subCategory;

    const handleChange = event => {
        changeValue(categoryId, event.target.value);
    };

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor="sub-category">
                Choose Sub Category
            </InputLabel>
            <Select
                disabled={loading}
                value={subCatId}
                onChange={handleChange}
                input={<OutlinedInput labelWidth={labelWidth} name="subCategory" id="sub-category" />}
            >
                {
                    subCatList.map((item) => (
                        <MenuItem key={item.uid} value={item.uid}>{item.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

export default CategoryCom;
