import { connect } from 'react-redux';
import { postSignOut } from 'actions/auth';
import { hideSnack } from 'actions/main';
import { Main as MainLayout } from 'layouts';

const mapStateToProps = state => ({
    user: state.auth.user,
    snackIsShow: state.main.isShow,
    snackMessage: state.main.message,
    snackStatus: state.main.status,
});

const mapDispatchToProps = {
    postSignOut, 
    hideSnack,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainLayout);

