import uuidv1 from 'uuid/v1';
import firebase from 'common/firebase';

class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }
    upload() {
        return this.loader.file.then(file => new Promise(async (resolve, reject) => {
                // Firebase Upload Image
                const storageRef = firebase.storage().ref();
                try {
                    const snapshot = await storageRef.child(`articles/${uuidv1()}-${file.name}`).put(file);
                    const downloadURL = await snapshot.ref.getDownloadURL();
                    resolve({
                        default: downloadURL
                    });
                } catch (error) {
                    const genericErrorText = `Couldn't upload file: ${file.name}.`;
                    reject(genericErrorText);
                }
            }));
    }
    abort() {
        console.log('Upload image fail!!!.');
    }
}

function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
    };
}

export default MyCustomUploadAdapterPlugin;