import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    CardContent,
    CardActions,
    Divider,
    AppBar,
    Tabs,
    Tab,
    Avatar,
    Typography,
} from '@material-ui/core';
import _ from 'lodash';

// language
import TH from 'common/lang/th.svg';
import EN from 'common/lang/en.svg';
import CH from 'common/lang/ch.svg';
import JP from 'common/lang/jp.svg';

const useStyles = makeStyles((theme) => ({
    appbar: {
        boxShadow: 'none',
        backgroundColor: '#fff',
    },
    tab: {
        textTransform: 'none',
    },
    selected: {
        color: "#00abbd",
    },
    tabWrapper: {
        flexDirection: 'row',
    },
    detail: {
        padding: 16,
    },
    avatar: {
        marginRight: theme.spacing(1),
        width: 24,
        height: 24,
        backgroundColor: "#00abbd",
        display: 'inline-block',
        border: '1px solid #dddddd',
        boxSizing: 'content-box',
    },
    formControl: {
        width: '100%',
        maxWidth: 200,
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabLang = (props) => {
    const classes = useStyles();
    const { 
        lang, 
        changeLang,
        thNode, 
        enNode, 
        chNode, 
        jpNode,
        thActive,
        enActive,
        chActive,
        jpActive, 
    } = props;

    const tabList = [
        {
            title: 'Thai',
            code: 'th',
            icon: <Avatar className={classes.avatar} src={TH} key="th" />,
            node: thNode,
            active: thActive,
        },
        {
            title: 'English',
            code: 'en',
            icon: <Avatar className={classes.avatar} src={EN} key="en" />,
            node: enNode,
            active: enActive,
        },
        {
            title: 'Chinese',
            code: 'ch',
            icon: <Avatar className={classes.avatar} src={CH} key="ch" />,
            node: chNode,
            active: chActive,
        },
        {
            title: 'Japanese',
            code: 'jp',
            icon: <Avatar className={classes.avatar} src={JP} key="jp" />,
            node: jpNode,
            active: jpActive,
        },
    ]

    // Tabs
    const tab = _.findIndex(tabList, ['code', lang]);

    return (
        <>
            <CardContent style={{padding: 0}}>
                <AppBar
                    position="static"
                    color="default"
                    className={classes.appbar}
                >
                    <Tabs indicatorColor="primary" value={tab}>
                        {
                            tabList.map((item, index) => (
                                <Tab
                                    key={item.code}
                                    onClick={() => changeLang(item.code)}
                                    classes={{
                                        root: classes.tab,
                                        selected: classes.selected,
                                        wrapper: classes.tabWrapper,
                                    }}
                                    label={
                                        <>
                                            {item.icon}
                                            <p>{item.title}</p>
                                        </>
                                    }
                                    {...a11yProps(index)}
                                />
                            ))
                        }
                    </Tabs>
                </AppBar>
                <Divider />
                <div className={classes.detail}>
                    {
                        tabList.map((item, index) => (
                            <div 
                                key={item.code} 
                                role="tabpanel" 
                                hidden={item.code !== lang}
                                id={`simple-tabpanel-${index}`}
                                aria-labelledby={`simple-tab-${index}`}
                            >
                                {item.node}
                            </div>
                        ))
                    }
                </div>
            </CardContent>
            <Divider />
            <CardActions style={{ padding: 16 }}>
                <Typography variant="body1" style={{ display: 'inline-block' }}>Language :</Typography>
                {
                    tabList.map((item) => (
                        item.active && item.icon
                    ))
                }
            </CardActions>
        </>
    );
};

TabLang.propTypes = {
    thNode: PropTypes.node.isRequired,
    enNode: PropTypes.node.isRequired,
    chNode: PropTypes.node.isRequired,
    jpNode: PropTypes.node.isRequired,
};

export default TabLang;
