import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Details } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Create = (props) => {
    const classes = useStyles();
    const {
        loading,
        lang,
        tagList,
        locationList,
        data,
        isValid,
        successMessage,
        articleStatus,
        changeLang,
        changeCategory,
        changeTag,
        removeTag,
        changeLocation,
        update,
        postCreate,
        changeCover,
        postImage,
        getCreateData,
    } = props;

    React.useEffect(() => {
        getCreateData();
    }, [getCreateData]);

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid item xs={12}>
                    <Details 
                        title="Create Article"
                        loading={loading}
                        lang={lang}
                        tagList={tagList}
                        locationList={locationList}
                        data={data}
                        isValid={isValid}
                        successMessage={successMessage}
                        articleStatus={articleStatus}
                        changeLang={changeLang}
                        changeCategory={changeCategory}
                        changeTag={changeTag}
                        removeTag={removeTag}
                        changeLocation={changeLocation}
                        update={update}
                        postCreate={postCreate}
                        changeCover={changeCover}
                        postImage={postImage}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default Create;
