import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import moment from 'moment';
import firebase from 'common/firebase';
import { showSnack } from './main';

const functions = firebase.app().functions('asia-east2');

const schema = {
    page: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    position: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    desktop: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    mobile: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
// -- CREATE/UPDATE
export const UPDATE_ADS = 'UPDATE_ADS';
export const FETCH_ADS = 'FETCH_ADS';
export const FETCH_ADS_DATA_DONE = 'FETCH_ADS_DATA_DONE';
export const FETCH_ADS_DONE = 'FETCH_ADS_DONE';
export const FETCH_ADS_FAIL = 'FETCH_ADS_FAIL';
export const RESET_ADS = 'RESET_ADS';

// -- LIST
export const FETCH_ADS_LIST_DONE = 'FETCH_ADS_LIST_DONE';

export const CHANGE_ADS_STATUS = 'CHANGE_ADS_STATUS';
export const DELETE_ADS = 'DELETE_ADS';
export const FETCH_ADS_LOADMORE = 'FETCH_ADS_LOADMORE';
export const FETCH_ADS_LOADMORE_DONE = 'FETCH_ADS_LOADMORE_DONE';
export const FETCH_ADS_SHOWLESS = 'FETCH_ADS_SHOWLESS';
export const UPDATE_ADS_INDEX = 'UPDATE_ADS_INDEX';
export const FETCH_ADS_INDEX_DONE = 'FETCH_ADS_INDEX_DONE';

// Sync Action Creator
// -- Create/Update --
export function resetAds() {
    return {
        type: RESET_ADS,
    };
};
export function fetchAds() {
    return {
        type: FETCH_ADS,
    };
};
export function fetchAdsDataDone(ads) {
    return {
        type: FETCH_ADS_DATA_DONE,
        ads,
    };
};
export function fetchAdsDone(message) {
    return {
        type: FETCH_ADS_DONE,
        message,
    };
};
export function fetchAdsFail(message) {
    return {
        type: FETCH_ADS_FAIL,
        message,
    };
};
export function updateAds(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;
    const errors = validate(oldData, schema);

    return {
        type: UPDATE_ADS,
        fieldName,
        newValue,
        errors: errors,
    };
};

// -- LIST --
export function fetchAdsListDone(data) {
    return {
        type: FETCH_ADS_LIST_DONE,
        adsList: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function fetchIndexDone(data) {
    return {
        type: FETCH_ADS_INDEX_DONE,
        adsList: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function changeAdsStatus(uid, newStatus) {
    return {
        type: CHANGE_ADS_STATUS,
        uid,
        newStatus,
    };
};
export function deleteAds(uid) {
    return {
        type: DELETE_ADS,
        uid,
    };
};
export function fetchLoadmore() {
    return {
        type: FETCH_ADS_LOADMORE,
    };
};
export function showless() {
    return {
        type: FETCH_ADS_SHOWLESS,
    };
};
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_ADS_LOADMORE_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function updateIndex(startIndex, endIndex) {
    return {
        type: UPDATE_ADS_INDEX,
        startIndex,
        endIndex,
    };
};
// Async Action Creator
export function postImage(file, oldData, fieldName){
    if (!file) {
        return {
            type: FETCH_ADS_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return function (dispatch) {
        dispatch(fetchAds());        
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`ads/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dispatch(updateAds(oldData, fieldName, downloadURL));
                dispatch(fetchAdsDone(''));
            });
        });
    };
}

export function postAdsCreate(data) {
    return function (dispatch) {
        dispatch(fetchAds());
        const adminCreator = firebase.auth().currentUser;
        if (adminCreator) {
            const inputData = {
                ...data,
                seen: 0,
                uniqueSeen: 0,
                click: 0,
                active: false,
                timestamp: moment().valueOf(),
                createdBy: adminCreator.uid,
            }

            const adsRef = firebase.firestore().collection("ads");
            return adsRef.add(inputData).then((docRef) => {
                dispatch(fetchAdsDone('Ads created successfully.'));
                dispatch(showSnack('success', 'Ads created successfully.'));
            }).catch((error) => {
                console.log(error);
                dispatch(fetchAdsFail(error.message));
            });
        } else {
            dispatch(fetchAdsFail("You are not sign in."));
        }
    };
}

export function getAdsEdit(uid) {
    return function (dispatch) {
        dispatch(fetchAds());
        const docRef = firebase.firestore().collection("ads").doc(uid);

        return docRef.get().then((doc) => {
            if (doc.exists) {
                const allData = doc.data();
                const ads = {
                    page: allData.page,
                    position: allData.position,
                    desktop: allData.desktop,
                    mobile: allData.mobile,
                    url: allData.url,
                };
                dispatch(fetchAdsDataDone(ads));
            } else {
                // doc.data() will be undefined in this case
                dispatch(showSnack('fail', "No such document!"));
                dispatch(fetchAdsFail("No such document!"));
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
            dispatch(fetchAdsFail(error.message));
        });
    };
}

export function postAdsEdit(uid,data) {
    return function (dispatch) {
        dispatch(fetchAds());
        
        const adsRef = firebase.firestore().collection("ads").doc(uid);
        return adsRef.set(data, { merge: true }).then((docRef) => {
            dispatch(fetchAdsDone('Ads updated successfully.'));
            dispatch(showSnack('success', 'Ads updated successfully.'));
        }).catch((error) => {
            console.log(error);
            dispatch(fetchAdsFail(error.message));
        });
    };
}

export function getAllAdsList() {
    return async function (dispatch) {
        dispatch(fetchAds());

        const getList = functions.httpsCallable('adsBackendGetList');
        try {
            const result = await getList('all');
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchAdsListDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchAdsFail(error.message));
        }
    };
}

export function getHomeAdsList() {
    return async function (dispatch) {
        dispatch(fetchAds());

        const getList = functions.httpsCallable('adsBackendGetList');
        try {
            const result = await getList('home');
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchAdsListDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchAdsFail(error.message));
        }
    };
}

export function getSideAdsList() {
    return async function (dispatch) {
        dispatch(fetchAds());

        const getList = functions.httpsCallable('adsBackendGetList');
        try {
            const result = await getList('side');
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchAdsListDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchAdsFail(error.message));
        }
    };
}

export function postDeleteAds(uid) {
    return function (dispatch) {
        const adsRef = firebase.firestore().collection("ads").doc(uid);
        return adsRef.delete().then(function () {
            dispatch(deleteAds(uid));
            dispatch(showSnack('success', 'Delete ads successfully.'));
        }).catch(function (error) {
            console.error("Error removing document: ", error.message);
        });
    };
};

export function toggleStatus(uid, newStatus) {
    return function (dispatch) {
        dispatch(changeAdsStatus(uid, newStatus));

        const adsRef = firebase.firestore().collection("ads").doc(uid);
        return adsRef.set({ active: newStatus }, { merge: true }).then(function () {
            dispatch(showSnack('success', 'Change ads status successfully.'));
        }).catch(function (error) {
            console.error("Error writing document: ", error);
        });
    };
};

export function loadmore(adsType,lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const adsLoadmore = functions.httpsCallable('adsBackendLoadmore');
        try {
            const data = {
                adsType,
                lastVisibleUid,
            };
            const result = await adsLoadmore(data);
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchLoadmoreDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchAdsFail(error.message));
        }
    };
}

export function queryByIndex(adsType, startIndex, endIndex) {
    return async (dispatch) => {
        dispatch(fetchAds());
        if (endIndex <= startIndex) {
            dispatch(showSnack("fail", "End index is less than start index."));
            dispatch(fetchAdsFail("endIndex < startIndex"));
        } else {
            const adsQuery = functions.httpsCallable('adsBackendQuery');
            try {
                const result = await adsQuery({ adsType, startIndex, endIndex });
                const output = {
                    numberOfDocs: result.data.numberOfDocs,
                    list: result.data.list,
                }
                dispatch(updateIndex(startIndex, endIndex));
                dispatch(fetchIndexDone(output));
            }
            catch (error) {
                console.log(error);
                dispatch(fetchAdsFail(error.message));
            }
        }
    };
}