import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import AccountDetails from './components/CreateDetails';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const UserCreate = (props) => {
    const classes = useStyles();
    const {
        loading,
        user,
        userErrors,
        userTouched,
        userFailMessage,
        userSuccessMessage,
        userIsValid,
        profileIsLoading,
        updateUserCreate,
        postUserCreate,
        postProfile,
        removeProfile,
    } = props;

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    xs={12}
                >
                    <AccountDetails 
                        loading={loading}
                        touched={userTouched}
                        errors={userErrors}
                        isValid={userIsValid}
                        user={user}
                        updateFunc={updateUserCreate}
                        postCreateFunc={postUserCreate}
                        userFailMessage={userFailMessage}
                        userSuccessMessage={userSuccessMessage}
                        profileIsLoading={profileIsLoading}
                        postProfile={postProfile}
                        removeProfile={removeProfile}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default UserCreate;
