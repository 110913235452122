import { connect } from 'react-redux';
import {
    update,
    postCreate,
    changeTag,
    removeTag,
} from 'actions/webboard';

import WebboardCreate from 'views/Webboard/Create';

const mapStateToProps = state => ({
    loading: state.webboard.loading,
    data: state.webboard.data,
    isValid: state.webboard.isValid,
    successMessage: state.webboard.successMessage,
});

const mapDispatchToProps = {
    update,
    postCreate,
    changeTag,
    removeTag,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WebboardCreate);

