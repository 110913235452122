import { connect } from 'react-redux';
import {
    resetPage,
    updatePage,
    postImage,
    getPage,
    postPageEdit,
    changeLang,
} from 'actions/pages';

import Page from 'views/Pages/Finance';

const mapStateToProps = state => ({
    loading: state.pages.loading,
    lang: state.pages.lang,
    pages: state.pages.pages,
    errors: state.pages.errors,
    isValid: state.pages.isValid,
});

const mapDispatchToProps = {
    resetPage,
    updatePage,
    postImage,
    getPage,
    postPageEdit,
    changeLang,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Page);

