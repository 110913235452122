import {
    FETCH_USER_LIST,
    FETCH_USER_LIST_DONE,
    FETCH_USER_LIST_FAIL,
    CHANGE_USER_STATUS,
    DELETE_USER,
} from '../actions/userList';

const initialState = {
    loading: false,
    userList: [],
    message: '',
};

const userListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_LIST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_USER_LIST_DONE:
            return {
                ...state,
                loading: false,
                userList: action.userList,
            };
        case FETCH_USER_LIST_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
            };
        case CHANGE_USER_STATUS:
            const index = state.userList.findIndex(user => user.uid === action.uid);
            let userList = [ ...state.userList ];
            userList[index] = { 
                ...userList[index], 
                disabled: action.newStatus 
            };
            return { 
                ...state, 
                userList 
            };
        case DELETE_USER:
            const index2 = state.userList.findIndex(user => user.uid === action.uid);
            let newUserList = [ ...state.userList ];
            newUserList.splice(index2, 1);
            
            return {
                ...state,
                userList: newUserList
            };
        default:
            return state;
    }
};
export default userListReducer;