import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Details } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Settings = (props) => {
    const classes = useStyles();
    const {
        loading,
        data,
        isValid,
        successMessage,
        changeType,
        changeProject,
        changeGeo,
        update,
        postEdit,
        postGallery,
        removeGallery,
        getEdit,
        location,
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];
    React.useEffect(() => {
        getEdit(uid);
    }, [getEdit, uid]);

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid item xs={12}>
                    <Details 
                        title="Edit Property"
                        uid={uid}
                        loading={loading}
                        data={data}
                        isValid={isValid}
                        successMessage={successMessage}
                        update={update}
                        postCreate={postEdit}
                        postGallery={postGallery}
                        removeGallery={removeGallery}
                        changeType={changeType}
                        changeProject={changeProject}
                        changeGeo={changeGeo}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(Settings);
