import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    remove: {
        opacity: 0,
        color: '#fff',
        position: 'absolute',
        right: 8,
        top: 8,
        transition: 'all 0.3s linear',
    },
    removeLoading: {
        color: '#00abbd',
    },
    media: {
        width: 180,
        height: 180,
        borderRadius: 10,
        position: 'relative',
        transition: 'all 0.3s linear',
        opacity: 1,
    },
    bg: {
        marginRight: 24,
        marginBottom: 24,
        width: 180,
        height: 180,
        borderRadius: 10,
        position: 'relative',
        background: '#808080',
        '&:hover': {
            '& $remove': {
                opacity: 1,
            },
            '& $media': {
                opacity: 0.7,
            },
        },
    },
    bgWhite: {
        background: '#fff',
        '&:hover': {
            background: '#fff',
        }
    },
    text: {
        color: '#fff',
        textAlign: 'center',
        position: 'absolute',
        bottom: 8,
        width: '100%',
    },
    btn: {
        width: 180,
        height: 180,
        position: 'relative',
        borderRadius: 10,
        border: 'solid 1px #00abbd',
        textAlign: 'center',
    },
    progress: {
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
    },
}));

export default function Image(props) {
    const classes = useStyles();
    const { 
        first,
        loading,
        url,
        remove,
    } = props;

    return (
        <Box className={clsx(classes.bg, loading && classes.bgWhite)}>
            {loading ? (
                <Box className={classes.btn}>
                    <IconButton
                        aria-label="remove"
                        className={clsx(classes.remove, classes.removeLoading)}
                        size="small"
                        onClick={() => remove()}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <CircularProgress
                        className={classes.progress}
                        color="primary"
                    />
                </Box>
            ) : (
                <>
                    <CardMedia
                        className={classes.media}
                        image={url}
                        title="Project Image"
                    />
                    <IconButton
                        aria-label="remove"
                        className={classes.remove}
                        size="small"
                        onClick={() => remove()}
                    >
                        <DeleteIcon />
                    </IconButton>
                    {first && (
                        <Typography variant="button" className={classes.text}>
                            Cover
                        </Typography>
                    )}
                </>
            )}
        </Box>
    );
}
