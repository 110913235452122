import { connect } from 'react-redux';
import { 
    getMyInbox, 
    resetData,
    getChat,
    updateInbox,
    updateChat,
    postChat,
    updateInboxSeen,
} from 'actions/inbox';
import MyInbox from 'views/Inbox';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.inbox.loading,
    list: state.inbox.list,
    chat: state.inbox.chat,
});

const mapDispatchToProps = {
    getMyInbox,
    resetData,
    getChat,
    updateInbox,
    updateChat,
    postChat,
    updateInboxSeen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyInbox);



