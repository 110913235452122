import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    CardContent,
    Avatar,
    Typography,
    Divider,
    Button,
    CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    avatar: {
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 100,
        width: 100,
        textTransform: 'uppercase',
    },
    action:{
        justifyContent: 'center',
    },
    uploadButton: {
        marginRight: theme.spacing(2)
    },
    progress: {
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
    },
}));

const AccountProfile = props => {
    const { loading, imageSrc, name, postUpdate, postRemove } = props;

    const classes = useStyles();

    const handleSelect = (event) => {
        const file = event.target.files[0];
        postUpdate(file);
    };

    return (
        <Card className={classes.root}>
            <CardContent>
                <div>
                    <div>
                        <Typography
                            gutterBottom
                            variant="h2"
                        >
                            Profile
                        </Typography>
                    </div>
                    <div style={{position: 'relative'}}>
                        {loading && <CircularProgress className={classes.progress} />}
                        {
                            (imageSrc === "" || imageSrc === null) ?
                                <Avatar 
                                    color="primary" 
                                    className={classes.avatar}
                                    style={{ backgroundColor: "#00abbd" }}
                                >
                                    {name && name.charAt(0)}
                                </Avatar>
                                :
                                <Avatar
                                    className={classes.avatar}
                                    src={imageSrc}
                                />
                        }
                    </div>
                </div>
            </CardContent>
            <Divider />
            <CardActions className={classes.action}>
                <label htmlFor="add-image">
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="add-image"
                        name="add-image"
                        type="file"
                        disabled={loading}
                        onChange={handleSelect}
                    />
                    <Button
                        className={classes.uploadButton}
                        color="primary"
                        variant="text"
                        component="div"
                        htmlFor="add-image"
                        disabled={loading}
                    >
                        Upload
                    </Button>
                </label>
                <Button 
                    variant="text"
                    disabled={loading}
                    onClick={() => postRemove()}
                >
                    Remove
                </Button>
            </CardActions>
        </Card>
    );
};

export default AccountProfile;
