import { connect } from 'react-redux';
import {
    changeLang,
    changeType,
    changeDev,
    changeLogo,
    changeGeo,
    update,
    updateNoLang,
    postEdit,
    postImage,
    postGallery,
    removeGallery,
    getEdit,
} from 'actions/project';

import ProjectEdit from 'views/Project/Edit';

const mapStateToProps = state => ({
    loading: state.project.loading,
    lang: state.project.lang,
    data: state.project.data,
    isValid: state.project.isValid,
    successMessage: state.project.successMessage,
});

const mapDispatchToProps = {
    changeLang,
    changeType,
    changeDev,
    changeLogo,
    changeGeo,
    update,
    updateNoLang,
    postEdit,
    postImage,
    postGallery,
    removeGallery,
    getEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectEdit);

