import React from 'react';
import Fab from '@material-ui/core/Fab';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Live from './live.svg';
import LifeStyle from './lifestyle.svg';
import Finance from './finance.svg';
import Inspiration from './inspiration.svg';

const styles = theme => ({
    button:{
        color: '#fff',
        boxShadow: 'none',
        minHeight: 20,
        '&:active':{
            boxShadow: 'none',
        }
    },
    l: {
        width: 56,
        height: 56,
        '& img':{
            width: 27,
            height: 27,
        },
        [theme.breakpoints.down('sm')]: {
            width: 48,
            height: 48,
            '& img': {
                width: 24,
                height: 24,
            },
        }
    },
    activeL: {
        border: 'solid 8px rgba(255, 255, 255, 0.5)',
        [theme.breakpoints.down('sm')]: {
            border: 'solid 6px rgba(255, 255, 255, 0.5)',
        }
    },
});

function Marker(props) {
    const { classes, category} = props;

    // Icon
    let Image = Live;
    if (category === 'lifestyle') {
        Image = LifeStyle;
    }
    if (category === 'finance') {
        Image = Finance;
    }
    if (category === 'inspiration'){
        Image = Inspiration;
    }

    return (
        <div>
            <Fab
                color="secondary"
                className={clsx(classes.button, classes.l, classes.activeL)}
            >
                <img src={Image} alt="button" />
            </Fab>
        </div>
    );
}

Marker.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    category: PropTypes.string.isRequired,
};

export default withStyles(styles)(Marker);