import React, { forwardRef } from 'react';
import { NavLink as RouterLink, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Card,
    CardHeader,
    CardActions,
    Divider,
    Button,
    TextField,
} from '@material-ui/core';
import _ from 'lodash';
import TabLang from 'components/TabLang';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const AccountDetails = (props) => {
    const {
        uid,
        title,
        loading,
        lang,
        data,
        isValid,
        successMessage,
        changeLang,
        update,
        postCreate,
    } = props;

    // Typing
    const handleChange = event => {
        update(data, lang, event.target.name, event.target.value);
    };

    const handleSave = () => {
        if (_.isEmpty(uid)) {
            postCreate(data);
        }
        else {
            postCreate(uid, data);
        }
    };

    if (!_.isEmpty(successMessage)) {
        return (
            <Redirect to="/developers" />
        );
    }

    return (
        <Card >
            <form
                autoComplete="off"
                noValidate
            >
                <CardHeader
                    title={title}
                />
                <Divider />
                <TabLang 
                    lang={lang}
                    changeLang={changeLang}
                    thActive={!_.isEmpty(data.name.th)}
                    enActive={!_.isEmpty(data.name.en)}
                    chActive={!_.isEmpty(data.name.ch)}
                    jpActive={!_.isEmpty(data.name.jp)}
                    thNode={
                        <TextField
                            key={`th-name-${lang === 'th'}`}
                            fullWidth
                            label="Name"
                            margin="dense"
                            name="name"
                            onChange={handleChange}
                            required
                            disabled={loading}
                            value={data.name.th}
                            variant="outlined"
                        />
                    }
                    enNode={
                        <TextField
                            key={`en-name-${lang === 'en'}`}
                            fullWidth
                            label="Name"
                            margin="dense"
                            name="name"
                            onChange={handleChange}
                            value={data.name.en}
                            variant="outlined"
                            disabled={loading}
                        />
                    }
                    chNode={
                        <TextField
                            key={`ch-name-${lang === 'ch'}`}
                            fullWidth
                            label="Name"
                            margin="dense"
                            name="name"
                            onChange={handleChange}
                            value={data.name.ch}
                            variant="outlined"
                            disabled={loading}
                        />
                    }
                    jpNode={
                        <TextField
                            key={`jp-name-${lang === 'jp'}`}
                            fullWidth
                            label="Name"
                            margin="dense"
                            name="name"
                            onChange={handleChange}
                            value={data.name.jp}
                            variant="outlined"
                            disabled={loading}
                        />
                    }
                />
                <Divider />
                <CardActions style={{padding: 16}}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSave}
                        disabled={!isValid}
                    >
                        Save
                    </Button>
                    <Button
                        color="default"
                        variant="outlined"
                        component={CustomRouterLink}
                        to='/developers'
                    >
                        Cancel
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

AccountDetails.propTypes = {
    title: PropTypes.string.isRequired,
};

export default AccountDetails;
