import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Divider, AppBar, Tabs, Tab ,Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
    connectSearchBox,
} from 'react-instantsearch-dom';
import { SearchInput, CategoryInput } from 'components';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    root2: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabs:{
        marginTop: 16,
        marginBottom: 16,
    },
    appbar: {
        boxShadow: 'none',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    tab: {
        textTransform: 'none',
    },
}));

const SearchBox = ({ currentRefinement, isSearchStalled, refine, changeSearch }) => {
    // call redux that is search or not searching
    changeSearch(currentRefinement);
    return (
        <>
            <SearchInput
                style={{ marginRight: 8 }}
                placeholder="Search article name"
                value={currentRefinement}
                onChange={event => refine(event.currentTarget.value)}
                reset={() => refine('')}
                disabled={isSearchStalled}
            />
        </>
    );
};
const CustomSearchBox = connectSearchBox(SearchBox);

const UsersToolbar = props => {
    const { 
        className, 
        tabActive,
        resetData,
        changeSearch,
        categoryId,
        categoryListId,
        ...rest 
    } = props;

    const classes = useStyles();

    const tabs = [
        {
            title: 'Draft',
            href: '/articles/draft',
        },
        {
            title: 'Publish',
            href: '/articles/publish',
        },
    ];

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.row}>
                {
                    tabActive === 'publish' && 
                    <CustomSearchBox
                        // redux func
                        changeSearch={changeSearch}
                    />
                }
                <CategoryInput
                    value={categoryId}
                    listItem={categoryListId}
                />
                <span className={classes.spacer} />
                <Button
                    color="primary"
                    variant="contained"
                    component={CustomRouterLink}
                    onClick={() => resetData()}
                    to="/articles/create"
                >
                    <AddIcon style={{ padding: 0, marginRight: 4, width: 16, height: 16 }} /> Create Article
                </Button>
            </div>
            <div className={classes.tabs}>
                <AppBar
                    position="static"
                    color="default"
                    className={classes.appbar}
                >
                    <Tabs
                        indicatorColor="primary"
                        value={tabActive === 'draft' ? 0 : 1}
                        aria-label="simple tabs example"
                    >
                        {
                            tabs.map((item, key) =>
                                <Tab
                                    classes={{
                                        root: classes.tab,
                                    }}
                                    key={item.title}
                                    component={CustomRouterLink}
                                    id={`simple-tab-${key}`}
                                    aria-controls={`simple-tabpanel-${key}`}
                                    label={item.title}
                                    to={item.href}
                                />
                            )
                        }
                    </Tabs>
                </AppBar>
                <Divider />
            </div>
        </div>
    );
};

UsersToolbar.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    tabActive: PropTypes.string.isRequired,
};

export default UsersToolbar;
