import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField, Box, Link, Typography, CircularProgress } from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        position: 'relative',
        paddingLeft: 100,
        paddingRight: 100,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    inputForm:{
        position: 'relative',
    },
    progress: {
        margin: 'auto',
        position: 'absolute',
        top: 0, 
        left: 0, 
        bottom: 0, 
        right: 0,
        zIndex: 2,
    },
    title: {
        marginTop: theme.spacing(3)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    }
}));

const SignIn = props => {
    const classes = useStyles();
    const { loading, isValid, message, data, errors, touched, postSignIn, updateSignIn, resetAuth } = props;

    const handleChange = event => {
        event.persist();
        updateSignIn(data,event.target.name, event.target.value);
    };

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)  ){
            return touched[field] && errors[field] ? true : false;
        }
        return false;
    };

    const handleSignIn = () => {
        postSignIn(data);
    };

    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <form
                    className={classes.form}
                    onSubmit={handleSignIn}
                >
                    <Typography
                        className={classes.title}
                        variant="h2"
                    >
                        Sign in
                    </Typography>
                    <div className={classes.inputForm}>
                        { loading && <CircularProgress className={classes.progress} /> }
                        <TextField
                            className={classes.textField}
                            error={hasError('email')}
                            fullWidth
                            helperText={
                                hasError('email') ? errors.email[0] : null
                            }
                            label="Email address"
                            name="email"
                            onChange={handleChange}
                            type="text"
                            value={data.email}
                            variant="outlined"
                            disabled={loading}
                        />
                        <TextField
                            className={classes.textField}
                            error={hasError('password')}
                            fullWidth
                            helperText={
                                hasError('password') ? errors.password[0] : null
                            }
                            label="Password"
                            name="password"
                            onChange={handleChange}
                            type="password"
                            value={data.password}
                            variant="outlined"
                            disabled={loading}
                        />
                        {   message !== '' && 
                            <Box mt={2}>
                                <Typography
                                    style={{color: "#de0032"}}
                                    variant="caption"
                                >
                                    {message}
                                </Typography>
                            </Box>
                        }
                        <Button
                            className={classes.signInButton}
                            color="primary"
                            disabled={!isValid || loading}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={handleSignIn}
                        >
                            Sign in now
                        </Button>
                    </div>
                    <Typography
                        color="textSecondary"
                        variant="body1"
                        onClick={() => resetAuth()}
                    >
                        <Link
                            component={RouterLink}
                            to="/forgot-password"
                            variant="h6"
                        >
                            Forgot password?
                        </Link>
                    </Typography>
                </form>
            </div>
        </div>
    );
};

SignIn.propTypes = {
    history: PropTypes.object
};

export default withRouter(SignIn);
