import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import _ from 'lodash';
import PurposeName from 'common/propertyPurpose';
import BedroomName from 'common/propertyRoomNumber';

const useStyles = makeStyles(theme => ({
    root: {
        left: -12,
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(2),
        width: 24,
        height: 24,
        textTransform: 'uppercase',
        backgroundColor: '#00abbd',
        display: 'inline-block',
    },
    profile: {
        width: 24,
        height: 24,
        textTransform: 'uppercase',
        backgroundColor: '#00abbd',
        display: 'flex',
        fontSize: '0.8rem',
    },
    cover: {
        width: 50,
        height: 50,
        backgroundColor: '#00abbd',
    },
    rowCap:{
        textTransform: 'capitalize',
    },
    dialogTitle:{
        textTransform: 'capitalize',
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const UsersTableList = props => {
    const classes = useStyles();

    const { 
        data, 
        packages, 
        resetData, 
        postDelete, 
        postClose, 
        postBringBack,
        postPackage,
    } = props;

    const purposeIndex = _.findIndex(PurposeName.type, ['uid', data.purpose]);
    const bedroomIndex = _.findIndex(BedroomName.type, ['uid', data.bedroomNum]);
    console.log(bedroomIndex);

    const [state, setState] = React.useState({
        anchorEl: null,
        openDialog: false,
        openPackage: false,
        packageId: data.packageId ? data.packageId : '',
        dialogName: '',
    });

    function handleClickOpenDialog(name) {
        setState({
            ...state,
            anchorEl: null,
            openDialog: true,
            dialogName: name,
        });
    }
    function handleCloseDialog() {
        setState({
            ...state,
            openDialog: false,
            openPackage: false,
            dialogName: '',
        });
    }
    function handleClickOpenPackage() {
        setState({
            ...state,
            openPackage: true,
        });
    }
    function handleClosePackage() {
        setState({
            ...state,
            openPackage: false,
        });
    }
    function handleDelete() {
        postDelete(data.uid);
        setState({
            ...state,
            openDialog: false,
            dialogName: '',
        });
    }
    function handlePostClose() {
        if(data.status !== 'close'){
            postClose(data.uid);
        }else{
            postBringBack(data.uid);
        }

        setState({
            ...state,
            openDialog: false,
            dialogName: '',
        });
    }
    function handleChangePackage(event) {
        setState({
            ...state,
            packageId: event.target.value,
        });
    }
    function handlePostPackage(packageId){
        const packageData = _.find(packages, { uid: packageId });
        postPackage(packageData, data.uid);

        setState({
            ...state,
            openPackage: false,
            packageId: '',
            anchorEl: null,
        });
    }

    // Menu
    function handleClick(event) {
        setState({
            ...state,
            anchorEl: event.currentTarget,
        });
    }
    function handleClose() {
        setState({
            ...state,
            anchorEl: null,
        });
    }

    return (
        <TableRow className={classes.tableRow} hover>
            <TableCell>{data.createdAt}</TableCell>
            <TableCell>{data.title}</TableCell>
            <TableCell className={classes.rowCap} >{PurposeName.type[purposeIndex].name}</TableCell>
            <TableCell>{data.sellPrice ? data.sellPrice.toLocaleString() : '-'}</TableCell>
            <TableCell>{data.rentPrice ? data.rentPrice.toLocaleString() : '-'}</TableCell>
            <TableCell className={classes.rowCap} >{bedroomIndex === -1 ? '-' :BedroomName.type[bedroomIndex].name}</TableCell>
            {
                data.status === 'promote' && 
                <TableCell>{`${data.reachAfterPromote}/${data.maximumReachPromote}`}</TableCell>
            }
            <TableCell>
                <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                {/* -- Menu -- */}
                <Menu
                    id="simple-menu"
                    anchorEl={state.anchorEl}
                    keepMounted
                    open={Boolean(state.anchorEl)}
                    onClose={handleClose}>
                    <MenuItem
                        component={CustomRouterLink}
                        to={`/properties/edit/${data.uid}`}
                        onClick={() => resetData()}>
                        Edit
                    </MenuItem>
                    {
                        data.status === 'open' &&
                            <MenuItem onClick={handleClickOpenPackage}>Promote</MenuItem>
                    }
                    <MenuItem onClick={() => handleClickOpenDialog('close')}>{data.status === 'close' ? 'Bring Back' : 'Sold/Rented'}</MenuItem>
                    <MenuItem onClick={() => handleClickOpenDialog('delete')}>Delete</MenuItem>
                </Menu>
                {/* -- Dialog -- */}
                <Dialog
                    open={state.openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" disableTypography>
                        <Typography className={classes.dialogTitle} variant="h4">
                            {state.dialogName === 'close' ? 
                                <>
                                    {data.status === 'close' ?'Show' :'Hide'}
                                </>
                                : 
                                state.dialogName
                            } This Property
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to {state.dialogName === 'close' ? 'change the status of' : state.dialogName} this property?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ padding: 16 }}>
                        {state.dialogName === 'delete' ?
                            <Button onClick={handleDelete} color="primary">
                                Delete
                            </Button>
                            :
                            <Button onClick={handlePostClose} color="primary">
                                {data.status === 'close' ? 'Bring Back' : 'Sold/Rented'}
                            </Button>
                        }
                        <Button
                            onClick={handleCloseDialog}
                            color="primary"
                            autoFocus>
                            No Thank
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* -- Package -- */}
                <Dialog
                    open={state.openPackage}
                    onClose={handleClosePackage}
                >
                    <DialogTitle id="alert-dialog-title" disableTypography>
                        <Typography className={classes.dialogTitle} variant="h4">
                            Promote Property
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <RadioGroup 
                            name="packages" 
                            value={state.packageId} 
                            onChange={handleChangePackage}
                        >
                            { packages.map((item)=>
                                <FormControlLabel 
                                    key={item.uid} 
                                    value={item.uid} 
                                    control={<Radio color="primary" />} 
                                    label={`${item.name.en} (฿${item.price}-${item.views}Views)`}
                                />
                            )}
                        </RadioGroup>
                    </DialogContent>
                    <DialogActions style={{ padding: 16 }}>
                        <Button 
                            onClick={() => handlePostPackage(state.packageId)} 
                            color="primary"
                        >
                            Promote
                        </Button>
                        <Button
                            onClick={handleClosePackage}
                            color="primary"
                            autoFocus
                        >
                            No Thank
                        </Button>
                    </DialogActions>
                </Dialog>
            </TableCell>
        </TableRow>
    );
};

export default UsersTableList;
