import { connect } from 'react-redux';
import {
    update,
    postEdit,
    getEdit,
    changeTag,
    removeTag,
} from 'actions/webboard';

import WebboardEdit from 'views/Webboard/Edit';

const mapStateToProps = state => ({
    loading: state.webboard.loading,
    data: state.webboard.data,
    isValid: state.webboard.isValid,
    successMessage: state.webboard.successMessage,
});

const mapDispatchToProps = {
    update,
    postEdit,
    getEdit,
    changeTag,
    removeTag,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WebboardEdit);

