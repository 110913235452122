import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Button,
    TextField,
    CircularProgress,
} from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    progress: {
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
    },
}));

const AccountDetails = props => {
    const classes = useStyles();
    const { loading, uid, fname, lname, desc, errors, touched, updateDetail, postUpdateDetail } = props;

    const handleChange = event => {
        const data = {
            fname,
            lname,
            desc,
        }
        updateDetail(data, event.target.name, event.target.value);
    };

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)) {
            return touched[field] && errors[field] ? true : false;
        }
        return false;
    };

    const handleSubmit = () => {
        const data = {
            uid,
            fname,
            lname,
            desc,
        }
        postUpdateDetail(data);
    };

    return (
        <Card className={classes.root}>
            <form
                autoComplete="off"
                noValidate
            >
                <CardHeader
                    subheader="The information can be edited"
                    title="User Information"
                />
                <Divider />
                <CardContent style={{position: 'relative'}}>
                    {loading && <CircularProgress className={classes.progress} />}
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="First name"
                                margin="dense"
                                name="fname"
                                onChange={handleChange}
                                required
                                value={fname}
                                variant="outlined"
                                error={hasError('fname')}
                                helperText={
                                    hasError('fname') ? errors.fname[0] : null
                                }
                                disabled={loading}
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Last name"
                                margin="dense"
                                name="lname"
                                onChange={handleChange}
                                required
                                value={lname}
                                variant="outlined"
                                error={hasError('lname')}
                                helperText={
                                    hasError('lname') ? errors.lname[0] : null
                                }
                                disabled={loading}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Description"
                                margin="dense"
                                name="desc"
                                onChange={handleChange}
                                value={desc}
                                variant="outlined"
                                error={hasError('desc')}
                                helperText={
                                    hasError('desc') ? errors.desc[0] : null
                                }
                                disabled={loading}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions style={{ padding: 16 }}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={hasError('desc') || hasError('fname') || hasError('lname') || loading}
                        onClick={handleSubmit}
                    >
                        Save details
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

export default AccountDetails;
