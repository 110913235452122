import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const styles = () => ({
    tag: {
        padding: '5px 10px',
        margin: '2px 4px 2px 0px',
    }
});

function Tag(props) {
    const { 
        text, 
        classes, 
        onDelete,
    } = props;

    return (
        <Button
            variant="outlined"
            color="primary"
            className={classes.tag}
        >
            <Typography variant="body2">
                {text}
            </Typography>
            <CloseIcon 
                onClick={onDelete} 
                fontSize='small'
            />
        </Button>
    );
}

Tag.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
};

Tag.defaultProps = {
    onDelete(){ console.log('Tag Deleted'); },
};

export default withStyles(styles)(Tag);