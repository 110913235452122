import { connect } from 'react-redux';
import {
    changeLang,
    updateTag,
    postTagCreate,
} from 'actions/tags';

import TagCreate from 'views/Tag/Create';

const mapStateToProps = state => ({
    loading: state.tags.loading,
    lang: state.tags.lang,
    tag: state.tags.tag,
    errors: state.tags.errors,
    isValid: state.tags.isValid,
    successMessage: state.tags.successMessage,
});

const mapDispatchToProps = {
    changeLang,
    updateTag,
    postTagCreate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagCreate);

