export default {
    category: [
        {
            uid: 'live',
            name: 'Live',
            subCategory: [
                {
                    uid: 'review',
                    name: 'Review'
                },
                {
                    uid: 'location',
                    name: 'Location'
                },
                {
                    uid: 'prNews',
                    name: 'PR News'
                }
            ]
        },
        {
            uid: 'lifestyle',
            name: 'Lifestyle',
            subCategory: [
                {
                    uid: 'airline',
                    name: 'Airline'
                },
                {
                    uid: 'travel',
                    name: 'Travel'
                },
                {
                    uid: 'eat',
                    name: 'Eat'
                }
            ]
        },
        {
            uid: 'finance',
            name: 'Finance',
            subCategory: [
                {
                    uid: 'investment',
                    name: 'Investment'
                },
                {
                    uid: 'news',
                    name: 'News'
                },
                {
                    uid: 'livingForward',
                    name: 'Living Forward'
                }
            ]
        },
        {
            uid: 'inspiration',
            name: 'Inspiration',
            subCategory: [
                {
                    uid: 'businessTalk',
                    name: 'Business Talk'
                },
                {
                    uid: 'homeDecor',
                    name: 'Home Decor'
                },
                {
                    uid: 'others',
                    name: 'Others'
                }
            ]
        }

    ]
};