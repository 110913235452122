import { connect } from 'react-redux';
import {
    getTagList,
    postDeleteTag,
    resetTag,
    loadmore,
    showless,
    queryByIndex,
    changeSearch,
} from 'actions/tags';
import TagList from 'views/Tag/List';

const mapStateToProps = state => ({
    loading: state.tags.loading,
    tagList: state.tags.tagList,
    loadingMore: state.tags.loadingMore,
    showLoadingMore: state.tags.showLoadingMore,
    startIndex: state.tags.startIndex,
    endIndex: state.tags.endIndex,
    numberOfDocs: state.tags.numberOfDocs,
    isSearching: state.tags.isSearching,
});

const mapDispatchToProps = {
    getTagList,
    postDeleteTag,
    resetTag,
    loadmore,
    showless,
    queryByIndex,
    changeSearch,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagList);