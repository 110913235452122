import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Hidden, List, ListItem, Button, colors } from '@material-ui/core';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import VideoLabelOutlinedIcon from '@material-ui/icons/VideoLabelOutlined';
import WebOutlinedIcon from '@material-ui/icons/WebOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import InputIcon from '@material-ui/icons/Input';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 240,
        [theme.breakpoints.up('lg')]: {
            marginTop: 64,
            height: 'calc(100% - 64px)'
        }
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        margin: theme.spacing(2, 0, 0, 0)
    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main
        }
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const Sidebar = props => {
    const { open, variant, onClose, className, postSignOut,user, ...rest } = props;
    console.log(user);

    const classes = useStyles();
    let pages = [
        {
            title: 'Articles',
            href: '/articles/',
            icon: <DashboardOutlinedIcon />
        },
        {
            title: 'Webboard',
            href: '/webboard/',
            icon: <ViewAgendaOutlinedIcon />
        },
        {
            title: 'Locations',
            href: '/locations/',
            icon: <PlaceOutlinedIcon />
        },
        {
            title: 'Tags',
            href: '/tags/',
            icon: <LabelOutlinedIcon />
        },
        {
            title: 'Ads',
            href: '/ads/',
            icon: <VideoLabelOutlinedIcon />
        },
        {
            title: 'Pages',
            href: '/pages/',
            icon: <WebOutlinedIcon />
        },
        {
            title: 'Term & Condition',
            href: '/term/',
            icon: <LockOutlinedIcon />
        },
    ];
    let centerPages = [
        {
            title: 'Properties',
            href: '/properties/',
            icon: <HomeWorkOutlinedIcon />
        },
        {
            title: 'Projects',
            href: '/projects',
            icon: <ApartmentOutlinedIcon />
        },
        {
            title: 'Developers',
            href: '/developers',
            icon: <BusinessCenterOutlinedIcon />
        },
        {
            title: 'Inbox',
            href: '/inbox',
            icon: <ForumOutlinedIcon />,
            noti: user.inboxNoti,
        },
        {
            title: 'Orders',
            href: '/orders',
            icon: <MonetizationOnOutlinedIcon />
        },
    ];
    let LowerPages = [
        {
            title: 'Users',
            href: '/users/',
            icon: <PeopleOutlinedIcon />
        },
        {
            title: 'Settings',
            href: '/settings',
            icon: <SettingsOutlinedIcon />
        }
    ];

    if (user.role === 'writer'){
        pages = [
            {
                title: 'Articles',
                href: '/articles/',
                icon: <DashboardOutlinedIcon />
            },
        ];
        LowerPages = [
            {
                title: 'Settings',
                href: '/settings',
                icon: <SettingsOutlinedIcon />
            }
        ];
    }

    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
                <Profile user={user} />
                <Divider className={classes.divider} />
                <SidebarNav pages={pages} />
                <Divider />
                <SidebarNav pages={centerPages} />
                <Divider />
                <Hidden mdDown>
                    <SidebarNav pages={LowerPages} />
                </Hidden>
                <Hidden lgUp>
                    <List>
                        {LowerPages.map(page => (
                            <ListItem
                                className={classes.item}
                                disableGutters
                                key={page.title}
                            >
                                <Button
                                    activeClassName={classes.active}
                                    className={classes.button}
                                    component={CustomRouterLink}
                                    to={page.href}
                                >
                                    <div className={classes.icon}>{page.icon}</div>
                                    {page.title}
                                </Button>
                            </ListItem>
                        ))}
                        <ListItem
                            className={classes.item}
                            disableGutters
                        >
                            <Button
                                className={classes.button}
                                onClick={() => postSignOut()}
                            >
                                <div className={classes.icon}><InputIcon /></div>
                                Logout
                            </Button>
                        </ListItem>
                    </List>
                </Hidden>
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired
};

export default Sidebar;
