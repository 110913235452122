import React, { forwardRef } from 'react';
import { NavLink as RouterLink, Redirect  } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Button,
    TextField,
    CircularProgress,
    Avatar,
    Typography,
    Box,
} from '@material-ui/core';
import _ from 'lodash';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    progress: {
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
    },
    avatar: {
        height: 100,
        width: 100,
        textTransform: 'uppercase',
    },
    bgAvatar:{
        backgroundColor: "#00abbd",
    },
    action: {
        padding: theme.spacing(2),
    },
    uploadButton: {
        marginRight: theme.spacing(2),
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const AccountDetails = props => {
    const classes = useStyles();
    const { 
        loading,
        touched,
        errors,
        isValid,
        user,
        updateFunc,
        postUpdateFunc,
        userFailMessage,
        userSuccessMessage,
        profileIsLoading,
        postProfile,
        removeProfile,
    } = props;

    const handleChange = event => {
        updateFunc(user, event.target.name, event.target.value);
    };

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)) {
            return touched[field] && errors[field] ? true : false;
        }
        return false;
    };

    const handleSelect = (event) => {
        const file = event.target.files[0];
        postProfile(file);
    };

    const handleSubmit = () => {
        postUpdateFunc(user);
    };

    if (userSuccessMessage !== ''){
        return (
            <Redirect to="/users/all" />
        );
    }

    return (
        <Card className={classes.root}>
            <form
                autoComplete="off"
                noValidate
            >
                <CardHeader
                    title="User Information"
                    subheader="The information can be edited"
                />
                <Divider />
                <CardContent style={{position: 'relative'}}>
                    {loading && <CircularProgress className={classes.progress} />}
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Email"
                                margin="dense"
                                name="email"
                                onChange={handleChange}
                                required
                                value={user.email}
                                variant="outlined"
                                error={hasError('email')}
                                helperText={
                                    hasError('email') ? errors.email[0] : null
                                }
                                disabled={loading}
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Password"
                                margin="dense"
                                name="password"
                                onChange={handleChange}
                                required
                                value={user.password}
                                variant="outlined"
                                error={hasError('password')}
                                helperText={
                                    hasError('password') ? errors.password[0] : null
                                }
                                disabled={loading}
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="First name"
                                margin="dense"
                                name="fname"
                                onChange={handleChange}
                                required
                                value={user.fname}
                                variant="outlined"
                                error={hasError('fname')}
                                helperText={
                                    hasError('fname') ? errors.fname[0] : null
                                }
                                disabled={loading}
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Last name"
                                margin="dense"
                                name="lname"
                                onChange={handleChange}
                                required
                                value={user.lname}
                                variant="outlined"
                                error={hasError('lname')}
                                helperText={
                                    hasError('lname') ? errors.lname[0] : null
                                }
                                disabled={loading}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Description"
                                margin="dense"
                                name="desc"
                                onChange={handleChange}
                                value={user.desc}
                                variant="outlined"
                                error={hasError('desc')}
                                helperText={
                                    hasError('desc') ? errors.desc[0] : null
                                }
                                disabled={loading}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                    <div>
                        <div style={{ marginBottom: 32 }}>
                            <Typography
                                gutterBottom
                                variant="h5"
                            >
                                Profile
                            </Typography>
                        </div>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            {(profileIsLoading || loading) && <CircularProgress className={classes.progress} color="inherit" />}
                            {
                                (user.profile === "" || user.profile === null) ?
                                    <Avatar
                                        color="primary"
                                        className={clsx(classes.avatar, classes.bgAvatar)}>
                                        {user.fname && user.fname.charAt(0)}
                                    </Avatar>
                                    :
                                    <Avatar
                                        className={classes.avatar}
                                        src={user.profile}
                                    />
                            }
                        </div>
                    </div>
                </CardContent>
                <CardActions className={classes.action}>
                    <label htmlFor="add-image">
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="add-image"
                            name="add-image"
                            type="file"
                            disabled={profileIsLoading}
                            onChange={handleSelect}
                        />
                        <Button
                            className={classes.uploadButton}
                            color="primary"
                            variant="text"
                            component="div"
                            htmlFor="add-image"
                            disabled={profileIsLoading || loading}
                        >
                            Upload
                        </Button>
                    </label>
                    <Button
                        variant="text"
                        disabled={profileIsLoading || loading}
                        onClick={() => removeProfile()}
                    >
                        Remove
                    </Button>
                </CardActions>
                {userFailMessage !== '' &&
                    <Box p={2}>
                        <Typography
                            style={{ color: "#de0032" }}
                            variant="caption"
                        >
                            {userFailMessage}
                        </Typography>
                    </Box>
                }
                <Divider />
                <CardActions style={{ padding: 16 }}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!isValid || loading || profileIsLoading}
                        onClick={handleSubmit}
                    >
                        Update
                    </Button>
                    <Button
                        color="default"
                        variant="outlined"
                        component={CustomRouterLink}
                        to="/users/all"
                    >
                        Cancel
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

export default AccountDetails;
