import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Logo from 'common/wht.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        backgroundColor: theme.palette.background.default,
    },
    main: {
        height: '100%'
    },
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#00abbd",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
}));

const Minimal = props => {
    const { children } = props;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <main className={classes.main}>
                <Grid
                    className={classes.grid}
                    container
                >
                    <Grid
                        className={classes.quoteContainer}
                        item
                        lg={5}
                    >
                        <div className={classes.quote}>
                            <div className={classes.quoteInner}>
                                <img src={Logo} alt="logo" style={{ height: 150 }} />
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        className={classes.content}
                        item
                        lg={7}
                        xs={12}
                    >
                        {children}
                    </Grid>
                </Grid>
            </main>
        </div>
    );
};

Minimal.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Minimal;
