import { connect } from 'react-redux';
import { updateForgot, postForgot, resetAuth } from 'actions/auth';
import ForgotPass from 'views/ForgotPass';

const mapStateToProps = state => ({
    loading: state.auth.loading,
    forgotEmail: state.auth.forgotEmail,
    errors: state.auth.errors,
    isValid: state.auth.isValid,
    touched: state.auth.touched,
    message: state.auth.message,
    successMessage: state.auth.successMessage,
});

const mapDispatchToProps = {
    updateForgot,
    postForgot,
    resetAuth,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPass);

