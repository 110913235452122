import { connect } from 'react-redux';
import { getUser, updateDetail, postUpdateDetail, updateEmail, postUpdateEmail,
    updatePassword, postUpdatePassword, postProfile, postRemoveProfile,
} from 'actions/settings';
import Settings from 'views/Settings';

const mapStateToProps = state => ({
    // page
    loading: state.settings.loading,

    // detail
    detailIsLoading: state.settings.detailIsLoading,
    user: state.settings.user,
    userErrors: state.settings.userErrors,
    userTouched: state.settings.userTouched,

    // email
    email: state.settings.email,
    emailErrors: state.settings.emailErrors,
    emailTouched: state.settings.emailTouched,
    emailIsLoading: state.settings.emailIsLoading,
    emailIsValid: state.settings.emailIsValid,
    emailSuccessMessage: state.settings.emailSuccessMessage,
    emailFailMessage: state.settings.emailFailMessage,

    // password
    password: state.settings.password,
    passwordErrors: state.settings.passwordErrors,
    passwordTouched: state.settings.passwordTouched,
    passwordIsLoading: state.settings.passwordIsLoading,
    passwordIsValid: state.settings.passwordIsValid,
    passwordSuccessMessage: state.settings.passwordSuccessMessage,
    passwordFailMessage: state.settings.passwordFailMessage,

    // profile
    profileIsLoading: state.settings.profileIsLoading,

});

const mapDispatchToProps = {
    getUser,
    updateDetail,
    postUpdateDetail,
    updateEmail,
    postUpdateEmail,
    updatePassword,
    postUpdatePassword,
    postProfile,
    postRemoveProfile,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings);

