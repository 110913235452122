import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Switch,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
    root: {
        left: -12,
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    cover:{
        maxWidth: 120,
        maxHeight: 120,
        height: 'auto',
        borderRadius: 8,
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const TableList = props => {
    const { 
        list, 
        selected,
        hideToggle,
        resetAds,
        postDeleteAds, 
        toggleStatus,
    } = props;
    const classes = useStyles();

    const [state, setState] = React.useState({
        anchorEl: null,
        openDialog: false,
    });


    function handleClickOpenDialog() {
        setState({
            ...state,
            anchorEl: null,
            openDialog: true
        });
    }
    function handleCloseDialog() {
        setState({
            ...state,
            openDialog: false,
        });
    }
    function handleDeleteAds() {
        postDeleteAds(list.uid);
        setState({
            ...state,
            openDialog: false,
        });
    }

    // Menu
    function handleClick(event) {
        setState({
            ...state, 
            anchorEl: event.currentTarget
        });
    }
    function handleClose() {
        setState({
            ...state,
            anchorEl: null
        });
    }

    // Toggle
    const handleChange = () => () => {
        toggleStatus(list.uid, !list.active);
    };
    
    return (
        <TableRow
            className={classes.tableRow}
            hover
            selected={selected}
        >
            <TableCell>
                {list.createdAt}
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <Typography variant="body1" style={{textTransform: 'capitalize'}}>{list.position}</Typography>
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <img className={classes.cover} src={list.desktop} alt="cover" />
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <img className={classes.cover} src={list.mobile} alt="cover" />
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <Typography variant="body1">{list.seen}</Typography>
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <Typography variant="body1">{list.uniqueSeen}</Typography>
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <Typography variant="body1">{list.click}</Typography>
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <Typography variant="body1">{list.uniqueClick ? list.uniqueClick : 0}</Typography>
                </div>
            </TableCell>
            <TableCell>
                {   !hideToggle &&
                    <Switch
                        classes={{
                            root: classes.root,
                        }}
                        checked={list.active}
                        onChange={handleChange()}
                        value="checked"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }
            </TableCell>
            <TableCell>
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                {/* -- Menu -- */}
                <Menu
                    id="simple-menu"
                    anchorEl={state.anchorEl}
                    keepMounted
                    open={Boolean(state.anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem 
                        component={CustomRouterLink}
                        to={`/ads/edit/${list.uid}`}
                        onClick={() => resetAds()}
                    >
                        Edit
                    </MenuItem>
                    <MenuItem onClick={handleClickOpenDialog}>Delete</MenuItem>
                </Menu>
                {/* -- Dialog -- */}
                <Dialog
                    open={state.openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" disableTypography>
                        <Typography variant="h4">Delete Ads</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this ads?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{padding: 16}}>
                        <Button onClick={handleDeleteAds} color="primary" >
                            Delete
                        </Button>
                        <Button onClick={handleCloseDialog} color="primary" autoFocus>
                            No Thank
                        </Button>
                    </DialogActions>
                </Dialog>
            </TableCell>
        </TableRow>             
    );
};

TableList.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    list: PropTypes.object.isRequired
};

export default TableList;
