export default {
    type: [
        {
            uid: 'ready',
            name: 'พร้อมเข้าอยู่',
        },
        {
            uid: 'underConstruction',
            name: 'กำลังก่อสร้าง',
        },
    ],
};
