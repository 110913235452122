import { connect } from 'react-redux';
import {
    changeLang,
    changeCategory,
    changeGeo,
    update,
    postEdit,
    getEdit,
} from 'actions/locations';

import LocationEdit from 'views/Location/Edit';

const mapStateToProps = state => ({
    loading: state.locations.loading,
    lang: state.locations.lang,
    data: state.locations.data,
    isValid: state.locations.isValid,
    successMessage: state.locations.successMessage,
});

const mapDispatchToProps = {
    changeLang,
    changeCategory,
    changeGeo,
    update,
    postEdit,
    getEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationEdit);

