import { connect } from 'react-redux';
import {
    getList,
    postDelete,
    resetData,
    querySearch,
    loadmore,
    showless,
    queryByIndex,
    togglePin,
    toggleHide,
} from 'actions/webboard';
import WebboardList from 'views/Webboard/List';

const mapStateToProps = state => ({
    loading: state.webboard.loading,
    list: state.webboard.list,
    isSearching: state.webboard.isSearching,
    loadingMore: state.webboard.loadingMore,
    showLoadingMore: state.webboard.showLoadingMore,
    startIndex: state.webboard.startIndex,
    endIndex: state.webboard.endIndex,
    numberOfDocs: state.webboard.numberOfDocs,
});

const mapDispatchToProps = {
    getList,
    postDelete,
    resetData,
    querySearch,
    loadmore,
    showless,
    queryByIndex,
    togglePin,
    toggleHide,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WebboardList);