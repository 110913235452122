import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import _ from 'lodash';
import moment from 'moment';
import stripHtml from "string-strip-html";
import firebase from 'common/firebase';
import { showSnack } from './main';
const functions = firebase.app().functions('asia-east2');

const schema = {
    categoryId: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    subCatId: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    cover: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    nameTh: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    descTh: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
// -- CREATE/UPDATE
export const SET_ARTICLE_LANG = 'SET_ARTICLE_LANG';
export const SET_ARTICLE_CATEGORY = 'SET_ARTICLE_CATEGORY';
export const SET_ARTICLE_TAG = 'SET_ARTICLE_TAG';
export const REMOVE_ARTICLE_TAG = 'REMOVE_ARTICLE_TAG';
export const SET_ARTICLE_LOCATION = 'SET_ARTICLE_LOCATION';
export const SET_ARTICLE_COVER = 'SET_ARTICLE_COVER';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const RESET_ARTICLE = 'RESET_ARTICLE';
export const FETCH_ARTICLE = 'FETCH_ARTICLE';
export const FETCH_ARTICLE_DATA_DONE = 'FETCH_ARTICLE_DATA_DONE';
export const FETCH_ARTICLE_CREATE_DATA_DONE = 'FETCH_ARTICLE_CREATE_DATA_DONE';
export const FETCH_ARTICLE_DONE = 'FETCH_ARTICLE_DONE';
export const FETCH_ARTICLE_FAIL = 'FETCH_ARTICLE_FAIL';
export const UPDATE_ARTICLE_SEARCH = 'UPDATE_ARTICLE_SEARCH';

// -- LIST
export const FETCH_ARTICLE_LIST_DONE = 'FETCH_ARTICLE_LIST_DONE';
export const CHANGE_ARTICLE_STATUS = 'CHANGE_ARTICLE_STATUS';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const FETCH_ARTICLE_LOADMORE = 'FETCH_ARTICLE_LOADMORE';
export const FETCH_ARTICLE_LOADMORE_DONE = 'FETCH_ARTICLE_LOADMORE_DONE';
export const FETCH_ARTICLE_SHOWLESS = 'FETCH_ARTICLE_SHOWLESS';
export const UPDATE_ARTICLE_INDEX = 'UPDATE_ARTICLE_INDEX';
export const FETCH_ARTICLE_INDEX_DONE = 'FETCH_ARTICLE_INDEX_DONE';

// Sync Action Creator
// -- Create/Update --
export function changeLang(lang) {
    return {
        type: SET_ARTICLE_LANG,
        lang
    };
};
export function querySearch(newValue) {
    return {
        type: UPDATE_ARTICLE_SEARCH,
        newValue,
    };
}
export function changeCategory(catId,subCatId,oldData) {
    oldData.categoryId = catId;
    oldData.subCatId = subCatId;
    const validateData = {
        categoryId: oldData.categoryId,
        subCatId: oldData.subCatId,
        cover: oldData.cover,
        nameTh: oldData.name.th,
        descTh: oldData.desc.th,
    }
    const errors = validate(validateData, schema);

    return {
        type: SET_ARTICLE_CATEGORY,
        catId,
        subCatId,
        errors,
    };
};
export function changeTag(tags,oldData) {
    const validateData = {
        categoryId: oldData.categoryId,
        subCatId: oldData.subCatId,
        cover: oldData.cover,
        nameTh: oldData.name.th,
        descTh: oldData.desc.th,
    }
    const errors = validate(validateData, schema);
    return {
        type: SET_ARTICLE_TAG,
        tags,
        errors,
    };
};
export function removeTag(index, oldData) {
    const validateData = {
        categoryId: oldData.categoryId,
        subCatId: oldData.subCatId,
        cover: oldData.cover,
        nameTh: oldData.name.th,
        descTh: oldData.desc.th,
    }
    const errors = validate(validateData, schema);
    return {
        type: REMOVE_ARTICLE_TAG,
        index,
        errors,
    };
};
export function changeLocation(locationId, oldData) {
    const validateData = {
        categoryId: oldData.categoryId,
        subCatId: oldData.subCatId,
        cover: oldData.cover,
        nameTh: oldData.name.th,
        descTh: oldData.desc.th,
    }
    const errors = validate(validateData, schema);
    return {
        type: SET_ARTICLE_LOCATION,
        locationId,
        errors,
    };
};
export function changeCover(cover, oldData) {
    oldData.cover = cover;
    const validateData = {
        categoryId: oldData.categoryId,
        subCatId: oldData.subCatId,
        cover: oldData.cover,
        nameTh: oldData.name.th,
        descTh: oldData.desc.th,
    }
    const errors = validate(validateData, schema);
    return {
        type: SET_ARTICLE_COVER,
        cover,
        errors
    };
};

export function update(oldData, lang, fieldName, newValue) {
    oldData[fieldName][lang] = newValue;

    const validateData = {
        categoryId: oldData.categoryId,
        subCatId: oldData.subCatId,
        cover: oldData.cover,
        nameTh: oldData.name.th,
        descTh: oldData.desc.th,
    }
    const errors = validate(validateData, schema);
    return {
        type: UPDATE_ARTICLE,
        lang,
        fieldName,
        newValue,
        errors: errors,
    };
};

export function resetData() {
    return {
        type: RESET_ARTICLE,
    };
};
export function fetch() {
    return {
        type: FETCH_ARTICLE,
    };
};
export function fetchDataDone(data) {
    return {
        type: FETCH_ARTICLE_DATA_DONE,
        data,
    };
};
export function fetchDone(message,status) {
    return {
        type: FETCH_ARTICLE_DONE,
        message,
        status,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_ARTICLE_FAIL,
        message,
    };
};

// -- LIST --
export function fetchCreateDataDone(){
    return {
        type: FETCH_ARTICLE_CREATE_DATA_DONE,
    };
};
export function fetchListDone(data) {
    return {
        type: FETCH_ARTICLE_LIST_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function fetchIndexDone(data) {
    return {
        type: FETCH_ARTICLE_INDEX_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function changeArticleStatus(uid, newStatus) {
    return {
        type: CHANGE_ARTICLE_STATUS,
        uid,
        newStatus,
    };
};
export function deleteData(uid) {
    return {
        type: DELETE_ARTICLE,
        uid,
    };
};
export function fetchLoadmore() {
    return {
        type: FETCH_ARTICLE_LOADMORE,
    };
};
export function showless() {
    return {
        type: FETCH_ARTICLE_SHOWLESS,
    };
};
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_ARTICLE_LOADMORE_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function updateIndex(startIndex, endIndex) {
    return {
        type: UPDATE_ARTICLE_INDEX,
        startIndex,
        endIndex,
    };
};

function slugify(text) {
    const uri = text.toString().toLowerCase().trim()
        // eslint-disable-next-line no-useless-escape
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
        .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single _
        .replace(/^-+|-+$/g, ''); // remove leading, trailing -

    return encodeURIComponent(uri);
}

// Async Action Creator
export function postImage(file, oldData) {
    if (!file) {
        return {
            type: FETCH_ARTICLE_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return function (dispatch) {
        dispatch(fetch());
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`articles/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dispatch(changeCover(downloadURL, oldData));
                dispatch(fetchDone(''));
            });
        });
    };
}
export function getCreateData() {
    return function (dispatch) {
        dispatch(fetch());
        dispatch(fetchCreateDataDone());
    };
}
export function getList(status,categoryId) {
    return function (dispatch) {
        dispatch(fetch());

        const data = {
            status,
            categoryId,
        };
        const articleGetList = functions.httpsCallable('articleGetList');
        return articleGetList(data).then(function (result) {
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchListDone(output));
        }).catch(function (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        });
    };
}
export function postCreate(status,data) {
    return function (dispatch) {
        dispatch(fetch());
        const adminCreator = firebase.auth().currentUser;
        if (adminCreator) {
            const shortDesc = {
                'th': _.truncate(stripHtml(data.desc.th), { 'length': 120, 'separator': ' ' }),
                'en': _.truncate(stripHtml(data.desc.en), { 'length': 100, 'separator': ' ' }),
                'ch': _.truncate(stripHtml(data.desc.ch), { 'length': 100, 'separator': ' ' }),
                'jp': _.truncate(stripHtml(data.desc.jp), { 'length': 100, 'separator': ' ' }),
            };
            const urlString = slugify(data.name.th);

            const inputData = {
                ...data,
                shortDesc,
                url: urlString,
                locationId: _.isEmpty(data.locationId) ? '' : data.locationId.uid,
                tagId: _.isEmpty(data.tagId) ? [] : _.map(data.tagId, 'uid'),
                status: status,
                adminId: adminCreator.uid,
                isHide: false,
                lastmonth: true,
                readTimeEst: 0,
                click: 0,
                read: 0,
                timestamp: moment().valueOf(),
            }

            const ref = firebase.firestore().collection("articles");
            return ref.add(inputData).then(() => {
                dispatch(getList(status,''));
                dispatch(fetchDone('Article created successfully.',status));
                dispatch(showSnack('success', 'Article created successfully.'));
            }).catch((error) => {
                console.log(error);
            });
        } else {
            dispatch(fetchFail("You are not sign in."));
        }
    };
}

export function getEdit(uid) {
    return function (dispatch) {
        dispatch(fetch());

        const articleGetList = functions.httpsCallable('articleGetId');
        return articleGetList(uid).then(function (result) {
            console.log(result.data.data);
            dispatch(fetchDataDone(result.data.data));
        }).catch(function (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        });
    };
}

export function postEdit(status, uid, data) {
    return function (dispatch) {
        dispatch(fetch());

        const shortDesc = {
            'th': _.truncate(stripHtml(data.desc.th), { 'length': 120, 'separator': ' ' }),
            'en': _.truncate(stripHtml(data.desc.en), { 'length': 100, 'separator': ' ' }),
            'ch': _.truncate(stripHtml(data.desc.ch), { 'length': 100, 'separator': ' ' }),
            'jp': _.truncate(stripHtml(data.desc.jp), { 'length': 100, 'separator': ' ' }),
        };
        const urlString = slugify(data.name.th);

        const inputData = {
            ...data,
            shortDesc,
            url: urlString,
            locationId: _.isEmpty(data.locationId) ? '' : data.locationId.uid,
            tagId: _.isEmpty(data.tagId) ? [] : _.map(data.tagId, 'uid'),
            status: status,
            readTimeEst: 0,
        }
        const ref = firebase.firestore().collection("articles");
        return ref.doc(uid).set(inputData, { merge: true }).then(() => {
            dispatch(getList(status, ''));
            dispatch(fetchDone('Article updated successfully.', status));
            dispatch(showSnack('success', 'Article updated successfully.'));
        }).catch((error) => {
            console.log(error);
            dispatch(fetchFail(error.message));
        });        
    };
}

export function postDelete(uid) {
    return function (dispatch) {
        const ref = firebase.firestore().collection("articles").doc(uid);
        return ref.delete().then(function () {
            dispatch(deleteData(uid));
            dispatch(showSnack('success', 'Delete article successfully.'));
        }).catch(function (error) {
            console.error("Error removing document: ", error.message);
        });
    };
};

export function toggleStatus(uid, newStatus) {
    return function (dispatch) {
        dispatch(changeArticleStatus(uid, newStatus));

        const ref = firebase.firestore().collection("articles").doc(uid);
        return ref.set({ isHide: newStatus }, { merge: true }).then(function () {
            dispatch(showSnack('success', 'Change article status successfully.'));
        }).catch(function (error) {
            console.error("Error writing document: ", error);
        });
    };
};

export function loadmore(status, categoryId, lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const data = { 
            status, 
            categoryId: categoryId === 'all' ? '' : categoryId,  
            lastVisibleUid 
        };

        const getLoadmore = functions.httpsCallable('articleLoadmore');
        try {
            const result = await getLoadmore(data);
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchLoadmoreDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function queryByIndex(status, categoryId, startIndex, endIndex) {
    return async (dispatch) => {
        dispatch(fetch());
        if (endIndex <= startIndex) {
            dispatch(showSnack("fail", "End index is less than start index."));
            dispatch(fetchFail("endIndex < startIndex"));
        } else {
            const data = { 
                status, 
                categoryId: categoryId === 'all' ? '' : categoryId,
                startIndex, 
                endIndex 
            };
            const getQuery = functions.httpsCallable('articleIndexQuery');
            try {
                const result = await getQuery(data);
                console.log(result);
                const output = {
                    numberOfDocs: result.data.numberOfDocs,
                    list: result.data.list,
                }
                dispatch(updateIndex(startIndex, endIndex));
                dispatch(fetchIndexDone(output));
            }
            catch (error) {
                console.log(error);
                dispatch(fetchFail(error.message));
            }
        }
    };
}