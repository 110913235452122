import { connect } from 'react-redux';
import {
    getList,
    updateTime,
} from 'actions/order';
import List from 'views/Order/List';

const mapStateToProps = state => ({
    loading: state.order.loading,
    start: state.order.start,
    end: state.order.end,
    list: state.order.list,
});

const mapDispatchToProps = {
    getList,
    updateTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
