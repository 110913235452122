import {
    SET_LOCATION_LANG,
    SET_LOCATION_CATEGORY,
    SET_LOCATION_GEO,
    UPDATE_LOCATION,
    FETCH_LOCATION,
    FETCH_LOCATION_DATA_DONE,
    FETCH_LOCATION_DONE,
    FETCH_LOCATION_FAIL,
    RESET_LOCATION,
    FETCH_LOCATION_LIST_DONE,
    DELETE_LOCATION,
    UPDATE_LOCATION_SEARCH,
    FETCH_LOCATION_LOADMORE,
    FETCH_LOCATION_LOADMORE_DONE,
    FETCH_LOCATION_SHOWLESS,
    UPDATE_LOCATION_INDEX,
    FETCH_LOCATION_INDEX_DONE,
} from '../actions/locations';

const initialState = {
    loading: false,
    lang: 'th',
    isSearching: false,
    list: [],
    loadingMore: false,
    showLoadingMore: true,
    startIndex: 1,
    endIndex: 25,
    numberOfDocs: 0,
    data: {
        categoryId: '',
        lat: 13.74285,
        lng: 100.5485,
        search: '',
        name: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
        desc: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const locationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOCATION_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case SET_LOCATION_CATEGORY:
            return {
                ...state,
                data: {
                    ...state.data,
                    categoryId: action.category,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case SET_LOCATION_GEO:
            return {
                ...state,
                data: {
                    ...state.data,
                    lat: action.lat,
                    lng: action.lng,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case RESET_LOCATION:
            return {
                ...initialState,
                data: {
                    categoryId: '',
                    lat: 13.74285,
                    lng: 100.5485,
                    search: '',
                    name: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                    desc: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                },
            };
        case UPDATE_LOCATION:
            if (action.lang === 'th' && action.fieldName === 'name'){
                return {
                    ...state,
                    data: {
                        ...state.data,
                        search: action.newValue,
                        name: {
                            ...state.data.name,
                            [action.lang]: action.newValue,
                        },
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: {
                        ...state.data[action.fieldName],
                        [action.lang]: action.newValue,
                    },
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case UPDATE_LOCATION_SEARCH:
            return {
                ...state,
                isSearching: action.newValue !== '',
            };
        case FETCH_LOCATION:
            return {
                ...state,
                loading: true,
                loadingMore: false,
                showLoadingMore: true,
            };
        case FETCH_LOCATION_LOADMORE:
            return {
                ...state,
                loadingMore: true,
            };
        case FETCH_LOCATION_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_LOCATION_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        case FETCH_LOCATION_LIST_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                showLoadingMore: action.list.length < action.numberOfDocs,
                startIndex: 1,
                endIndex: action.list.length,
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_LOCATION_INDEX_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                showLoadingMore: (state.startIndex + action.list.length - 1) < action.numberOfDocs,
                endIndex: (state.startIndex + action.list.length - 1),
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_LOCATION_LOADMORE_DONE:
            return {
                ...state,
                loadingMore: false,
                endIndex: (state.endIndex + action.list.length),
                showLoadingMore: (state.endIndex + action.list.length) < state.numberOfDocs,
                list: [
                    ...state.list,
                    ...action.list,
                ],
                numberOfDocs: action.list.length < 25 ? state.endIndex + action.list.length : action.numberOfDocs,
            };
        case FETCH_LOCATION_SHOWLESS:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: true,
                list: [
                    ...state.list.slice(0, state.list.length - 25),
                ],
                endIndex: state.endIndex - 25,
            };
        case UPDATE_LOCATION_INDEX:
            return {
                ...state,
                startIndex: action.startIndex,
            };
        case DELETE_LOCATION:
            const index = state.list.findIndex(data => data.uid === action.uid);
            let newList = [...state.list];
            newList.splice(index, 1);
            return {
                ...state,
                list: newList
            };
        default:
            return state;
    }
};
export default locationsReducer;
