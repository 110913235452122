import { connect } from 'react-redux';
import {
    changeLang,
    updateTag,
    postTagEdit,
    getTagEdit,
} from 'actions/tags';

import TagEdit from 'views/Tag/Edit';

const mapStateToProps = state => ({
    loading: state.tags.loading,
    lang: state.tags.lang,
    tag: state.tags.tag,
    errors: state.tags.errors,
    isValid: state.tags.isValid,
    successMessage: state.tags.successMessage,
});

const mapDispatchToProps = {
    changeLang,
    updateTag,
    postTagEdit,
    getTagEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagEdit);

