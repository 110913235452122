export default {
    page: {
        all: {
            name: 'All Page Ads',
            value: {
                a1: {
                    desktop: {
                        width: 728,
                        height: 90,
                    },
                    mobile: {
                        width: 360,
                        height: 64,
                    },
                },
                a4: {
                    desktop: {
                        width: 728,
                        height: 90,
                    },
                    mobile: {
                        width: 360,
                        height: 64,
                    },
                },
                popup: {
                    desktop: {
                        width: 600,
                        height: 450,
                    },
                    mobile: {
                        width: 320,
                        height: 240,
                    },
                },
            },
        },
        side: {
            name: 'Side Ads',
            value: {
                a2: {
                    desktop: {
                        width: 179,
                        height: 384,
                    },
                    mobile: {
                        width: 360,
                        height: 64,
                    },
                },
                a3: {
                    desktop: {
                        width: 179,
                        height: 384,
                    },
                    mobile: {
                        width: 360,
                        height: 64,
                    },
                },
            },
        },
        home: {
            name: 'Home Ads',
            value: {
                h1: {
                    desktop: {
                        width: 1200,
                        height: 458,
                    },
                    mobile: {
                        width: 360,
                        height: 275,
                    },
                },
                h2: {
                    desktop: {
                        width: 383,
                        height: 512,
                    },
                    mobile: {
                        width: 328,
                        height: 360,
                    },
                },
            },
        },
    },
};
