import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Menu,
    MenuItem,
    Switch,
    TableCell,
    TableRow,
    Typography,
    IconButton,
    // Dialog,
    // DialogActions,
    // DialogContent,
    // DialogContentText,
    // DialogTitle,
    // Button
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        left: -12,
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2),
        textTransform: 'uppercase',
    },
    bgAvatar: {
        backgroundColor: "#00abbd",
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const UsersTableList = props => {
    const { 
        user, 
        toggleStatus, 
        // postDeleteUser 
    } = props;
    const classes = useStyles();

    const [state, setState] = React.useState({
        anchorEl: null,
        openDialog: false,
    });


    // function handleClickOpenDialog() {
    //     setState({
    //         ...state,
    //         anchorEl: null,
    //         openDialog: true
    //     });
    // }

    // function handleDeleteUser() {
    //     postDeleteUser(user.uid);
    //     setState({
    //         ...state,
    //         openDialog: false,
    //     });
    // }

    // function handleCloseDialog() {
    //     setState({
    //         ...state,
    //         openDialog: false,
    //     });
    // }

    // Menu
    function handleClick(event) {
        setState({
            ...state, 
            anchorEl: event.currentTarget
        });
    }
    function handleClose() {
        setState({
            ...state,
            anchorEl: null
        });
    }

    // Toggle
    const handleChange = () => () => {
        toggleStatus(user.uid,!user.disabled);
    };
    
    return (
        <TableRow
            className={classes.tableRow}
            hover
        >
            <TableCell>
                {moment(user.createdAt).format('D MMM YYYY, HH:mm')}
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    {
                        !user.profile ?
                            <Avatar
                                color="primary"
                                className={clsx(classes.avatar,classes.bgAvatar)}>
                                {user.name && user.name.charAt(0)}
                            </Avatar>
                            :
                            <Avatar
                                className={classes.avatar}
                                src={user.profile}
                            >
                                {user.name && user.name.charAt(0)}
                            </Avatar>
                    }
                    <Typography variant="body1">{user.name}</Typography>
                </div>
            </TableCell>
            <TableCell>
                {user.email}
            </TableCell>
            <TableCell style={{ textTransform: 'capitalize' }}>
                {user.role}
            </TableCell>
            <TableCell>
                {user.role === 'writer' && 
                    <Switch
                        classes={{
                            root: classes.root,
                        }}
                        checked={!user.disabled}
                        onChange={handleChange()}
                        value="checked"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }
            </TableCell>
            <TableCell>
                {user.role === 'writer' && 
                    <>
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        {/* -- Menu -- */}
                        <Menu
                            id="simple-menu"
                            anchorEl={state.anchorEl}
                            keepMounted
                            open={Boolean(state.anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem 
                                component={CustomRouterLink}
                                to={`/users/edit/${user.uid}`}
                            >
                                Edit
                            </MenuItem>
                            {/* <MenuItem onClick={handleClickOpenDialog}>Delete</MenuItem> */}
                        </Menu>
                        {/* -- Dialog -- */}
                        {/* <Dialog
                            open={state.openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" disableTypography>
                                <Typography variant="h4">Delete User</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete user?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{padding: 16}}>
                                <Button onClick={handleDeleteUser} color="primary" >
                                    Delete
                                </Button>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    No Thank
                                </Button>
                            </DialogActions>
                        </Dialog> */}
                    </>
                }
            </TableCell>
        </TableRow>             
    );
};

UsersTableList.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.object.isRequired
};

export default UsersTableList;
