import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { 
    Paper, 
    InputBase, 
    MenuItem,
    Select, 
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        maxWidth: 200,
        // borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '-0.05px'
    },
    formControl: {
        width: '100%',
        maxWidth: 200,
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const SearchInput = props => {
    const { 
        value,
        listItem, 
    } = props;
    const classes = useStyles();

    return (
        <Paper className={classes.paper} >
            <Select
                value={value}
                input={
                    <InputBase  
                        className={classes.input} 
                        name="category" 
                        id="outlined-age-simple" 
                    />
                }
            >
                {
                    listItem.map((item, key) =>
                        <MenuItem
                            value={item.value}
                            component={CustomRouterLink}
                            key={item.title}
                            to={item.href}
                        >
                            {item.title}
                        </MenuItem>
                    )
                }
            </Select>
        </Paper>
    );
};

export default SearchInput;
