import validate from 'validate.js';
import moment from 'moment';
import firebase from 'common/firebase';
import { showSnack } from './main';

const functions = firebase.app().functions('asia-east2');

const schema = {
    nameTh: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    descTh: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    categoryId: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    lat: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    lng: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
// -- CREATE/UPDATE
export const SET_LOCATION_LANG = 'SET_LOCATION_LANG';
export const SET_LOCATION_CATEGORY = 'SET_LOCATION_CATEGORY';
export const SET_LOCATION_GEO = 'SET_LOCATION_GEO';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_LOCATION_DATA_DONE = 'FETCH_LOCATION_DATA_DONE';
export const FETCH_LOCATION_DONE = 'FETCH_LOCATION_DONE';
export const FETCH_LOCATION_FAIL = 'FETCH_LOCATION_FAIL';
export const RESET_LOCATION = 'RESET_LOCATION';
export const UPDATE_LOCATION_SEARCH = 'UPDATE_LOCATION_SEARCH';

// -- LIST
export const FETCH_LOCATION_LIST_DONE = 'FETCH_LOCATION_LIST_DONE';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const FETCH_LOCATION_LOADMORE = 'FETCH_LOCATION_LOADMORE';
export const FETCH_LOCATION_LOADMORE_DONE = 'FETCH_LOCATION_LOADMORE_DONE';
export const FETCH_LOCATION_SHOWLESS = 'FETCH_LOCATION_SHOWLESS';
export const UPDATE_LOCATION_INDEX = 'UPDATE_LOCATION_INDEX';
export const FETCH_LOCATION_INDEX_DONE = 'FETCH_LOCATION_INDEX_DONE';

// Sync Action Creator
// -- Create/Update --
export function changeLang(lang) {
    return {
        type: SET_LOCATION_LANG,
        lang
    };
};
export function querySearch(newValue) {
    return {
        type: UPDATE_LOCATION_SEARCH,
        newValue,
    };
}
export function changeCategory(category,oldData) {
    oldData.categoryId = category;
    const validateData = {
        nameTh: oldData.name.th,
        descTh: oldData.desc.th,
        categoryId: oldData.categoryId,
        lat: oldData.lat,
        lng: oldData.lng,
    }
    const errors = validate(validateData, schema);

    return {
        type: SET_LOCATION_CATEGORY,
        category,
        errors: errors,
    };
};
export function changeGeo(lat,lng, oldData) {
    oldData.lat = lat;
    oldData.lng = lng;
    const validateData = {
        nameTh: oldData.name.th,
        descTh: oldData.desc.th,
        categoryId: oldData.categoryId,
        lat: oldData.lat,
        lng: oldData.lng,
    }
    const errors = validate(validateData, schema);

    return {
        type: SET_LOCATION_GEO,
        lat, 
        lng,
        errors,
    };
};
export function resetData() {
    return {
        type: RESET_LOCATION,
    };
};
export function fetch() {
    return {
        type: FETCH_LOCATION,
    };
};
export function fetchDataDone(data) {
    return {
        type: FETCH_LOCATION_DATA_DONE,
        data,
    };
};
export function fetchDone(message) {
    return {
        type: FETCH_LOCATION_DONE,
        message,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_LOCATION_FAIL,
        message,
    };
};
export function update(oldData, lang, fieldName, newValue) {
    oldData[fieldName][lang] = newValue;

    const validateData = {
        nameTh: oldData.name.th,
        descTh: oldData.desc.th,
        categoryId: oldData.categoryId,
        lat: oldData.lat,
        lng: oldData.lng,
    }
    const errors = validate(validateData, schema);

    return {
        type: UPDATE_LOCATION,
        lang,
        fieldName,
        newValue,
        errors: errors,
    };
};

// -- LIST --
export function fetchListDone(data) {
    return {
        type: FETCH_LOCATION_LIST_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function fetchIndexDone(data) {
    return {
        type: FETCH_LOCATION_INDEX_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function deleteData(uid) {
    return {
        type: DELETE_LOCATION,
        uid,
    };
};
export function fetchLoadmore() {
    return {
        type: FETCH_LOCATION_LOADMORE,
    };
};
export function showless() {
    return {
        type: FETCH_LOCATION_SHOWLESS,
    };
};
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_LOCATION_LOADMORE_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function updateIndex(startIndex, endIndex) {
    return {
        type: UPDATE_LOCATION_INDEX,
        startIndex,
        endIndex,
    };
};

// Async Action Creator
export function getList() {
    return async function (dispatch) {
        dispatch(fetch());

        const getList = functions.httpsCallable('locationBackendGetList');
        try {
            const result = await getList();
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchListDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function postCreate(data) {
    return function (dispatch) {
        dispatch(fetch());

        const ref = firebase.firestore().collection("locations");
        ref.where("search", "==", data.search).get().then((searchResults) => {
            if (searchResults.size > 0){
                dispatch(showSnack('fail', 'Location name has already been created"'));
                return dispatch(fetchFail('Location name has already been created"'));
            }

            const inputData = {
                categoryId: data.categoryId,
                location: new firebase.firestore.GeoPoint(data.lat,data.lng),
                search: data.search,
                name: data.name,
                desc: data.desc,
                timestamp: moment().valueOf(),
            }

            return ref.add(inputData).then(() => {
                dispatch(getList());
                dispatch(fetchDone('Location created successfully.'));
                dispatch(showSnack('success', 'Location created successfully.'));
            }).catch((error) => {
                console.log(error);
            });
        }).catch((error) => {
            console.log(error);
        });
    };
}

export function getEdit(uid) {
    return function (dispatch) {
        dispatch(fetch());
        const docRef = firebase.firestore().collection("locations").doc(uid);

        return docRef.get().then((doc) => {
            if (doc.exists) {
                const allData = doc.data();
                const data = {
                    categoryId: allData.categoryId,
                    lat: allData.location._lat,
                    lng: allData.location._long,
                    search: allData.search,
                    name: allData.name,
                    desc: allData.desc,
                };
                dispatch(fetchDataDone(data));
            } else {
                // doc.data() will be undefined in this case
                dispatch(showSnack('fail', "No such document!"));
                dispatch(fetchFail("No such document!"));
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
            dispatch(fetchFail(error.message));
        });
    };
}

export function postEdit(uid, data) {
    return function (dispatch) {
        dispatch(fetch());

        const docRef = firebase.firestore().collection("locations");
        docRef.where("search", "==", data.search).get().then((searchResults) => {
            let resultLength = 0;
            searchResults.forEach(function (doc) {
                if (doc.id !== uid){
                    resultLength+=1;
                }
            });

            if (resultLength > 0) {
                dispatch(showSnack('fail', 'Location name has already been created"'));
                return dispatch(fetchFail('Location name has already been created"'));
            }

            const inputData = {
                categoryId: data.categoryId,
                location: new firebase.firestore.GeoPoint(data.lat, data.lng),
                search: data.search,
                name: data.name,
                desc: data.desc,
            }

            return docRef.doc(uid).set(inputData, { merge: true }).then(() => {
                dispatch(fetchDone('Location updated successfully.'));
                dispatch(showSnack('success', 'Location updated successfully.'));
            }).catch((error) => {
                console.log(error);
                dispatch(fetchFail(error.message));
            });
        }).catch((error) => {
            console.log(error);
        });
    };
}

export function postDelete(uid) {
    return function (dispatch) {
        const docRef = firebase.firestore().collection("locations").doc(uid);
        return docRef.delete().then(function () {
            dispatch(deleteData(uid));
            dispatch(showSnack('success', 'Delete location successfully.'));
        }).catch(function (error) {
            console.error("Error removing document: ", error.message);
        });
    };
};

export function loadmore(lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const getLoadmore = functions.httpsCallable('locationBackendLoadmore');
        try {
            const result = await getLoadmore(lastVisibleUid);
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchLoadmoreDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function queryByIndex(startIndex, endIndex) {
    return async (dispatch) => {
        dispatch(fetch());
        if (endIndex <= startIndex) {
            dispatch(showSnack("fail", "End index is less than start index."));
            dispatch(fetchFail("endIndex < startIndex"));
        } else {
            const getQuery = functions.httpsCallable('locationBackendQuery');
            try {
                const result = await getQuery({ startIndex, endIndex });
                const output = {
                    numberOfDocs: result.data.numberOfDocs,
                    list: result.data.list,
                }
                dispatch(updateIndex(startIndex, endIndex));
                dispatch(fetchIndexDone(output));
            }
            catch (error) {
                console.log(error);
                dispatch(fetchFail(error.message));
            }
        }
    };
}