import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography
} from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  progress: {
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 2,
  },
}));

const Email = props => {
  const { 
    uid, 
    email, 
    password, 
    loading, 
    touched,
    errors,
    updateEmail,
    isValid,
    postUpdateEmail,
    successEmail,
    failEmail,
  } = props;

  const classes = useStyles();

  const handleChange = event => {
    const data = {
        uid,
        email,
        password,
    }
    updateEmail(data, event.target.name, event.target.value);
  };

  const hasError = field => {
    if (_.has(errors, field) && _.has(touched, field)) {
      return touched[field] && errors[field] ? true : false;
    }
    return false;
  };

  const handleSubmit = () => {
        const data = {
          uid,
          email,
          password,
        }
        postUpdateEmail(data);
    };

  return (
    <Card className={classes.root} >
      <form>
        <CardHeader
          subheader="Change email"
          title="Email"
        />
        <Divider />
        <CardContent style={{position: 'relative'}}>
          {loading && <CircularProgress className={classes.progress} />}
          <TextField
            fullWidth
            label="Email"
            name="email"
            onChange={handleChange}
            value={email}
            variant="outlined"
            error={hasError('email')}
            helperText={
                hasError('email') ? errors.email[0] : null
            }
            disabled={loading}
            autoComplete="new-password"
          />
          <TextField
            fullWidth
            label="Current password"
            name="password"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={password}
            variant="outlined"
            error={hasError('password')}
            helperText={
              hasError('password') ? errors.password[0] : null
            }
            disabled={loading}
            autoComplete="new-password"
          />
          {
            failEmail !== '' &&
            <Box mt={2}>
              <Typography
                style={{ color: "#de0032" }}
                variant="caption"
              >
                {failEmail}
              </Typography>
            </Box>
          }
          {
            successEmail !== '' &&
            <Box mt={2}>
              <Typography
                color="primary"
                variant="caption"
              >
                {successEmail}
              </Typography>
            </Box>
          }
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16 }}>
          <Button
            color="primary"
            variant="outlined"
            disabled={!isValid || loading}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default Email;
