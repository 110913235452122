import { connect } from 'react-redux';
import {
    changeLang,
    changeCategory,
    changeTag,
    removeTag,
    changeLocation,
    update,
    postCreate,
    changeCover,
    postImage,
    getCreateData,
} from 'actions/articles';

import ArticleCreate from 'views/Article/Create';

const mapStateToProps = state => ({
    loading: state.articles.loading,
    lang: state.articles.lang,
    tagList: state.articles.tagList,
    locationList: state.articles.locationList,
    data: state.articles.data,
    errors: state.articles.errors,
    isValid: state.articles.isValid,
    successMessage: state.articles.successMessage,
    articleStatus: state.articles.articleStatus,
});

const mapDispatchToProps = {
    changeLang,
    changeCategory,
    changeTag,
    removeTag,
    changeLocation,
    update,
    postCreate,
    changeCover,
    postImage,
    getCreateData,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ArticleCreate);

