import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';
import 'firebase/functions';

// const BUILD_LEVEL = 'staging';
const BUILD_LEVEL = 'production';

// Initialize Firebase
let firebaseConfig = {};
if (BUILD_LEVEL !== 'production'){
    firebaseConfig = {
        apiKey: "AIzaSyDK3ChDZD5A4FHREoPYkvYmPBE2OLbCFb4",
        authDomain: "all-about-living-staging-10b66.firebaseapp.com",
        databaseURL: "https://all-about-living-staging-10b66.firebaseio.com",
        projectId: "all-about-living-staging-10b66",
        storageBucket: "all-about-living-staging-10b66.appspot.com",
        messagingSenderId: "1091602412005",
        appId: "1:1091602412005:web:1049614dc9fcfd01"
    };
}else{
    firebaseConfig = {
        apiKey: "AIzaSyAMQH8Wm8oMY-r1C355zV-QS_3UaQKb7DI",
        authDomain: "all-about-living-prod.firebaseapp.com",
        databaseURL: "https://all-about-living-prod.firebaseio.com",
        projectId: "all-about-living-prod",
        storageBucket: "all-about-living-prod.appspot.com",
        messagingSenderId: "598820359223",
        appId: "1:598820359223:web:167bc6aeedf839ce"
    };
}

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default firebase;