import React, { forwardRef } from 'react';
import { NavLink as RouterLink, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Card,
    CardHeader,
    CardActions,
    Divider,
    Button,
    TextField,
    Typography,
    Box,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import TabLang from 'components/TabLang';
import ImageField from 'components/ImageField';
import GalleryField from 'components/GalleryField';
import TypeField from 'components/ProjectTypeField';
import DeveloperField from 'components/DeveloperSearch';
import Map from 'components/Map';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            marginRight: theme.spacing(2),
        },
    },
}));

const AccountDetails = props => {
    const classes = useStyles();

    const {
        uid,
        title,
        loading,
        lang,
        data,
        isValid,
        successMessage,
        update,
        updateNoLang,
        postCreate,
        postImage,
        postGallery,
        removeGallery,
        changeLang,
        changeType,
        changeDev,
        changeImage,
        changeGeo,
    } = props;

    // Typing
    const handleChange = event => {
        update(data, lang, event.target.name, event.target.value);
    };
    const handleNoLangChange = event => {
        updateNoLang(data, event.target.name, event.target.value);
    };

    const handleSave = () => {
        if (_.isEmpty(uid)) {
            postCreate(data);
        } else {
            postCreate(uid, data);
        }
    };

    if (!_.isEmpty(successMessage)) {
        return <Redirect to="/projects" />;
    }

    return (
        <Card>
            <CardHeader title={title} />
            <Divider />
            <Box p={2}>
                <TypeField
                    loading={loading}
                    value={data.typeId}
                    oldData={data}
                    changeValue={changeType}
                />
                <DeveloperField
                    loading={loading}
                    oldData={data}
                    update={dev => changeDev(dev, data)}
                    selectedValue={data.devId}
                />
            </Box>
            <Box p={2} className={classes.root}>
                <TextField
                    label="Completed years"
                    name="yearDone"
                    onChange={handleNoLangChange}
                    type="number"
                    value={data.yearDone}
                    variant="outlined"
                    disabled={loading}
                />
                <TextField
                    label="Number of units"
                    name="unitNumber"
                    onChange={handleNoLangChange}
                    type="number"
                    value={data.unitNumber}
                    variant="outlined"
                    disabled={loading}
                />
            </Box>
            <ImageField
                title={'Logo*'}
                width={300}
                height={300}
                loading={loading}
                url={data.img}
                uploadFunc={file => postImage(file, data)}
                oldData={data}
                fieldName="img"
                removeFunc={() => changeImage('', data)}
            />
            <Box p={2}>
                <Box mb={2}>
                    <Typography variant="body1">Choose Location* :</Typography>
                </Box>
                <Map
                    loading={loading}
                    category="live"
                    lat={data.location.lat}
                    lng={data.location.lng}
                    changeGeo={(lat, lng) => changeGeo(lat, lng, data)}
                />
            </Box>
            <Box p={2}>
                <Box mb={2}>
                    <Typography variant="body1">
                        Gallery (Maximum of 10 images) :
                    </Typography>
                </Box>
                <GalleryField
                    list={data.gallery}
                    oldData={data}
                    postGallery={postGallery}
                    removeGallery={removeGallery}
                />
            </Box>
            <Divider />
            <TabLang
                lang={lang}
                changeLang={changeLang}
                thActive={!_.isEmpty(data.title.th) || !_.isEmpty(data.desc.th)}
                enActive={!_.isEmpty(data.title.en) || !_.isEmpty(data.desc.en)}
                chActive={!_.isEmpty(data.title.ch) || !_.isEmpty(data.desc.ch)}
                jpActive={!_.isEmpty(data.title.jp) || !_.isEmpty(data.desc.jp)}
                thNode={
                    <>
                        <Box mb={2}>
                            <TextField
                                key={`th-title-${lang === 'th'}`}
                                fullWidth
                                label="Title"
                                name="title"
                                onChange={handleChange}
                                required
                                value={data.title.th}
                                variant="outlined"
                                disabled={loading}
                            />
                        </Box>
                        <TextField
                            key={`th-desc-${lang === 'th'}`}
                            fullWidth
                            label="Description"
                            name="desc"
                            onChange={handleChange}
                            required
                            multiline
                            value={data.desc.th}
                            variant="outlined"
                            disabled={loading}
                        />
                    </>
                }
                enNode={
                    <>
                        <Box mb={2}>
                            <TextField
                                key={`en-name-${lang === 'en'}`}
                                fullWidth
                                label="Title"
                                name="title"
                                onChange={handleChange}
                                value={data.title.en}
                                variant="outlined"
                                disabled={loading}
                            />
                        </Box>
                        <TextField
                            key={`en-desc-${lang === 'en'}`}
                            fullWidth
                            label="Description"
                            name="desc"
                            onChange={handleChange}
                            multiline
                            value={data.desc.en}
                            variant="outlined"
                            disabled={loading}
                        />
                    </>
                }
                chNode={
                    <>
                        <Box mb={2}>
                            <TextField
                                key={`ch-name-${lang === 'ch'}`}
                                fullWidth
                                label="Title"
                                name="title"
                                onChange={handleChange}
                                value={data.title.ch}
                                variant="outlined"
                                disabled={loading}
                            />
                        </Box>
                        <TextField
                            key={`ch-desc-${lang === 'ch'}`}
                            fullWidth
                            label="Description"
                            name="desc"
                            onChange={handleChange}
                            multiline
                            value={data.desc.ch}
                            variant="outlined"
                            disabled={loading}
                        />
                    </>
                }
                jpNode={
                    <>
                        <Box mb={2}>
                            <TextField
                                key={`jp-name-${lang === 'jp'}`}
                                fullWidth
                                label="Title"
                                name="title"
                                onChange={handleChange}
                                value={data.title.jp}
                                variant="outlined"
                                disabled={loading}
                            />
                        </Box>
                        <TextField
                            key={`jp-desc-${lang === 'jp'}`}
                            fullWidth
                            label="Description"
                            name="desc"
                            onChange={handleChange}
                            multiline
                            value={data.desc.jp}
                            variant="outlined"
                            disabled={loading}
                        />
                    </>
                }
            />
            <Divider />
            <CardActions style={{ padding: 16 }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSave()}
                    disabled={!isValid || loading}>
                    Save
                </Button>
                <Button
                    color="default"
                    variant="outlined"
                    component={CustomRouterLink}
                    to="/projects">
                    Cancel
                </Button>
            </CardActions>
        </Card>
    );
};

AccountDetails.propTypes = {
    title: PropTypes.string.isRequired,
};

export default AccountDetails;
