import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    chip: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        }
    }
});

function Badge(props) {
    const { classes ,color, number} = props;

    let badgeColor = (color === 'filter') ? 'xxx' : 'secondary';
    if (badgeColor === 'xxx') {
        badgeColor = number !== 0 ? 'primary' : 'default';
    }

    return (
        <Chip 
            className={classes.chip}
            color={badgeColor}
            size="small" 
            label={number}
        />
    );
}

Badge.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
    number: PropTypes.number.isRequired,
};

Badge.defaultProps = {
    color: 'default',
};

export default withStyles(styles)(Badge);