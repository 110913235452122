import { connect } from 'react-redux';
import {
    getList,
    postDelete,
    resetData,
    querySearch,
    loadmore,
    showless,
    queryByIndex,
} from 'actions/project';
import ProjectList from 'views/Project/List';

const mapStateToProps = state => ({
    loading: state.project.loading,
    list: state.project.list,
    isSearching: state.project.isSearching,
    loadingMore: state.project.loadingMore,
    showLoadingMore: state.project.showLoadingMore,
    startIndex: state.project.startIndex,
    endIndex: state.project.endIndex,
    numberOfDocs: state.project.numberOfDocs,
});

const mapDispatchToProps = {
    getList,
    postDelete,
    resetData,
    querySearch,
    loadmore,
    showless,
    queryByIndex,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectList);