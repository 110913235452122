import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Details } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
}));

const Create = props => {
    const classes = useStyles();
    const {
        loading,
        lang,
        data,
        isValid,
        successMessage,
        changeLang,
        changeType,
        changeDev,
        changeLogo,
        changeGeo,
        update,
        updateNoLang,
        postCreate,
        postImage,
        postGallery,
        removeGallery,
    } = props;

    // React.useEffect(() => {
    //     getCreateData();
    // }, [getCreateData]);

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Details
                        title="Create Project"
                        loading={loading}
                        lang={lang}
                        data={data}
                        isValid={isValid}
                        successMessage={successMessage}
                        update={update}
                        updateNoLang={updateNoLang}
                        postCreate={postCreate}
                        postImage={postImage}
                        postGallery={postGallery}
                        removeGallery={removeGallery}
                        changeImage={changeLogo}
                        changeLang={changeLang}
                        changeType={changeType}
                        changeDev={changeDev}
                        changeGeo={changeGeo}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default Create;
