import { connect } from 'react-redux';
import { getUserList, toggleStatus, postDeleteUser } from 'actions/userList';
import { resetUserCreate } from 'actions/userCreate';
import UserList from 'views/User/List';

const mapStateToProps = state => ({
    loading: state.userList.loading,
    userList: state.userList.userList,
    message: state.userList.message,
});

const mapDispatchToProps = {
    getUserList,
    resetUserCreate,
    toggleStatus,
    postDeleteUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserList);

