import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
    tag: {
        padding: '5px 10px',
        marginBottom: 8,
    }
});

function Tag(props) {
    const { 
        text, 
        classes, 
        onClick,
        url, 
    } = props;

    return (
        <>
            {
                url ? 
                    <Link to={url} style={{ textDecoration: 'none' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.tag}
                        >
                            <Typography variant="body2">
                                {text}
                            </Typography>
                        </Button>
                    </Link>
                    :
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.tag}
                        onClick={onClick}
                    >
                        <Typography variant="body2">
                            {text}
                        </Typography>
                    </Button>
            }
        </>
    );
}

Tag.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    url: PropTypes.string,
};

Tag.defaultProps = {
    onClick(){ console.log('Tag Click'); },
    url: null,
};

export default withStyles(styles)(Tag);