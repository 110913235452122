import React from 'react';
import Fab from '@material-ui/core/Fab';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Badge } from '../element';

const styles = () => ({
    fab: {
        height: 52,
        textAlign: 'left',
        backgroundColor: '#fff',
        '&:hover':{
            backgroundColor: '#e4e4e4',
        }
    },
    desc:{
        marginRight: 12,
    }
});

function MyButton(props) {
    const { children, classes, onClick, number } = props;
    return (
        <Fab variant="extended" color="default" className={classes.fab} onClick={onClick}>
            <Typography variant="button" className={classes.desc}>{children}</Typography>
            <Badge number={number} color="filter" />
        </Fab>
    );
}

MyButton.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    number: PropTypes.number.isRequired,
    onClick: PropTypes.func,
};

MyButton.defaultProps = {
    onClick() { console.log('Button Click'); }
};

export default withStyles(styles)(MyButton);