import { connect } from 'react-redux';
import {
    getList,
    postDelete,
    postClose,
    postBringBack,
    postPackage,
    resetData,
    updateTime,
} from 'actions/property';
import ProjectList from 'views/Property/List';

const mapStateToProps = state => ({
    loading: state.property.loading,
    list: state.property.list,
    loadingMore: state.property.loadingMore,
    packages: state.property.packages,
    start: state.property.start,
    end: state.property.end,
});

const mapDispatchToProps = {
    getList,
    postDelete,
    postClose,
    postBringBack,
    postPackage,
    resetData,
    updateTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
