import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import BalloonBlockEditor from '@ckeditor/ckeditor5-build-balloon-block'; 
import { withStyles } from '@material-ui/styles';
import { 
    Typography,
    Box,    
} from '@material-ui/core';
import clsx from 'clsx';
import MyEditorConfiguration from './uploadAdapter';

const styles = (theme) => ({
    editorContainer: {
        position: 'relative',
        color: '#2e2e2e',
        '& .ck.ck-editor__editable.ck-focused': {
            boxShadow: 'none',
        },
        '& .ck.ck-editor__editable': {
            borderRadius: '4px !important',
            padding: 16,
            border: '1px solid #bfbfbf',
            '& figure': {
                margin: '1rem 0',
                textAlign: 'center',
                display: 'block',
                [theme.breakpoints.down('sm')]: {
                    margin: '0.5rem 0',
                },
                '&.image-style-side': {
                    maxWidth: '50%',
                    float: 'right',
                    marginLeft: '1rem',
                    [theme.breakpoints.down('sm')]: {
                        maxWidth: '100%',
                        float: 'none',
                        marginLeft: '0',
                    },
                },
                '&.media': {
                    clear: 'both',
                    display: 'block',
                    width: '100%',
                },
                '& figcaption': {
                    backgroundColor: '#fff',
                    fontFamily: 'Sarabun',
                    padding:0,
                    color: '#c7c7c7',
                    marginTop: '0.5rem',
                    fontSize: "0.875rem",
                    fontWeight: "normal",
                    fontStyle: "normal",
                    fontStretch: "normal",
                    lineHeight: "1.71",
                    letterSpacing: "normal",
                    display: 'block',
                    "@media screen and (max-width: 961px)": {
                        marginTop: '0.25rem',
                        fontSize: "0.75rem",
                        lineHeight: 1.83,
                    }
                }
            },
            '& img': {
                display: 'block',
                width: 'auto',
                height: 'auto',
                maxHeight: 600,
                maxWidth: '100%',
                margin: '0 auto',
            },
            '& h2': {
                margin: 0,
                fontFamily: 'Prompt',
                fontSize: 24,
                fontWeight: 600,
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 1.5,
                letterSpacing: 'normal',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 20,
                    lineHeight: 1.6,
                },
            },
            '& h3': {
                margin: 0,
                fontFamily: 'Prompt',
                fontSize: 22,
                fontWeight: 600,
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 1.55,
                letterSpacing: 'normal',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 18,
                    lineHeight: 1.67,
                },
            },
            '& h4': {
                margin: 0,
                fontFamily: 'Prompt',
                fontSize: 18,
                fontWeight: 600,
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 1.67,
                letterSpacing: 'normal',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                    lineHeight: 1.75,
                },
            },
            '& p': {
                margin: 0,
                fontFamily: 'Sarabun',
                fontSize: 18,
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 1.67,
                letterSpacing: 'normal',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                    lineHeight: 1.75,
                },
            },
            '& ul': {
                paddingLeft: 40,
                [theme.breakpoints.down('sm')]: {
                    paddingLeft: 20,
                },
            },
            '& ol': {
                paddingLeft: 40,
                [theme.breakpoints.down('sm')]: {
                    paddingLeft: 20,
                },
            },
            '& li': {
                margin: 0,
                fontFamily: 'Sarabun',
                fontSize: 18,
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 1.67,
                letterSpacing: 'normal',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                    lineHeight: 1.75,
                },
            },
            '& a': {
                color: '#00abbd',
            },
            '& blockquote': {
                margin: 0,
                borderLeft: '4px solid #00abbd',
                paddingLeft: 12,
                fontFamily: 'Prompt',
                fontSize: 18,
                fontWeight: 500,
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 1.56,
                letterSpacing: 'normal',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                    lineHeight: 1.75,
                },
            },
        },
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.3,
        background: '#CCC',
    }
});

function MyEditor(props){
    const {
        classes,
        disabled,
        lang,
        oldData,
        name,
        value,
        onChange,
    } = props;

    const editorConfiguration = {
        extraPlugins: [
            MyEditorConfiguration
        ],
        language: lang,
        mediaEmbed:{
            previewsInData:true
        }
    };
    
    return (
        <>
            <Box pl={2} pb={1}>
                <Typography variant="caption">Description*</Typography>
            </Box>
            <div id="editor" className={clsx(classes.editorContainer, disabled ? classes.disabled : '')} >
                <CKEditor
                    editor={BalloonBlockEditor}
                    config={editorConfiguration}
                    data={value}
                    disabled={disabled}
                    onChange={(event, editor) => {
                        const newData = editor.getData();
                        // console.log({ event, editor, newData });
                        onChange(oldData, lang, name, newData);
                    }}
                    // onInit={editor => {
                    //     // You can store the "editor" and use when it is needed.
                    //     console.log('Editor is ready to use!', editor);
                    // }}
                    // onBlur={(event, editor) => {
                    //     console.log('Blur.', editor);
                    // }}
                    // onFocus={(event, editor) => {
                    //     console.log('Focus.', editor);
                    // }}
                />
            </div>
        </>
    );
}

export default withStyles(styles)(MyEditor);