import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { 
  Paper, 
  Input,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    color: theme.palette.text.secondary
  },
  iconButton: {
    padding: 10,
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

const SearchInput = props => {
  const { 
    className, 
    onChange, 
    style,
    reset,
    disabled,
    ...rest 
  } = props;

  const classes = useStyles();

  return (
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
    >
      <IconButton
        className={classes.iconButton}
      >
        <SearchIcon className={classes.icon} />
      </IconButton>
      <Input
        {...rest}
        className={classes.input}
        disableUnderline
        onChange={onChange}
      />
      <IconButton
        className={classes.iconButton}
        disabled={disabled}
        onClick={reset}
      >
        <CloseIcon className={classes.icon} />
      </IconButton>
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;
