import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Details } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Create = (props) => {
    const classes = useStyles();
    const {
        loading,
        lang,
        data,
        isValid,
        successMessage,
        update,
        postCreate,
        changeTag,
        removeTag,
    } = props;

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid item xs={12}>
                    <Details 
                        title="Create Topic" 
                        loading={loading}
                        lang={lang}
                        data={data}
                        isValid={isValid}
                        successMessage={successMessage}
                        update={update}
                        postCreate={postCreate}
                        changeTag={changeTag}
                        removeTag={removeTag}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default Create;
