import moment from 'moment';
import {
    SET_PROPERTY_TYPE,
    SET_PROPERTY_PROJECT,
    SET_PROPERTY_GEO,
    UPLOAD_PROPERTY_GALLERY,
    UPLOAD_PROPERTY_GALLERY_DONE,
    REMOVE_PROPERTY_GALLERY,
    UPDATE_PROPERTY,
    FETCH_PROPERTY,
    FETCH_PROPERTY_DATA_DONE,
    FETCH_PROPERTY_DONE,
    FETCH_PROPERTY_FAIL,
    RESET_PROPERTY,
    FETCH_PROPERTY_LIST_DONE,
    DELETE_PROPERTY,
    FETCH_PROPERTY_LOADMORE,
    FETCH_PROPERTY_LOADMORE_DONE,
    FETCH_PROPERTY_SHOWLESS,
    UPDATE_PROPERTY_INDEX,
    UPDATE_PROPERTY_TIME,
} from 'actions/property';

const initialState = {
    loading: false,
    start: moment().subtract(1, 'months').valueOf(),
    end: moment().valueOf(),
    list: [],
    packages: [],
    loadingMore: false,
    showLoadingMore: true,
    startIndex: 1,
    endIndex: 25,
    data: {
        type: '',
        projectId: '',
        location: {
            lat: 13.7563,
            lng: 100.5018,
        },
        title: '',
        desc: '',
        purpose: '',
        sellPrice: '',
        rentPrice: '',
        landSize: '',
        areaSize: '',
        bedroomNum: '',
        toiletNum: '',
        floor: '',
        furniture: '',
        direction: '',
        projectStatus: '',
        gallery: [],
        status: 'open',
        packageId: '',
        startPromoteAt: 0,
        reachAfterPromote: 0,
        maximumReachPromote: 0,
        createdBy: 'admin',
        createdAt: '',
        updatedAt: '',
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_PROPERTY:
            return {
                ...initialState,
                data: {
                    type: '',
                    projectId: '',
                    location: {
                        lat: 13.7563,
                        lng: 100.5018,
                    },
                    title: '',
                    desc: '',
                    purpose: '',
                    sellPrice: '',
                    rentPrice: '',
                    landSize: '',
                    areaSize: '',
                    bedroomNum: '',
                    toiletNum: '',
                    floor: '',
                    furniture: '',
                    direction: '',
                    projectStatus: '',
                    gallery: [],
                    status: 'open',
                    startPromoteAt: 0,
                    reachAfterPromote: 0,
                    maximumReachPromote: 0,
                    createdBy: 'admin',
                    createdAt: '',
                    updatedAt: '',
                },
            };
        case UPDATE_PROPERTY_TIME:
            return {
                ...state,
                [action.field]: action.value,
            };
        case SET_PROPERTY_TYPE:
            return {
                ...state,
                data: {
                    ...state.data,
                    type: action.typeId,
                    projectId: action.typeId === 'project' ? '' : 'location',
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case SET_PROPERTY_PROJECT:
            return {
                ...state,
                data: {
                    ...state.data,
                    projectId: action.proId,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case SET_PROPERTY_GEO:
            return {
                ...state,
                data: {
                    ...state.data,
                    location: {
                        lat: action.lat,
                        lng: action.lng,
                    },
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case UPDATE_PROPERTY:
            if (
                action.fieldName === 'sellPrice' &&
                state.data.purpose === 'sell'
            ) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        rentPrice: 'xxx',
                        [action.fieldName]: action.newValue,
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            }
            if (
                action.fieldName === 'rentPrice' &&
                state.data.purpose === 'rent'
            ) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        sellPrice: 'xxx',
                        [action.fieldName]: action.newValue,
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: action.newValue,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case FETCH_PROPERTY:
            return {
                ...state,
                loading: true,
                loadingMore: false,
                showLoadingMore: true,
            };
        case FETCH_PROPERTY_LOADMORE:
            return {
                ...state,
                loadingMore: true,
            };
        case FETCH_PROPERTY_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_PROPERTY_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_PROPERTY_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        case FETCH_PROPERTY_LIST_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                showLoadingMore: action.list.length < action.numberOfDocs,
                startIndex: 1,
                endIndex: action.list.length,
                packages: action.packages,
            };
        case FETCH_PROPERTY_LOADMORE_DONE:
            return {
                ...state,
                loadingMore: false,
                endIndex: state.endIndex + action.list.length,
                showLoadingMore:
                    state.endIndex + action.list.length < state.numberOfDocs,
                list: [...state.list, ...action.list],
                packages: action.packages,
            };
        case FETCH_PROPERTY_SHOWLESS:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: true,
                list: [...state.list.slice(0, state.list.length - 25)],
                endIndex: state.endIndex - 25,
            };
        case UPDATE_PROPERTY_INDEX:
            return {
                ...state,
                startIndex: action.startIndex,
            };
        case DELETE_PROPERTY:
            const index = state.list.findIndex(data => data.uid === action.uid);
            let newList = [...state.list];
            newList.splice(index, 1);
            return {
                ...state,
                list: newList,
            };
        case UPLOAD_PROPERTY_GALLERY:
            let galList = [...state.data.gallery];
            galList.push('loading');
            return {
                ...state,
                data: {
                    ...state.data,
                    gallery: galList,
                },
            };
        case UPLOAD_PROPERTY_GALLERY_DONE:
            let galList2 = [...state.data.gallery];
            galList2[action.index] = action.imgURL;
            return {
                ...state,
                data: {
                    ...state.data,
                    gallery: galList2,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case REMOVE_PROPERTY_GALLERY:
            let galList3 = [...state.data.gallery];
            galList3.splice(action.index, 1);
            return {
                ...state,
                data: {
                    ...state.data,
                    gallery: galList3,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        default:
            return state;
    }
};
export default projectsReducer;
