export default {
    type: [
        {
            uid: 'empty',
            name: 'ไม่มี',
        },
        {
            uid: 'haveSome',
            name: 'มีบางส่วน',
        },
        {
            uid: 'fullyFurnished',
            name: 'ตกแต่งครบ',
        },
    ],
};