import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
} from 'react-instantsearch-dom';
import { Toolbar, Table } from './components';
const searchClient = algoliasearch('SJS3S5FAVR', 'fbf789ef62160863f04f7f39da3e7fe7');

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
}));

const UserList = (props) => {
    const classes = useStyles();
    const {
        loading,
        list,
        isSearching,
        getList,
        postDelete,
        resetData,
        querySearch,
        loadingMore,
        showLoadingMore,
        startIndex,
        endIndex,
        numberOfDocs,
        loadmore,
        showless,
        queryByIndex,
        toggleStatus,
        location,
    } = props;

    const lastVisibleUid = list[list.length - 1] ? list[list.length - 1].uid : '';

    let categoryId = location.pathname.split('/').slice(-1)[0];
    categoryId = categoryId === 'publish' ? 'all' : categoryId;

    const categoryListId = [
        {
            title: 'All',
            href: '/articles/publish',
            value: 'all',
        },
        {
            title: 'Finance',
            href: '/articles/publish/finance',
            value: 'finance',
        },
        {
            title: 'Inspiration',
            href: '/articles/publish/inspiration',
            value: 'inspiration',
        },
        {
            title: 'Lifestyle',
            href: '/articles/publish/lifestyle',
            value: 'lifestyle',
        },
        {
            title: 'Live',
            href: '/articles/publish/live',
            value: 'live',
        },
    ];

    React.useEffect(() => {
        getList('publish', categoryId === 'all' ? '' : categoryId);
    }, [getList, categoryId]);

    return (
        <div className={classes.root}>
            <InstantSearch searchClient={searchClient} indexName="articles">
                <Toolbar 
                    tabActive="publish" 
                    changeSearch={querySearch}
                    resetData={resetData}
                    categoryId={categoryId}
                    categoryListId={categoryListId}
                />
                <div className={classes.content}>
                    <Table 
                        isSearching={isSearching} 
                        list={list}
                        resetData={resetData}
                        loading={loading}
                        postDelete={postDelete}
                        toggleStatus={toggleStatus}
                        loadingMore={loadingMore}
                        showLoadingMore={showLoadingMore}
                        startIndex={startIndex}
                        endIndex={endIndex}
                        numberOfDocs={numberOfDocs}
                        loadmoreFunc={() => loadmore('publish', categoryId, lastVisibleUid)}
                        showlessFunc={showless}
                        queryFunc={(start, end) => queryByIndex('publish', categoryId, start, end)}
                    />
                </div>
            </InstantSearch>
        </div>
    );
};

export default withRouter(UserList);
