import validate from 'validate.js';
import moment from 'moment';
import firebase from 'common/firebase';
import _ from 'lodash';
import stripHtml from 'string-strip-html';
import { showSnack } from './main';

const functions = firebase.app().functions('asia-east2');

const schema = {
    title: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    desc: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
// -- CREATE/UPDATE
export const SET_TOPIC_TAG = 'SET_TOPIC_TAG';
export const REMOVE_TOPIC_TAG = 'REMOVE_TOPIC_TAG';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const FETCH_TOPIC = 'FETCH_TOPIC';
export const FETCH_TOPIC_DATA_DONE = 'FETCH_TOPIC_DATA_DONE';
export const FETCH_TOPIC_DONE = 'FETCH_TOPIC_DONE';
export const FETCH_TOPIC_FAIL = 'FETCH_TOPIC_FAIL';
export const RESET_TOPIC = 'RESET_TOPIC';
export const UPDATE_TOPIC_SEARCH = 'UPDATE_TOPIC_SEARCH';

// -- LIST
export const FETCH_TOPIC_LIST_DONE = 'FETCH_TOPIC_LIST_DONE';
export const CHANGE_TOPIC_PIN = 'CHANGE_TOPIC_PIN';
export const CHANGE_TOPIC_HIDE = 'CHANGE_TOPIC_HIDE';
export const DELETE_TOPIC = 'DELETE_TOPIC';
export const FETCH_TOPIC_LOADMORE = 'FETCH_TOPIC_LOADMORE';
export const FETCH_TOPIC_LOADMORE_DONE = 'FETCH_TOPIC_LOADMORE_DONE';
export const FETCH_TOPIC_SHOWLESS = 'FETCH_TOPIC_SHOWLESS';
export const UPDATE_TOPIC_INDEX = 'UPDATE_TOPIC_INDEX';
export const FETCH_TOPIC_INDEX_DONE = 'FETCH_TOPIC_INDEX_DONE';

// Sync Action Creator
// -- Create/Update --
export function changeTag(tags, oldData) {
    const validateData = {
        title: oldData.title,
        desc: oldData.desc,
    };
    const errors = validate(validateData, schema);
    return {
        type: SET_TOPIC_TAG,
        tags,
        errors,
    };
};
export function removeTag(index, oldData) {
    const validateData = {
        title: oldData.title,
        desc: oldData.desc,
    };
    const errors = validate(validateData, schema);
    return {
        type: REMOVE_TOPIC_TAG,
        index,
        errors,
    };
};
export function querySearch(newValue) {
    return {
        type: UPDATE_TOPIC_SEARCH,
        newValue,
    };
};
export function resetData() {
    return {
        type: RESET_TOPIC,
    };
};
export function fetch() {
    return {
        type: FETCH_TOPIC,
    };
};
export function fetchDataDone(data) {
    return {
        type: FETCH_TOPIC_DATA_DONE,
        data,
    };
};
export function fetchDone(message) {
    return {
        type: FETCH_TOPIC_DONE,
        message,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_TOPIC_FAIL,
        message,
    };
};
export function update(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;

    const validateData = {
        title: oldData.title,
        desc: oldData.desc,
        tagId: oldData.tagId,
    }
    const errors = validate(validateData, schema);

    return {
        type: UPDATE_TOPIC,
        fieldName,
        newValue,
        errors,
    };
};
export function changePin(uid, newStatus) {
    return {
        type: CHANGE_TOPIC_PIN,
        uid,
        newStatus,
    };
};
export function changeHide(uid, newStatus) {
    return {
        type: CHANGE_TOPIC_HIDE,
        uid,
        newStatus,
    };
};

// -- LIST --
export function fetchListDone(data) {
    return {
        type: FETCH_TOPIC_LIST_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function fetchIndexDone(data) {
    return {
        type: FETCH_TOPIC_INDEX_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function deleteData(uid) {
    return {
        type: DELETE_TOPIC,
        uid,
    };
};
export function fetchLoadmore() {
    return {
        type: FETCH_TOPIC_LOADMORE,
    };
};
export function showless() {
    return {
        type: FETCH_TOPIC_SHOWLESS,
    };
};
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_TOPIC_LOADMORE_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function updateIndex(startIndex, endIndex) {
    return {
        type: UPDATE_TOPIC_INDEX,
        startIndex,
        endIndex,
    };
};

function slugify(text) {
    const uri = text.toString().toLowerCase().trim()
        // eslint-disable-next-line no-useless-escape
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
        .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single _
        .replace(/^-+|-+$/g, ''); // remove leading, trailing -

    return encodeURIComponent(uri);
}

// Async Action Creator
export function getList() {
    return async function (dispatch) {
        dispatch(fetch());

        const getTopicList = functions.httpsCallable('webboardBackendGetList');
        try {
            const result = await getTopicList();
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchListDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
export function postCreate(data) {
    return (dispatch) => {
        dispatch(fetch());
        const creator = firebase.auth().currentUser;
        if (creator) {
            const shortDesc = _.truncate(stripHtml(data.desc), { 'length': 120, 'separator': ' ' });
            const urlString = slugify(data.title);

            const inputData = {
                url: urlString,
                title:data.title,
                desc: data.desc,
                accept: true,
                shortDesc,
                tagId: _.isEmpty(data.tagId) ? [] : _.map(data.tagId, 'uid'),
                isHide: false,
                isPin: false,
                reachNumber: 0,
                commentNumber: 0,
                createdBy: creator.uid,
                isCreatedByAdmin: true,
                timestamp: moment().valueOf(),
                updatedAt: moment().valueOf(),
            };

            const ref = firebase.firestore().collection("topics");
            return ref.add(inputData).then(() => {
                dispatch(fetchDone('Topic has already been created successfully.'));
                dispatch(showSnack('success', 'Topic has already been created successfully.'));
            }).catch((error) => {
                dispatch(showSnack('fail', error.message));
            });
        }
        dispatch(fetchFail("You are not sign in."));
        return true;
    };
};

export function getEdit(uid) {
    return function (dispatch) {
        dispatch(fetch());
        const getData = functions.httpsCallable('topicGetCreateData');
        return getData(uid).then((result) => {
            dispatch(fetchDataDone(result.data));
        }).catch((error) => {
            console.log("Error getting document:", error);
            dispatch(fetchFail(error.message));
        });
    };
}

export function postEdit(uid, data) {
    return (dispatch) => {
        dispatch(fetch());
        const creator = firebase.auth().currentUser;
        if (creator) {
            const shortDesc = _.truncate(stripHtml(data.desc), { 'length': 120, 'separator': ' ' });
            const urlString = slugify(data.title);

            const inputData = {
                title: data.title,
                desc: data.desc,
                shortDesc,
                url: urlString,
                tagId: _.isEmpty(data.tagId) ? [] : _.map(data.tagId, 'uid'),
                updatedAt: moment().valueOf(),
            };

            const ref = firebase.firestore().collection("topics");
            return ref.doc(uid).set(inputData, { merge: true }).then(() => {
                dispatch(fetchDone('Topic has already been updated successfully.'));
                dispatch(showSnack('success', 'Topic has already been updated successfully.'));
            }).catch((error) => {
                dispatch(showSnack('fail', error.message));
            });
        }
        dispatch(fetchFail("You are not sign in."));
        return true;
    };
}

export function postDelete(uid) {
    return function (dispatch) {
        const docRef = firebase.firestore().collection("topics").doc(uid);
        return docRef.delete().then(function () {
            dispatch(deleteData(uid));
            dispatch(showSnack('success', 'Delete topics successfully.'));
        }).catch(function (error) {
            console.error("Error removing document: ", error.message);
        });
    };
};

export function togglePin(uid, newStatus) {
    return function (dispatch) {
        dispatch(changePin(uid, newStatus));

        const ref = firebase.firestore().collection("topics").doc(uid);
        return ref.set({ isPin: newStatus }, { merge: true }).then(function () {
            dispatch(showSnack('success', 'Change topic pin successfully.'));
        }).catch(function (error) {
            console.error("Error writing document: ", error);
        });
    };
};

export function toggleHide(uid, newStatus) {
    return function (dispatch) {
        dispatch(changeHide(uid, newStatus));

        const ref = firebase.firestore().collection("topics").doc(uid);
        return ref.set({ isHide: newStatus }, { merge: true }).then(function () {
            dispatch(showSnack('success', 'Change topic hide/publish successfully.'));
        }).catch(function (error) {
            console.error("Error writing document: ", error);
        });
    };
};

export function loadmore(lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const getLoadmore = functions.httpsCallable('webboardBackendLoadmore');
        try {
            const result = await getLoadmore(lastVisibleUid);
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchLoadmoreDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function queryByIndex(startIndex, endIndex) {
    return async (dispatch) => {
        dispatch(fetch());
        if (endIndex <= startIndex) {
            dispatch(showSnack("fail", "End index is less than start index."));
            dispatch(fetchFail("endIndex < startIndex"));
        } else {
            const getQuery = functions.httpsCallable('webboardBackendQuery');
            try {
                const result = await getQuery({ startIndex, endIndex });
                const output = {
                    numberOfDocs: result.data.numberOfDocs,
                    list: result.data.list,
                }
                dispatch(updateIndex(startIndex, endIndex));
                dispatch(fetchIndexDone(output));
            }
            catch (error) {
                console.log(error);
                dispatch(fetchFail(error.message));
            }
        }
    };
}