/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import algoliasearch from 'algoliasearch/lite';
import { connectAutoComplete, InstantSearch } from 'react-instantsearch-dom';
import _ from 'lodash';

const searchClient = algoliasearch(
    'SJS3S5FAVR',
    'fbf789ef62160863f04f7f39da3e7fe7',
);

const MyAutocomplete = ({
    loading,
    oldData,
    update,
    selectedValue,
    hits,
    refine,
}) => {
    const unselected = _(hits)
        .differenceBy(selectedValue, 'uid')
        .map(
            _.partial(_.pick, _, 'uid', 'nameTh', 'nameEn', 'nameCh', 'nameJp'),
        )
        .value();

    return (
        <Autocomplete
            noOptionsText="No suggestions"
            loading={loading}
            options={unselected}
            getOptionLabel={option => (option.nameTh ? option.nameTh : '')}
            filterSelectedOptions
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Choose Project"
                    margin="normal"
                    fullWidth
                    required
                    disabled={loading}
                    onChange={event => {
                        refine(event.currentTarget.value);
                    }}
                />
            )}
            value={selectedValue}
            onChange={(event, value) => {
                update(value, oldData);
            }}
        />
    );
};
const ConnectedAutocomplete = connectAutoComplete(MyAutocomplete);

function DeveloperSearch(props) {
    const { loading, oldData, update, selectedValue } = props;

    return (
        <InstantSearch indexName="projects" searchClient={searchClient}>
            <ConnectedAutocomplete
                loading={loading}
                oldData={oldData}
                update={update}
                selectedValue={selectedValue}
            />
        </InstantSearch>
    );
}

DeveloperSearch.propTypes = {
    loading: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
};

MyAutocomplete.propTypes = {
    loading: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    hits: PropTypes.array.isRequired,
    refine: PropTypes.func.isRequired,
};

export default DeveloperSearch;
