import React from 'react';
import { makeStyles } from '@material-ui/styles';
import algoliasearch from 'algoliasearch/lite';
import { 
    InstantSearch, 
} from 'react-instantsearch-dom';
import { TagToolbar, TagTable } from './components';

const searchClient = algoliasearch('SJS3S5FAVR', 'fbf789ef62160863f04f7f39da3e7fe7');

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
    }
}));

const List = (props) => {
    const classes = useStyles();
    const {
        loading,
        isSearching,
        tagList,
        getTagList,
        postDeleteTag,
        resetTag,
        loadingMore,
        showLoadingMore,
        startIndex,
        endIndex,
        numberOfDocs,
        loadmore,
        showless,
        queryByIndex,
        changeSearch,
    } = props;

    const lastVisibleUid = tagList[tagList.length - 1] ? tagList[tagList.length - 1].uid : '';

    React.useEffect(() => {
        getTagList();
    }, [getTagList]);

    return (
        <div className={classes.root}>
            <InstantSearch searchClient={searchClient} indexName="tags">
                <TagToolbar 
                    resetData={resetTag}
                    changeSearch={changeSearch}
                />
                <div className={classes.content}>
                    <TagTable 
                        isSearching={isSearching}
                        list={tagList} 
                        resetData={resetTag}
                        loading={loading}
                        postDelete={postDeleteTag}
                        loadingMore={loadingMore}
                        showLoadingMore={showLoadingMore}
                        startIndex={startIndex}
                        endIndex={endIndex}
                        numberOfDocs={numberOfDocs}
                        loadmoreFunc={() => loadmore(lastVisibleUid)}
                        showlessFunc={showless}
                        queryFunc={queryByIndex}
                    />
                </div>
            </InstantSearch>
        </div>
    );
};

export default List;
