import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Details } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Settings = (props) => {
    const classes = useStyles();
    const {
        loading,
        lang,
        tagList,
        locationList,
        data,
        isValid,
        successMessage,
        articleStatus,
        changeLang,
        changeCategory,
        changeTag,
        removeTag,
        changeLocation,
        update,
        postEdit,
        changeCover,
        postImage,
        getEdit,
        location,
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];
    React.useEffect(() => {
        getEdit(uid);
    }, [getEdit, uid]);

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid item xs={12}>
                    <Details 
                        title="Edit Article"
                        uid={uid}
                        loading={loading}
                        lang={lang}
                        tagList={tagList}
                        locationList={locationList}
                        data={data}
                        isValid={isValid}
                        successMessage={successMessage}
                        articleStatus={articleStatus}
                        changeLang={changeLang}
                        changeCategory={changeCategory}
                        changeTag={changeTag}
                        removeTag={removeTag}
                        changeLocation={changeLocation}
                        update={update}
                        postCreate={postEdit}
                        changeCover={changeCover}
                        postImage={postImage} 
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(Settings);
