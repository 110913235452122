export default {
    type: [
        {
            uid: 'north',
            name: 'เหนือ',
        },
        {
            uid: 'south',
            name: 'ใต้',
        },
        {
            uid: 'east',
            name: 'ตะวันออก',
        },
        {
            uid: 'west',
            name: 'ตะวันตก',
        },
    ],
};
