import firebase from 'common/firebase';
import _ from 'lodash';

const functions = firebase.app().functions('asia-east2');

// Action
export const UPDATE_ORDER_TIME = 'UPDATE_ORDER_TIME';
export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_ORDER_DONE = 'FETCH_ORDER_DONE';
export const FETCH_ORDER_FAIL = 'FETCH_ORDER_FAIL';
export const FETCH_ORDER_LIST_DONE = 'FETCH_ORDER_LIST_DONE';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_ORDER,
    };
}
export function fetchDone(message) {
    return {
        type: FETCH_ORDER_DONE,
        message,
    };
}
export function fetchFail(message) {
    return {
        type: FETCH_ORDER_FAIL,
        message,
    };
}

// -- LIST --
export function fetchListDone(data) {
    return {
        type: FETCH_ORDER_LIST_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
}
export function updateTime(field, value) {
    return {
        type: UPDATE_ORDER_TIME,
        field,
        value,
    };
}

// Async Action Creator
export function getList(start, end) {
    return async function(dispatch) {
        dispatch(fetch());

        const getOrder = functions.httpsCallable('orderBackendGetList');
        try {
            const result = await getOrder({start, end});
            const output = {
                numberOfDocs: _.get(result, 'data.numberOfDocs', 0),
                list: _.get(result, 'data.list', []),
            };
            dispatch(fetchListDone(output));
        } catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
