import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    Divider,
    Button,
    TextField,
} from '@material-ui/core';
import _ from 'lodash';
import ImageField from 'components/ImageField';
import TabLang from 'components/TabLang';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
        marginRight: 16,
    },
}));

const AccountDetails = (props) => {
    const classes = useStyles();
    const {
        uid,
        lang,
        loading,
        pages,
        isValid,
        updatePage,
        postImage,
        postPageEdit,
        changeLang,
    } = props;

    // Typing
    const handleChange = event => {
        updatePage(pages, lang, event.target.name, event.target.value);
    };

    // Submit Form
    const handleSave = () => {
        postPageEdit(uid,pages);
    };

    return (
        <Card className={classes.root}>
            <ImageField
                title={"Cover*"}
                width={2560}
                height={853}
                loading={loading}
                url={pages.cover}
                uploadFunc={postImage}
                oldData={pages}
                fieldName="cover"
                removeFunc={() => updatePage(pages, '', 'cover', '')}
            />
            <Divider />
            <TabLang
                lang={lang}
                changeLang={changeLang}
                thActive={!_.isEmpty(pages.title.th) || !_.isEmpty(pages.desc.th)}
                thNode={
                    <>
                        <TextField
                            key={`th-title-${lang === 'th'}`}
                            fullWidth
                            label="Title"
                            margin="normal"
                            name="title"
                            onChange={handleChange}
                            required
                            value={pages.title.th}
                            variant="outlined"
                            disabled={loading}
                        />
                        <TextField
                            key={`th-desc-${lang === 'th'}`}
                            fullWidth
                            label="Description"
                            margin="normal"
                            name="desc"
                            onChange={handleChange}
                            required
                            value={pages.desc.th}
                            variant="outlined"
                            disabled={loading}
                        />
                    </>
                }
                
                enActive={!_.isEmpty(pages.title.en) || !_.isEmpty(pages.desc.en)}
                enNode={
                    <>
                        <TextField
                            key={`en-title-${lang === 'en'}`}
                            fullWidth
                            label="Title"
                            margin="normal"
                            name="title"
                            onChange={handleChange}
                            value={pages.title.en}
                            variant="outlined"
                            disabled={loading}
                        />
                        <TextField
                            key={`en-desc-${lang === 'en'}`}
                            fullWidth
                            label="Description"
                            margin="normal"
                            name="desc"
                            onChange={handleChange}
                            value={pages.desc.en}
                            variant="outlined"
                            disabled={loading}
                        />
                    </>
                }

                chActive={!_.isEmpty(pages.title.ch) || !_.isEmpty(pages.desc.ch)}
                chNode={
                    <>
                        <TextField
                            key={`ch-title-${lang === 'ch'}`}
                            fullWidth
                            label="Title"
                            margin="normal"
                            name="title"
                            onChange={handleChange}
                            value={pages.title.ch}
                            variant="outlined"
                            disabled={loading}
                        />
                        <TextField
                            key={`ch-desc-${lang === 'ch'}`}
                            fullWidth
                            label="Description"
                            margin="normal"
                            name="desc"
                            onChange={handleChange}
                            value={pages.desc.ch}
                            variant="outlined"
                            disabled={loading}
                        />
                    </>
                }

                jpActive={!_.isEmpty(pages.title.jp) || !_.isEmpty(pages.desc.jp)}
                jpNode={
                    <>
                        <TextField
                            key={`jp-title-${lang === 'jp'}`}
                            fullWidth
                            label="Title"
                            margin="normal"
                            name="title"
                            onChange={handleChange}
                            value={pages.title.jp}
                            variant="outlined"
                            disabled={loading}
                        />
                        <TextField
                            key={`jp-desc-${lang === 'jp'}`}
                            fullWidth
                            label="Description"
                            margin="normal"
                            name="desc"
                            onChange={handleChange}
                            value={pages.desc.jp}
                            variant="outlined"
                            disabled={loading}
                        />
                    </>
                }
            />
            <Divider />
            <CardActions style={{padding: 16}}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </CardActions>
        </Card>
    );
};

export default AccountDetails;
