import { combineReducers } from 'redux';
import auth from './auth';
import main from './main';
import resetPassword from './resetPassword';
import settings from './settings';
import userList from './userList';
import userCreate from './userCreate';
import userUpdate from './userUpdate';
import ads from './ads';
import pages from './pages';
import term from './term';
import tags from './tags';
import locations from './locations';
import webboard from './webboard';
import articles from './articles';
import developer from './developer';
import property from './property';
import project from './project';
import inbox from './inbox';
import order from './order';

export default combineReducers({
    auth,
    main,
    resetPassword,
    settings,
    userList,
    userCreate,
    userUpdate,
    ads,
    pages,
    tags,
    locations,
    developer,
    project,
    property,
    webboard,
    articles,
    term,
    inbox,
    order,
});
