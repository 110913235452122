import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import _ from 'lodash';
import firebase from 'common/firebase';
import { showSnack } from './main';

const functions = firebase.app().functions('asia-east2');

const schema = {
    cover: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    titleTh:{
        presence: { allowEmpty: false, message: 'is required' },
    },
    descTh: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
export const SET_LANG = 'SET_LANG';
export const RESET_PAGE = 'RESET_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_PAGE_DATA_DONE = 'FETCH_PAGE_DATA_DONE';
export const FETCH_PAGE_DONE = 'FETCH_PAGE_DONE';
export const FETCH_PAGE_FAIL = 'FETCH_PAGE_FAIL';
export const FETCH_PAGE_ARTICLE = 'FETCH_PAGE_ARTICLE';
export const FETCH_PAGE_ARTICLE_DONE = 'FETCH_PAGE_ARTICLE_DONE';
export const SET_PAGE_ARTICLE = 'SET_PAGE_ARTICLE';
export const SAVE_PAGE_ARTICLE = 'SAVE_PAGE_ARTICLE';

// Sync Action Creator
export function changeLang(lang) {
    return {
        type: SET_LANG,
        lang
    };
};
export function resetPage() {
    return {
        type: RESET_PAGE,
    };
};
export function fetchPage() {
    return {
        type: FETCH_PAGE,
    };
};
export function fetchPageDataDone(pages) {
    return {
        type: FETCH_PAGE_DATA_DONE,
        pages,
    };
};
export function fetchArticleList() {
    return {
        type: FETCH_PAGE_ARTICLE,
    }
}
export function fetchArticleListDone(){
    return {
        type: FETCH_PAGE_ARTICLE_DONE,
    }
}
export function changeArticleData(articleId, index) {
    // const validateData = {
    //     categoryId: oldData.categoryId,
    //     subCatId: oldData.subCatId,
    //     cover: oldData.cover,
    //     nameTh: oldData.name.th,
    //     descTh: oldData.desc.th,
    // }
    // const errors = validate(validateData, schema);
    return {
        type: SET_PAGE_ARTICLE,
        articleId,
        index,
    };
}
export function fetchPageDone(message) {
    return {
        type: FETCH_PAGE_DONE,
        message,
    };
};
export function fetchPageFail(message) {
    return {
        type: FETCH_PAGE_FAIL,
        message,
    };
};
export function updatePage(oldData, lang, fieldName, newValue) {
    if (lang === ''){
        oldData[fieldName] = newValue;
    } else {
        oldData[fieldName][lang] = newValue;
    }
    
    const validateData = {
        cover: oldData.cover,
        titleTh: oldData.title.th,
        descTh: oldData.desc.th,
    }
    const errors = validate(validateData, schema);

    return {
        type: UPDATE_PAGE,
        lang,
        fieldName,
        newValue,
        errors: errors,
    };
};

// Async Action Creator
export function postImage(file, oldData, fieldName){
    if (!file) {
        return {
            type: FETCH_PAGE_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return function (dispatch) {
        dispatch(fetchPage());        
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`pages/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dispatch(updatePage(oldData,'', fieldName, downloadURL));
                dispatch(fetchPageDone(''));
            });
        });
    };
}

export function getPage(uid) {
    return function (dispatch) {
        dispatch(fetchPage());

        const initialData = functions.httpsCallable('pageGetData');
        return initialData(uid).then(function (result) {
            const data = result.data;
            dispatch(fetchPageDataDone(data));
        }).catch(function (error) {
            console.log(error);
            // doc.data() will be undefined in this case
            dispatch(showSnack('fail', "No such document!"));
            dispatch(fetchPageFail("No such document!"));
        });
    };
}

export function postPageEdit(uid,data) {
    return function (dispatch) {
        dispatch(fetchPage());
        
        const pageRef = firebase.firestore().collection("pages").doc(uid);

        const inputData = {
            cover: data.cover,
            desc: data.desc,
            title: data.title,
        };

        return pageRef.set(inputData, { merge: true }).then((docRef) => {
            dispatch(fetchPageDone('Page updated successfully.'));
            dispatch(showSnack('success', 'Page updated successfully.'));
        }).catch((error) => {
            console.log(error);
            dispatch(fetchPageFail(error.message));
        });
    };
}

export function savePageArticle(uid,topics) {
    return function (dispatch) {
        dispatch(fetchArticleList());
        const inputData = {
            topics: _.map(topics, 'uid'),
        }
        const ref = firebase.firestore().collection("pages").doc(uid);
        return ref.set(inputData, { merge: true }).then((docRef) => {
            dispatch(fetchPageDone('Page updated successfully.'));
            dispatch(showSnack('success', 'Page updated successfully.'));
        }).catch((error) => {
            console.log(error);
            dispatch(fetchPageFail(error.message));
        });
    };
}