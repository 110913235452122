import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import firebase from 'common/firebase';
import { getLogin } from './auth';
import { showSnack } from './main';

// Action
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_DONE = 'FETCH_USER_DONE';
export const UPDATE_DETAIL = 'UPDATE_DETAIL';
export const FETCH_DETAIL = 'FETCH_DETAIL';
export const FETCH_DETAIL_DONE = 'FETCH_DETAIL_DONE';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const FETCH_EMAIL = 'FETCH_EMAIL';
export const FETCH_EMAIL_DONE = 'FETCH_EMAIL_DONE';
export const FETCH_EMAIL_FAIL = 'FETCH_EMAIL_FAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FETCH_PASSWORD = 'FETCH_PASSWORD';
export const FETCH_PASSWORD_DONE = 'FETCH_PASSWORD_DONE';
export const FETCH_PASSWORD_FAIL = 'FETCH_PASSWORD_FAIL';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_DONE = 'FETCH_PROFILE_DONE';
export const FETCH_PROFILE_FAIL = 'FETCH_PROFILE_FAIL';

const detailSchema = {
    fname: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    lname: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    desc: {
        presence: { allowEmpty: true, message: 'is required' },
        length: {
            maximum: 200
        }
    }
};
const emailSchema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    },
};

const passwordSchema = {
    oldPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    },
    newPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    },
    confirmNewPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    },
};

// Sync Action Creator
export function fetchUser() {
    return {
        type: FETCH_USER,
    };
};
export function fetchUserDone(user) {
    return {
        type: FETCH_USER_DONE,
        user,
    };
};
export function updateDetail(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;
    const errors = validate(oldData, detailSchema);

    return {
        type: UPDATE_DETAIL,
        fieldName,
        newValue,
        errors: errors,
    };
};
export function fetchDetail() {
    return {
        type: FETCH_DETAIL,
    };
};
export function fetchDetailDone() {
    return {
        type: FETCH_DETAIL_DONE,
    };
};
export function updateEmail(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;
    const errors = validate(oldData, emailSchema);

    return {
        type: UPDATE_EMAIL,
        fieldName,
        newValue,
        errors: errors,
    };
};
export function fetchEmail() {
    return {
        type: FETCH_EMAIL,
    };
};
export function fetchEmailDone(message) {
    return {
        type: FETCH_EMAIL_DONE,
        message,
    };
};
export function fetchEmailFail(message) {
    return {
        type: FETCH_EMAIL_FAIL,
        message,
    };
};
export function updatePassword(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;
    const errors = validate(oldData, passwordSchema);

    return {
        type: UPDATE_PASSWORD,
        fieldName,
        newValue,
        errors,
    };
};
export function fetchPassword() {
    return {
        type: FETCH_PASSWORD,
    };
};
export function fetchPasswordDone(message) {
    return {
        type: FETCH_PASSWORD_DONE,
        message,
    };
};
export function fetchPasswordFail(message) {
    return {
        type: FETCH_PASSWORD_FAIL,
        message,
    };
};
export function fetchProfile() {
    return {
        type: FETCH_PROFILE,
    };
};
export function fetchProfileDone(message) {
    return {
        type: FETCH_PROFILE_DONE,
        message,
    };
};
export function fetchProfileFail(message) {
    return {
        type: FETCH_PROFILE_FAIL,
        message,
    };
};

// Async Action Creator
export function getUser() {
    return function (dispatch) {
        dispatch(fetchUser());
        return firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                const data = {};
                data.uid = user.uid;
                data.name = user.displayName;
                data.profile = user.photoURL;
                data.email = user.email;

                const usersRef = firebase.firestore().collection('admins').doc(user.uid);
                usersRef.get().then(function (doc) {
                    if (doc.exists) {
                        const snapshort = doc.data();
                        data.fname = snapshort.fname;
                        data.lname = snapshort.lname;
                        data.desc = snapshort.desc;
                        data.role = snapshort.role;
                        dispatch(fetchUserDone(data));
                    } else {
                        console.log(" No user is signed in.");
                        dispatch(showSnack('fail', " No user is signed in."));
                    }
                }).catch(function (error) {
                    console.log("Error getting document:", error);
                });
            } else {
                // No user is signed in.
                console.log(" No user is signed in.");
                dispatch(showSnack('fail', " No user is signed in."));
            }
        });
    };
}

export function postUpdateDetail(data) {
    return function (dispatch) {
        dispatch(fetchDetail());

        // Update Firestore
        const usersRef = firebase.firestore().collection('admins').doc(data.uid);
        return usersRef.set({
            fname: data.fname,
            lname: data.lname,
            desc: data.desc,
        }, { merge: true }).then(function () {
            // Update Auth Profile
            const user = firebase.auth().currentUser;
            user.updateProfile({ displayName: `${data.fname} ${data.lname}` }).then(function(){
                // Get isLogin
                dispatch(getLogin());
                dispatch(fetchDetailDone());
                dispatch(showSnack('success', "Your information updated successfully."));
            }).catch(function (error) {
                console.log(error.message);
            });
        })
        .catch(function (error) {
            console.error("Error writing document: ", error.message);
        });
    };
}

export function postUpdateEmail(data) {
    return function (dispatch) {
        dispatch(fetchEmail());

        const user = firebase.auth().currentUser;
        
        // User re-authenticated.
        return firebase.auth().signInWithEmailAndPassword(user.email, data.password).then(function (userCredential) {
            // Update Email
            userCredential.user.updateEmail(data.email).then(function () {
                dispatch(getUser());
                dispatch(fetchEmailDone(""));
                dispatch(showSnack('success', "Your email updated successfully."));
            }).catch(function (error) {
                dispatch(fetchEmailFail(error.message));
            });;
        }).catch(function (error) {
            dispatch(fetchEmailFail(error.message));
        });
    };
}

export function postUpdatePassword(data) {
    if (data.newPassword !== data.confirmNewPassword) {
        return {
            type: FETCH_PASSWORD_FAIL,
            message: 'New password and confirm password does not match.',
        };
    }
    return function (dispatch) {
        dispatch(fetchPassword());

        const user = firebase.auth().currentUser;
        // User re-authenticated.
        return firebase.auth().signInWithEmailAndPassword(user.email, data.oldPassword).then(function (userCredential) {
            // Update Password
            userCredential.user.updatePassword(data.newPassword).then(function(){
                // Update successful.
                dispatch(getUser());
                dispatch(fetchPasswordDone(""));
                dispatch(showSnack('success', "Your password updated successfully."));
            }).catch(function (error) {
                dispatch(fetchPasswordFail(error.message));
            });
        }).catch(function (error) {
            dispatch(fetchPasswordFail(error.message));
        });
    };
}

export function postProfile(file) {
    if (!file){
        return {
            type: FETCH_PROFILE_DONE,
            message: 'No selected file.',
        };
    }

    return function (dispatch) {
        dispatch(fetchProfile());
        
        // Add file to storage
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`admin/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // Update Auth Profile
                const user = firebase.auth().currentUser;
                user.updateProfile({photoURL: downloadURL}).then(function() {
                    dispatch(getUser());
                    dispatch(getLogin());
                    dispatch(fetchProfileDone('Upload file done.'));
                    dispatch(showSnack('success', "Your profile uploaded successfully."));
                }).catch(function (error) {
                    console.log(error.message);
                });
            });
        });
    };
}

export function postRemoveProfile() {

    return function (dispatch) {
        dispatch(fetchProfile());
        const user = firebase.auth().currentUser;
        return user.updateProfile({ photoURL: null }).then(function () {
            dispatch(getUser());
            dispatch(getLogin());
            dispatch(fetchProfileDone('Remove file done.'));
            dispatch(showSnack('success', "Your profile removed successfully."));
        }).catch(function (error) {
            console.log(error.message);
        });
    };
}

