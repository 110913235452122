import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from 'containers/RouteWithLayout';
import MainLayout from 'containers/MainLayout';
import SignInView from 'containers/SignIn';
import ForgotPasswordView from 'containers/ForgotPass';
import ResetPasswordView from 'containers/ResetPassword';
import SettingsView from 'containers/Settings';
import UserListView from 'containers/UserList';
import UserCreateView from 'containers/UserCreate';
import UserUpdateView from 'containers/UserUpdate';
import AdsAllView from 'containers/AdsAll';
import AdsSideView from 'containers/AdsSide';
import AdsHomeView from 'containers/AdsHome';
import AdsCreateView from 'containers/AdsCreate';
import AdsEditView from 'containers/AdsEdit';
import PageHomeView from 'containers/PageHome';
import PagePostsView from 'containers/PagePosts';
import PageLiveView from 'containers/PageLive';
import PageLifestyleView from 'containers/PageLifestyle';
import PageFinanceView from 'containers/PageFinance';
import PageInspirationView from 'containers/PageInspiration';
import TagCreateView from 'containers/TagCreate';
import TagListView from 'containers/TagList';
import TagEditView from 'containers/TagEdit';
import LocationCreateView from 'containers/LocationCreate';
import LocationListView from 'containers/LocationList';
import LocationEditView from 'containers/LocationUpdate';
import WebboardCreateView from 'containers/WebboardCreate';
import WebboardListView from 'containers/WebboardList';
import WebboardEditView from 'containers/WebboardUpdate';
import ArticleDraftView from 'containers/ArticleDraft';
import ArticlePublishView from 'containers/ArticlePublish';
import ArticleCreateView from 'containers/ArticleCreate';
import ArticleEditView from 'containers/ArticleUpdate';
import TermConditionView from 'containers/TermCondition';
import TermAdsvertisingView from 'containers/TermAdsvertising';
import TermWhatIsAALVView from 'containers/TermWhatIsAALV';
import TermPrivacyView from 'containers/TermPrivacy';
import DeveloperCreateView from 'containers/DeveloperCreate';
import DeveloperListView from 'containers/DeveloperList';
import DeveloperEditView from 'containers/DeveloperUpdate';
import ProjectCreateView from 'containers/ProjectCreate';
import ProjectListView from 'containers/ProjectList';
import ProjectEditView from 'containers/ProjectUpdate';
import PropCreateView from 'containers/PropertyCreate';
import PropListView from 'containers/PropertyList';
import PropListSoldView from 'containers/PropertySoldList';
import PropListPromotedView from 'containers/PropertyPromotedList';
import PropEditView from 'containers/PropertyUpdate';
import OrderListView from 'containers/OrderList';
import InboxView from 'containers/Inbox';
import { Minimal as MinimalLayout } from './layouts';
import { NotFound as NotFoundView } from './views';

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/sign-in" />
            {/* Blogs */}
            <Redirect exact from="/articles/" to="/articles/draft" />
            <RouteWithLayout
                layout={MainLayout}
                component={ArticleDraftView}
                exact
                path="/articles/draft"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={ArticleDraftView}
                exact
                path="/articles/draft/:categoryId"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={ArticlePublishView}
                exact
                path="/articles/publish"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={ArticlePublishView}
                exact
                path="/articles/publish/:categoryId"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={ArticleCreateView}
                exact
                path="/articles/create"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={ArticleEditView}
                exact
                path="/articles/edit/:tagId"
                authRequired
            />
            {/* Webboard */}
            <Redirect exact from="/webboard/" to="/webboard/all" />
            <RouteWithLayout
                layout={MainLayout}
                component={WebboardListView}
                exact
                path="/webboard/all"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={WebboardCreateView}
                exact
                path="/webboard/create"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={WebboardEditView}
                exact
                path="/webboard/edit/:topicId"
                authRequired
            />
            {/* Locations */}
            <Redirect exact from="/locations/" to="/locations/all" />
            <RouteWithLayout
                layout={MainLayout}
                component={LocationListView}
                exact
                path="/locations/all"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={LocationCreateView}
                exact
                path="/locations/create"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={LocationEditView}
                exact
                path="/locations/edit/:tagId"
                authRequired
            />
            {/* Authentication */}
            <RouteWithLayout
                layout={MinimalLayout}
                component={SignInView}
                exact
                path="/sign-in"
                noAuthRequired
            />
            <RouteWithLayout
                layout={MinimalLayout}
                component={ForgotPasswordView}
                exact
                path="/forgot-password"
                noAuthRequired
            />
            <RouteWithLayout
                layout={MinimalLayout}
                component={ResetPasswordView}
                path="/reset-password"
                noAuthRequired
            />
            {/* Setting */}
            <RouteWithLayout
                layout={MainLayout}
                component={SettingsView}
                exact
                path="/settings"
                authRequired
            />
            {/* Users */}
            <Redirect exact from="/users/" to="/users/all" />
            <RouteWithLayout
                layout={MainLayout}
                component={UserListView}
                exact
                path="/users/all"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={UserCreateView}
                exact
                path="/users/create"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={UserUpdateView}
                exact
                path="/users/edit/:userId"
                authRequired
            />
            {/* Ads */}
            <Redirect exact from="/ads/" to="/ads/all" />
            <RouteWithLayout
                layout={MainLayout}
                component={AdsAllView}
                exact
                path="/ads/all"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={AdsSideView}
                exact
                path="/ads/side"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={AdsHomeView}
                exact
                path="/ads/home"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={AdsCreateView}
                exact
                path="/ads/create"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={AdsEditView}
                exact
                path="/ads/edit/:adsId"
                authRequired
            />
            {/* Pages */}
            <Redirect exact from="/pages/" to="/pages/home" />
            <RouteWithLayout
                layout={MainLayout}
                component={PageHomeView}
                exact
                path="/pages/home"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={PageLiveView}
                exact
                path="/pages/live"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={PageLifestyleView}
                exact
                path="/pages/lifestyle"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={PageFinanceView}
                exact
                path="/pages/finance"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={PageInspirationView}
                exact
                path="/pages/inspiration"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={PagePostsView}
                exact
                path="/pages/posts"
                authRequired
            />
            {/* Term & Condition */}
            <Redirect exact from="/term/" to="/term/condition" />
            <RouteWithLayout
                layout={MainLayout}
                component={TermConditionView}
                exact
                path="/term/condition"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={TermAdsvertisingView}
                exact
                path="/term/adsvertising"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={TermWhatIsAALVView}
                exact
                path="/term/what-is-aalv"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={TermPrivacyView}
                exact
                path="/term/privacy-policy"
                authRequired
            />
            {/* Tags */}
            <Redirect exact from="/tags/" to="/tags/all" />
            <RouteWithLayout
                layout={MainLayout}
                component={TagCreateView}
                exact
                path="/tags/create"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={TagListView}
                exact
                path="/tags/all"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={TagEditView}
                exact
                path="/tags/edit/:tagId"
                authRequired
            />
            {/* Developers */}
            <RouteWithLayout
                layout={MainLayout}
                component={DeveloperListView}
                exact
                path="/developers"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={DeveloperCreateView}
                exact
                path="/developers/create"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={DeveloperEditView}
                exact
                path="/developers/edit/:id"
                authRequired
            />
            {/* Projects */}
            <RouteWithLayout
                layout={MainLayout}
                component={ProjectListView}
                exact
                path="/projects"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={ProjectCreateView}
                exact
                path="/projects/create"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={ProjectEditView}
                exact
                path="/projects/edit/:id"
                authRequired
            />
            {/* Sell-Rent */}
            <Redirect exact from="/properties/" to="/properties/normal" />
            <RouteWithLayout
                layout={MainLayout}
                component={PropListView}
                exact
                path="/properties/normal"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={PropListPromotedView}
                exact
                path="/properties/promoted"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={PropListSoldView}
                exact
                path="/properties/soldrented"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={PropCreateView}
                exact
                path="/properties/create"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={PropEditView}
                exact
                path="/properties/edit/:id"
                authRequired
            />
            {/* Orders */}
            <RouteWithLayout
                layout={MainLayout}
                component={OrderListView}
                exact
                path="/orders"
                authRequired
            />
            {/* Chat */}
            <RouteWithLayout
                layout={MainLayout}
                component={InboxView}
                exact
                path="/inbox"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={InboxView}
                exact
                path="/inbox/:inboxId"
                authRequired
            />
            {/* Not Found */}
            <RouteWithLayout
                layout={MinimalLayout}
                component={NotFoundView}
                exact
                path="/not-found"
            />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
