import { connect } from 'react-redux';
import {
    getList,
    postDelete,
    resetData,
    querySearch,
    loadmore,
    showless,
    queryByIndex,
} from 'actions/locations';
import LocationList from 'views/Location/List';

const mapStateToProps = state => ({
    loading: state.locations.loading,
    list: state.locations.list,
    isSearching: state.locations.isSearching,
    loadingMore: state.locations.loadingMore,
    showLoadingMore: state.locations.showLoadingMore,
    startIndex: state.locations.startIndex,
    endIndex: state.locations.endIndex,
    numberOfDocs: state.locations.numberOfDocs,
});

const mapDispatchToProps = {
    getList,
    postDelete,
    resetData,
    querySearch,
    loadmore,
    showless,
    queryByIndex,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationList);