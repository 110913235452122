import React, { forwardRef } from 'react';
import { NavLink as RouterLink, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Card,
    CardHeader,
    CardActions,
    Divider,
    Button,
    TextField,
    Typography,
    Box,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import GalleryField from 'components/GalleryField';
import TypeField from 'components/PropertyTypeField';
import PurposeField from 'components/PropertyPurpose';
import StatusField from 'components/PropertyStatus';
import FurnitureField from 'components/PropertyFurniture';
import ProjectField from 'components/ProjectSearch';
import DirectionField from 'components/PropertyRoomDirection';
import RoomNumberField from 'components/PropertyRoomNumber';
import BathroomNumberField from 'components/PropertyBathRoomNumber';
import Map from 'components/Map';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            marginRight: theme.spacing(2),
        },
    },
}));

const AccountDetails = props => {
    const classes = useStyles();

    const {
        uid,
        title,
        loading,
        data,
        isValid,
        successMessage,
        update,
        postCreate,
        postGallery,
        removeGallery,
        changeType,
        changeProject,
        changeGeo,
    } = props;

    // Typing
    const handleChange = event => {
        update(data, event.target.name, event.target.value);
    };

    const handleSave = () => {
        if (_.isEmpty(uid)) {
            postCreate(data);
        } else {
            postCreate(uid, data);
        }
    };

    if (!_.isEmpty(successMessage)) {
        return <Redirect to="/properties/" />;
    }

    return (
        <Card>
            <CardHeader title={title} />
            <Divider />
            <Box p={2}>
                <Box mb={3}>
                    <Typography variant="h4">Location Data</Typography>
                </Box>
                <TypeField
                    loading={loading}
                    value={data.type}
                    oldData={data}
                    changeValue={changeType}
                />
                {
                    data.type === 'project' &&
                    <ProjectField
                        loading={loading}
                        oldData={data}
                        update={proId => changeProject(proId, data)}
                        selectedValue={data.projectId}
                    />
                }
                {
                    data.type === 'personal' &&
                    <>
                        <Box mt={2} mb={2}>
                            <Typography variant="body1">Choose Location* :</Typography>
                        </Box>
                        <Map
                            loading={loading}
                            category="live"
                            lat={data.location.lat}
                            lng={data.location.lng}
                            changeGeo={(lat, lng) => changeGeo(lat, lng, data)}
                        />
                    </>
                }
            </Box>
            <Divider />
            <Box p={2}>
                <Box mb={3}>
                    <Typography variant="h4">Property Data</Typography>
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        label="Title"
                        name="title"
                        onChange={handleChange}
                        type="text"
                        value={data.title}
                        variant="outlined"
                        disabled={loading}
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        label="Description"
                        name="desc"
                        multiline
                        rows={3}
                        onChange={handleChange}
                        type="text"
                        value={data.desc}
                        variant="outlined"
                        disabled={loading}
                    />
                </Box>
                <Box className={classes.root} mb={2}>
                    <PurposeField
                        loading={loading}
                        value={data.purpose}
                        changeValue={(newValue) => update(data, 'purpose', newValue)}
                    />
                    {(data.purpose === 'sell' || data.purpose === 'sellrent') &&
                        <TextField
                            label="Sell Price (THB)"
                            name="sellPrice"
                            onChange={handleChange}
                            type="number"
                            value={data.sellPrice}
                            variant="outlined"
                            disabled={loading}
                            required
                        />
                    }
                    {(data.purpose === 'rent' || data.purpose === 'sellrent') &&
                        <TextField
                            label="Rent Price (THB/Month)"
                            name="rentPrice"
                            onChange={handleChange}
                            type="number"
                            value={data.rentPrice}
                            variant="outlined"
                            disabled={loading}
                            required
                        />
                    }
                </Box>
                <Box className={classes.root} mb={2}>
                    <TextField
                        label="Land Size (ตร.วา)"
                        name="landSize"
                        onChange={handleChange}
                        type="number"
                        value={data.landSize}
                        variant="outlined"
                        disabled={loading}
                        helperText="ขนาดที่ดิน"
                    />
                    <TextField
                        label="Area Size (ตร.ม.)"
                        name="areaSize"
                        onChange={handleChange}
                        type="number"
                        value={data.areaSize}
                        variant="outlined"
                        disabled={loading}
                        helperText="พื้นที่ใช้สอย"
                        required
                    />
                </Box>
                <Box className={classes.root} mb={2}>
                    <RoomNumberField
                        loading={loading}
                        value={data.bedroomNum}
                        changeValue={(newValue) => update(data, 'bedroomNum', newValue)}
                    />
                    <BathroomNumberField
                        loading={loading}
                        value={data.toiletNum}
                        changeValue={(newValue) => update(data, 'toiletNum', newValue)}
                    />
                    <TextField
                        label="Floor"
                        name="floor"
                        onChange={handleChange}
                        type="number"
                        value={data.floor}
                        variant="outlined"
                        disabled={loading}
                    />
                </Box>
                <Box className={classes.root} >
                    <FurnitureField
                        loading={loading}
                        value={data.furniture}
                        changeValue={(newValue) => update(data, 'furniture', newValue)}
                    />
                    <DirectionField
                        loading={loading}
                        value={data.direction}
                        changeValue={(newValue) => update(data, 'direction', newValue)}
                    />
                    <StatusField
                        loading={loading}
                        value={data.projectStatus}
                        changeValue={(newValue) => update(data, 'projectStatus', newValue)}
                    />
                </Box>
            </Box>
            <Divider />
            <Box p={2}>
                <Box mb={2}>
                    <Typography variant="body1">
                        Gallery (Maximum of 10 images)* :
                    </Typography>
                </Box>
                <GalleryField
                    list={data.gallery}
                    oldData={data}
                    postGallery={postGallery}
                    removeGallery={removeGallery}
                />
            </Box>
            <Divider />
            <CardActions style={{ padding: 16 }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSave()}
                    disabled={!isValid || loading}>
                    Save
                </Button>
                <Button
                    color="default"
                    variant="outlined"
                    component={CustomRouterLink}
                    to="/properties/">
                    Cancel
                </Button>
            </CardActions>
        </Card>
    );
};

AccountDetails.propTypes = {
    title: PropTypes.string.isRequired,
};

export default AccountDetails;
