import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Toolbar, Details, TopFivePic, TopFiveForm } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
}));

const HomeList = (props) => {
    const classes = useStyles();
    const {
        loading,
        lang,
        pages,
        errors,
        isValid,
        resetPage,
        updatePage,
        postImage,
        getPage,
        postPageEdit,
        changeLang,
    } = props;

    React.useEffect(() => {
        getPage('live');
    }, [getPage]);

    return (
        <div className={classes.root}>
            <Toolbar 
                tabActive="live"
                resetPage={resetPage}
            />
            <div className={classes.content}>
                <Grid
                    container
                    spacing={4}
                >
                    <Grid item md={5} xs={12}>
                        <TopFivePic />
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <TopFiveForm
                            uid="live"
                            loading={loading}
                            topics={pages.topics}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Details
                            uid="live"
                            lang={lang}
                            loading={loading}
                            pages={pages}
                            errors={errors}
                            isValid={isValid}
                            updatePage={updatePage}
                            postImage={postImage}
                            postPageEdit={postPageEdit}
                            changeLang={changeLang}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default HomeList;
