import {
    SET_DEVELOPER_LANG,
    UPDATE_DEVELOPER,
    FETCH_DEVELOPER,
    FETCH_DEVELOPER_DATA_DONE,
    FETCH_DEVELOPER_DONE,
    FETCH_DEVELOPER_FAIL,
    RESET_DEVELOPER,
    FETCH_DEVELOPER_LIST_DONE,
    DELETE_DEVELOPER,
    UPDATE_DEVELOPER_SEARCH,
    FETCH_DEVELOPER_LOADMORE,
    FETCH_DEVELOPER_LOADMORE_DONE,
    FETCH_DEVELOPER_SHOWLESS,
    UPDATE_DEVELOPER_INDEX,
    FETCH_DEVELOPER_INDEX_DONE,
} from '../actions/developer';

const initialState = {
    loading: false,
    lang: 'th',
    isSearching: false,
    list: [],
    loadingMore: false,
    showLoadingMore: true,
    startIndex: 1,
    endIndex: 25,
    numberOfDocs: 0,
    data: {
        search: '',
        name: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const developersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEVELOPER_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case RESET_DEVELOPER:
            return {
                ...initialState,
                data: {
                    search: '',
                    name: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                },
            };
        case UPDATE_DEVELOPER:
            if (action.lang === 'th' && action.fieldName === 'name'){
                return {
                    ...state,
                    data: {
                        ...state.data,
                        search: action.newValue,
                        name: {
                            ...state.data.name,
                            [action.lang]: action.newValue,
                        },
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: {
                        ...state.data[action.fieldName],
                        [action.lang]: action.newValue,
                    },
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case UPDATE_DEVELOPER_SEARCH:
            return {
                ...state,
                isSearching: action.newValue !== '',
            };
        case FETCH_DEVELOPER:
            return {
                ...state,
                loading: true,
                loadingMore: false,
                showLoadingMore: true,
            };
        case FETCH_DEVELOPER_LOADMORE:
            return {
                ...state,
                loadingMore: true,
            };
        case FETCH_DEVELOPER_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_DEVELOPER_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_DEVELOPER_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        case FETCH_DEVELOPER_LIST_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                showLoadingMore: action.list.length < action.numberOfDocs,
                startIndex: 1,
                endIndex: action.list.length,
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_DEVELOPER_INDEX_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                showLoadingMore: (state.startIndex + action.list.length - 1) < action.numberOfDocs,
                endIndex: (state.startIndex + action.list.length - 1),
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_DEVELOPER_LOADMORE_DONE:
            return {
                ...state,
                loadingMore: false,
                endIndex: (state.endIndex + action.list.length),
                showLoadingMore: (state.endIndex + action.list.length) < state.numberOfDocs,
                list: [
                    ...state.list,
                    ...action.list,
                ],
                numberOfDocs: action.list.length < 25 ? state.endIndex + action.list.length : action.numberOfDocs,
            };
        case FETCH_DEVELOPER_SHOWLESS:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: true,
                list: [
                    ...state.list.slice(0, state.list.length - 25),
                ],
                endIndex: state.endIndex - 25,
            };
        case UPDATE_DEVELOPER_INDEX:
            return {
                ...state,
                startIndex: action.startIndex,
            };
        case DELETE_DEVELOPER:
            const index = state.list.findIndex(data => data.uid === action.uid);
            let newList = [...state.list];
            newList.splice(index, 1);
            return {
                ...state,
                list: newList
            };
        default:
            return state;
    }
};
export default developersReducer;
