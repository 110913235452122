import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Details } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Edit = (props) => {
    const classes = useStyles();
    const { 
        loading,
        lang,
        data,
        isValid,
        successMessage,
        changeLang,
        changeCategory,
        changeGeo,
        update,
        postEdit,
        getEdit,
        location,
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];
    React.useEffect(() => {
        getEdit(uid);
    }, [getEdit, uid]);

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid item xs={12}>
                    <Details 
                        title="Edit Location" 
                        uid={uid}
                        loading={loading}
                        lang={lang}
                        data={data}
                        isValid={isValid}
                        successMessage={successMessage}
                        changeLang={changeLang}
                        changeCategory={changeCategory}
                        changeGeo={changeGeo}
                        update={update}
                        postCreate={postEdit}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(Edit);
