import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import firebase from 'common/firebase';
import { showSnack } from './main';
const functions = firebase.app().functions('asia-east2');

// Action
export const UPDATE_USER_EDIT = 'UPDATE_USER_EDIT';
export const FETCH_USER_EDIT_PROFILE = 'FETCH_USER_EDIT_PROFILE';
export const FETCH_USER_EDIT_PROFILE_DONE = 'FETCH_USER_EDIT_PROFILE_DONE';
export const FETCH_USER_EDIT_PROFILE_FAIL = 'FETCH_USER_EDIT_PROFILE_FAIL';
export const UPDATE_USER_EDIT_PROFILE = 'UPDATE_USER_EDIT_PROFILE';
export const REMOVE_USER_EDIT_PROFILE = 'REMOVE_USER_EDIT_PROFILE';
export const FETCH_USER_EDIT = 'FETCH_USER_EDIT';
export const FETCH_USER_EDIT_DONE = 'FETCH_USER_EDIT_DONE';
export const FETCH_USER_EDIT_FAIL = 'FETCH_USER_EDIT_FAIL';
export const RESET_USER_EDIT = 'RESET_USER_EDIT';
export const FETCH_USER_EDIT_DATA_DONE = 'FETCH_USER_EDIT_DATA_DONE';

const schema = {
    fname: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    lname: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    desc: {
        presence: { allowEmpty: true, message: 'is required' },
        length: {
            maximum: 200
        }
    },
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: true, message: 'is required' },
        length: {
            maximum: 128
        }
    },
};

// Sync Action Creator
export function fetchUserEdit() {
    return {
        type: FETCH_USER_EDIT,
    };
};
export function fetchUserEditDone(message) {
    return {
        type: FETCH_USER_EDIT_DONE,
        message,
    };
};
export function fetchUserEditFail(message) {
    return {
        type: FETCH_USER_EDIT_FAIL,
        message,
    };
};
export function updateUserEdit(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;
    let errors = validate(oldData, schema);
    if (fieldName !== 'desc'){
        oldData[fieldName] = newValue.trim();
        errors = validate(oldData, schema);
        return {
            type: UPDATE_USER_EDIT,
            fieldName,
            newValue: newValue.trim(),
            errors: errors,
        };
    }

    return {
        type: UPDATE_USER_EDIT,
        fieldName,
        newValue,
        errors: errors,
    };
};
export function fetchProfile() {
    return {
        type: FETCH_USER_EDIT_PROFILE,
    };
};
export function fetchProfileDone(message) {
    return {
        type: FETCH_USER_EDIT_PROFILE_DONE,
        message,
    };
};
export function fetchProfileFail(message) {
    return {
        type: FETCH_USER_EDIT_PROFILE_FAIL,
        message,
    };
};
export function updateProfile(url) {
    return {
        type: UPDATE_USER_EDIT_PROFILE,
        url,
    };
};
export function removeProfile() {
    return {
        type: REMOVE_USER_EDIT_PROFILE,
    };
};
export function resetUserEdit() {
    return {
        type: RESET_USER_EDIT,
    };
};
export function fetchUserEditDataDone(user) {
    return {
        type: FETCH_USER_EDIT_DATA_DONE,
        user,
    };
};

// Async Action Creator
export function getUserEdit(uid) {
    return function (dispatch) {
        dispatch(fetchUserEdit());
        const adminGetId = functions.httpsCallable('adminGetId');
        return adminGetId(uid).then(function (result) {
            if (result.data.errorInfo) {
                dispatch(fetchUserEditFail(result.data.errorInfo.message));
            }else{
                dispatch(fetchUserEditDataDone(result.data));
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(fetchUserEditFail(error.message));
        });
    };
}

export function postProfile(file) {
    if (!file){
        return {
            type: FETCH_USER_EDIT_PROFILE_DONE,
            message: 'No selected file.',
        };
    }

    return function (dispatch) {
        dispatch(fetchProfile());
        // Add file to storage
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`admin/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dispatch(updateProfile(downloadURL));
            });
        });
    };
}

export function postUserEdit(data) {
    return function (dispatch) {
        dispatch(fetchUserEdit());
        console.log(data);
        const adminUpdate = functions.httpsCallable('adminUpdate');
        return adminUpdate(data).then(function (result) {
            if (result.data === true) {
                dispatch(fetchUserEditDone('User updated successfully.'));
                dispatch(showSnack('success', 'User updated successfully.'));
            }else{
                dispatch(fetchUserEditFail(result.data.errorInfo.message));
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(fetchUserEditFail(error.message));
        });
    };
}
