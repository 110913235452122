import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import firebase from 'common/firebase';
import { showSnack } from './main';
const functions = firebase.app().functions('asia-east2');

// Action
export const UPDATE_USER_CREATE = 'UPDATE_USER_CREATE';
export const FETCH_USER_CREATE_PROFILE = 'FETCH_USER_CREATE_PROFILE';
export const FETCH_USER_CREATE_PROFILE_DONE = 'FETCH_USER_CREATE_PROFILE_DONE';
export const FETCH_USER_CREATE_PROFILE_FAIL = 'FETCH_USER_CREATE_PROFILE_FAIL';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const REMOVE_PROFILE = 'REMOVE_PROFILE';
export const FETCH_USER_CREATE = 'FETCH_USER_CREATE';
export const FETCH_USER_CREATE_DONE = 'FETCH_USER_CREATE_DONE';
export const FETCH_USER_CREATE_FAIL = 'FETCH_USER_CREATE_FAIL';
export const RESET_USER_CREATE = 'RESET_USER_CREATE';

const schema = {
    fname: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    lname: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    desc: {
        presence: { allowEmpty: true, message: 'is required' },
        length: {
            maximum: 200
        }
    },
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    },
};

// Sync Action Creator
export function fetchUserCreate() {
    return {
        type: FETCH_USER_CREATE,
    };
};
export function fetchUserCreateDone(message) {
    return {
        type: FETCH_USER_CREATE_DONE,
        message,
    };
};
export function fetchUserCreateFail(message) {
    return {
        type: FETCH_USER_CREATE_FAIL,
        message,
    };
};
export function updateUserCreate(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;
    const errors = validate(oldData, schema);

    return {
        type: UPDATE_USER_CREATE,
        fieldName,
        newValue,
        errors: errors,
    };
};
export function fetchProfile() {
    return {
        type: FETCH_USER_CREATE_PROFILE,
    };
};
export function fetchProfileDone(message) {
    return {
        type: FETCH_USER_CREATE_PROFILE_DONE,
        message,
    };
};
export function fetchProfileFail(message) {
    return {
        type: FETCH_USER_CREATE_PROFILE_FAIL,
        message,
    };
};
export function updateProfile(url) {
    return {
        type: UPDATE_PROFILE,
        url,
    };
};
export function removeProfile() {
    return {
        type: REMOVE_PROFILE,
    };
};
export function resetUserCreate() {
    return {
        type: RESET_USER_CREATE,
    };
};

// Async Action Creator
export function postProfile(file) {
    if (!file){
        return {
            type: FETCH_USER_CREATE_PROFILE_DONE,
            message: 'No selected file.',
        };
    }

    return function (dispatch) {
        dispatch(fetchProfile());
        // Add file to storage
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`admin/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dispatch(updateProfile(downloadURL));
            });
        });
    };
}

export function postUserCreate(data) {
    return function (dispatch) {
        dispatch(fetchUserCreate());
        const adminCreate = functions.httpsCallable('adminCreate');
        return adminCreate(data).then(function (result) {
            if (result.data === true) {
                dispatch(fetchUserCreateDone('User created successfully.'));
                dispatch(showSnack('success', 'User created successfully.'));
            }else{
                dispatch(fetchUserCreateFail(result.data.errorInfo.message));
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(fetchUserCreateFail(error.message));
        });
    };
}
