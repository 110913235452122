import {
    SHOW_SNACK,
    HIDE_SNACK
} from '../actions/main';

const initialState = {
    isShow: false,
    message: '',
    status: '',
};

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SNACK:
            return {
                ...state,
                isShow: true,
                message: action.message,
                status: action.status,
            };
        case HIDE_SNACK:
            return {
                ...state,
                isShow: false,
                message: '',
                status: '',
            };
        default:
            return state;
    }
};
export default mainReducer;