import React, { forwardRef } from 'react';
import { NavLink as RouterLink, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Card,
    CardHeader,
    CardActions,
    Divider,
    Button,
    TextField,
    Box,
} from '@material-ui/core';
import TagSearch from 'components/TagSearch';
import _ from 'lodash';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const AccountDetails = (props) => {
    const {
        uid,
        title,
        loading,
        data,
        isValid,
        successMessage,
        update,
        postCreate,
        changeTag,
        removeTag,
    } = props;

    // Typing
    const handleChange = event => {
        update(data, event.target.name, event.target.value);
    };

    const handleSave = () => {
        if (_.isEmpty(uid)) {
            postCreate(data);
        }
        else {
            postCreate(uid, data);
        }
    };

    if (!_.isEmpty(successMessage)) {
        return (
            <Redirect to="/webboard/" />
        );
    }

    return (
        <Card>
            <form
                autoComplete="off"
                noValidate
            >
                <CardHeader
                    title={title}
                />
                <Divider />
                <Box p={2}>
                    <Box mb={3}>
                        <TextField
                            fullWidth
                            label="Title"
                            name="title"
                            onChange={handleChange}
                            value={data.title}
                            variant="outlined"
                            disabled={loading}
                        />
                    </Box>
                    <Box mb={1}>
                        <TextField
                            fullWidth
                            multiline
                            rows={5}
                            rowsMax={50}
                            label="Desc"
                            name="desc"
                            onChange={handleChange}
                            value={data.desc}
                            variant="outlined"
                            disabled={loading}
                            type="text"
                        />
                    </Box>
                    <Box>
                        <TagSearch
                            loading={loading}
                            oldData={data}
                            selectedValue={data.tagId}
                            update={(tags) => changeTag(tags, data)}
                            remove={(index) => removeTag(index, data)}
                        />
                    </Box>
                </Box>
                <Divider />
                <CardActions style={{padding: 16}}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSave}
                        disabled={!isValid}
                    >
                        Save
                    </Button>
                    <Button
                        color="default"
                        variant="outlined"
                        component={CustomRouterLink}
                        to='/webboard/'
                    >
                        Cancel
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

AccountDetails.propTypes = {
    title: PropTypes.string.isRequired,
};

export default AccountDetails;
