import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Divider, AppBar, Tabs, Tab ,Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    root2: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabs:{
        marginTop: 16,
        marginBottom: 16,
    },
    appbar: {
        boxShadow: 'none',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    tab: {
        textTransform: 'none',
    },
}));

const UsersToolbar = props => {
    const { 
        className,
        resetAds, 
        tabActive,
        ...rest 
    } = props;

    const classes = useStyles();

    const tabs = [
        {
            title: 'All Page Ads (A1,A2,Popup)',
            href: '/ads/all',
        },
        {
            title: 'Side Ads (A2,A3)',
            href: '/ads/side',
        },
        {
            title: 'Home Ads (H1,H2)',
            href: '/ads/home',
        },
    ];

    let activeValue = 0;
    if (tabActive === 'publish'){
        activeValue = 1;
    }if(tabActive === 'home'){
        activeValue = 2;
    }


    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.row}>
                <span className={classes.spacer} />
                <Button
                    color="primary"
                    variant="contained"
                    component={CustomRouterLink}
                    to="/ads/create"
                    onClick={() => resetAds()}
                >
                    <AddIcon style={{ padding: 0, marginRight: 4, width: 16, height: 16 }} /> Create Ads
                </Button>
            </div>
            <div className={classes.tabs}>
                <AppBar
                    position="static"
                    color="default"
                    className={classes.appbar}
                >
                    <Tabs
                        indicatorColor="primary"
                        value={activeValue}
                        aria-label="simple tabs example"
                    >
                        {
                            tabs.map((item, key) =>
                                <Tab
                                    classes={{
                                        root: classes.tab,
                                    }}
                                    key={item.title}
                                    component={CustomRouterLink}
                                    id={`simple-tab-${key}`}
                                    aria-controls={`simple-tabpanel-${key}`}
                                    label={item.title}
                                    to={item.href}
                                />
                            )
                        }
                    </Tabs>
                </AppBar>
                <Divider />
            </div>
        </div>
    );
};

UsersToolbar.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    tabActive: PropTypes.string.isRequired,
};

export default UsersToolbar;
