import moment from 'moment';
import {
    FETCH_ORDER,
    FETCH_ORDER_DONE,
    FETCH_ORDER_FAIL,
    FETCH_ORDER_LIST_DONE,
    UPDATE_ORDER_TIME,
} from 'actions/order';

const initialState = {
    loading: false,
    start: moment()
        .subtract(1, 'months')
        .valueOf(),
    end: moment().valueOf(),
    list: [],
    errors: {},
    successMessage: '',
    failMessage: '',
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDER:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_ORDER_TIME:
            return {
                ...state,
                [action.field]: action.value,
            };
        case FETCH_ORDER_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        case FETCH_ORDER_LIST_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
            };
        default:
            return state;
    }
};
export default orderReducer;
