import { connect } from 'react-redux';
import {
    getList,
    postDelete,
    resetData,
    querySearch,
    loadmore,
    showless,
    queryByIndex,
} from 'actions/developer';
import DeveloperList from 'views/Developer/List';

const mapStateToProps = state => ({
    loading: state.developer.loading,
    list: state.developer.list,
    isSearching: state.developer.isSearching,
    loadingMore: state.developer.loadingMore,
    showLoadingMore: state.developer.showLoadingMore,
    startIndex: state.developer.startIndex,
    endIndex: state.developer.endIndex,
    numberOfDocs: state.developer.numberOfDocs,
});

const mapDispatchToProps = {
    getList,
    postDelete,
    resetData,
    querySearch,
    loadmore,
    showless,
    queryByIndex,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeveloperList);