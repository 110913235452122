export default {
    type: [
        {
            uid: 'sell',
            name: 'ขาย',
        },
        {
            uid: 'rent',
            name: 'เช่า',
        },
        {
            uid: 'sellrent',
            name: 'ขายและเช่า',
        },
    ],
};
