import _ from 'lodash';
import {
    SET_LANG,
    RESET_PAGE,
    UPDATE_PAGE,
    FETCH_PAGE,
    FETCH_PAGE_DATA_DONE,
    FETCH_PAGE_DONE,
    FETCH_PAGE_FAIL,
    FETCH_PAGE_ARTICLE,
    FETCH_PAGE_ARTICLE_DONE,
    SET_PAGE_ARTICLE,
    SAVE_PAGE_ARTICLE,
} from 'actions/pages';

const initialState = {
    loading: false,
    lang: 'th',
    pages: {
        cover: '',
        title: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
        desc: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
        topics: [],
    },
    articleLoading: true,
    categoryArticle: [],
    articleIsValid: false,
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const pagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case RESET_PAGE:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    cover: '',
                    title: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                    desc: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                    topics: [],
                },
            };
        case UPDATE_PAGE:
            if (action.fieldName === 'cover') {
                return {
                    ...state,
                    pages: {
                        ...state.pages,
                        cover: action.newValue
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            }
            if (action.fieldName === 'title') {
                return {
                    ...state,
                    pages: {
                        ...state.pages,
                        title: {
                            ...state.pages.title,
                            [action.lang]: action.newValue,
                        },
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            } 
            
            return {
                ...state,
                pages: {
                    ...state.pages,
                    desc: {
                        ...state.pages.desc,
                        [action.lang]: action.newValue,
                    },
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case FETCH_PAGE:
            return {
                ...state,
                loading: true,
            };
        case FETCH_PAGE_DATA_DONE:    
            return {
                ...state,
                loading: false,
                pages: action.pages,
            };
        case SET_PAGE_ARTICLE:
            let newList = [...state.pages.topics];
            newList[action.index] = action.articleId;
            console.log(!_.includes(newList, null));
            return {
                ...state,
                pages: {
                    ...state.pages,
                    topics: newList,
                },
                articleIsValid: !_.includes(newList, null),
            };
        case FETCH_PAGE_ARTICLE:
            return {
                ...state,
                articleLoading: true,
                articleIsValid: false,
            };
        case FETCH_PAGE_ARTICLE_DONE:
            return {
                ...state,
                articleLoading: false,
            };
        case SAVE_PAGE_ARTICLE:
            return {
                ...state,
                articleLoading: false,
                successMessage: action.message,
            };
        case FETCH_PAGE_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_PAGE_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default pagesReducer;
