import { connect } from 'react-redux';
import { getLogin } from 'actions/auth';
import RouteWithLayout from 'components/RouteWithLayout';

const mapStateToProps = state => ({
    isLogin: state.auth.isLogin,
});

const mapDispatchToProps = {
    getLogin,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RouteWithLayout);

