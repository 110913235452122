import React, { forwardRef } from 'react';
import { NavLink as RouterLink, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Card,
    CardHeader,
    CardActions,
    Divider,
    Button,
    TextField,
    Box,
    Grid,
} from '@material-ui/core';
import _ from 'lodash';

import Category from 'components/Category';
import SubCategory from 'components/SubCategory';
import TabLang from 'components/TabLang';
import TagSearch from 'components/TagSearch';
import LocationSearch from 'components/LocationSearch';
import ImageField from 'components/ImageField';
import Editor from 'components/Editor';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const AccountDetails = (props) => {
    const {
        uid,
        title,
        loading,
        lang,
        data,
        isValid,
        successMessage,
        articleStatus,
        changeLang,
        changeCategory,
        changeTag,
        removeTag,
        changeLocation,
        update,
        postCreate,
        changeCover,
        postImage,
    } = props;

    // Typing
    const handleChange = event => {
        update(data, lang, event.target.name, event.target.value);
    };

    const handleSave = (status) => {
        if (_.isEmpty(uid)) {
            postCreate(status, data);
        }
        else {
            postCreate(status, uid, data);
        }
    };

    if (!_.isEmpty(successMessage) && title === 'Create Article' ) {
        return (
            <Redirect to={`/articles/${articleStatus}`} />
        );
    } else if (!_.isEmpty(successMessage) && articleStatus === 'publish'){
        return (
            <Redirect to={`/articles/${articleStatus}`} />
        );
    }

    return (
        <Card>
            <CardHeader
                title={title}
            />
            <Divider />
            <Box p={2}>
                <Box mb={1}>
                    <Category 
                        loading={loading}
                        category={data.categoryId}
                        changeValue={(newCatId) => changeCategory(newCatId,'',data)}
                    />
                    <SubCategory 
                        loading={loading}
                        categoryId={data.categoryId}
                        subCatId={data.subCatId}
                        changeValue={(catId,subCatId) => changeCategory(catId, subCatId, data)}
                    />
                </Box>
                <Box mb={1}>
                    <TagSearch
                        loading={loading}
                        oldData={data}
                        selectedValue={data.tagId}
                        update={(tags) => changeTag(tags, data)}
                        remove={(index) => removeTag(index, data)}
                    />
                </Box>
                <Box>
                    <LocationSearch
                        loading={loading}
                        oldData={data}
                        selectedValue={data.locationId}
                        update={(loc) => changeLocation(loc, data)}
                        remove={(index) => removeTag(index, data)}
                    />
                </Box>
            </Box>
            <ImageField
                title={"Cover*"}
                width={2560}
                height={1440}
                loading={loading}
                url={data.cover}
                uploadFunc={(file) => postImage(file,data)}
                oldData={data}
                fieldName="cover"
                removeFunc={() => changeCover('',data)}
            />
            <Divider />
            <TabLang
                lang={lang}
                changeLang={changeLang}
                thActive={
                    !_.isEmpty(data.textOverCover.th)
                    ||
                    !_.isEmpty(data.name.th) 
                    || 
                    !_.isEmpty(data.desc.th)
                }
                enActive={
                    !_.isEmpty(data.textOverCover.en)
                    ||
                    !_.isEmpty(data.name.en) 
                    || 
                    !_.isEmpty(data.desc.en)
                }
                chActive={
                    !_.isEmpty(data.textOverCover.ch)
                    ||
                    !_.isEmpty(data.name.ch) 
                    || 
                    !_.isEmpty(data.desc.ch)
                }
                jpActive={
                    !_.isEmpty(data.textOverCover.jp)
                    ||
                    !_.isEmpty(data.name.jp) 
                    || 
                    !_.isEmpty(data.desc.jp)
                } 
                thNode={
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <TextField
                                key={`th-text-${lang === 'th'}`}
                                fullWidth
                                label="Text Over Cover"
                                margin="dense"
                                name="textOverCover"
                                onChange={handleChange}
                                value={data.textOverCover.th}
                                variant="outlined"
                                disabled={loading}
                            />
                            <TextField
                                key={`th-name-${lang === 'th'}`}
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="name"
                                onChange={handleChange}
                                required
                                value={data.name.th}
                                variant="outlined"
                                disabled={loading}
                            />
                            <Box mt={1}>
                                <Editor 
                                    oldData={data}
                                    lang="th"
                                    name="desc"
                                    onChange={update}
                                    value={data.desc.th}
                                    disabled={loading}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                }
                enNode={
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <TextField
                                key={`en-text-${lang === 'en'}`}
                                fullWidth
                                label="Text Over Cover"
                                margin="dense"
                                name="textOverCover"
                                onChange={handleChange}
                                value={data.textOverCover.en}
                                variant="outlined"
                                disabled={loading}
                            />
                            <TextField
                                key={`en-name-${lang === 'en'}`}
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="name"
                                onChange={handleChange}
                                value={data.name.en}
                                variant="outlined"
                                disabled={loading}
                            />
                            <Box mt={1}>
                                <Editor
                                    oldData={data}
                                    lang="en"
                                    name="desc"
                                    onChange={update}
                                    value={data.desc.en}
                                    disabled={loading}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                }
                chNode={
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <TextField
                                key={`ch-text-${lang === 'ch'}`}
                                fullWidth
                                label="Text Over Cover"
                                margin="dense"
                                name="textOverCover"
                                onChange={handleChange}
                                value={data.textOverCover.ch}
                                variant="outlined"
                                disabled={loading}
                            />
                            <TextField
                                key={`ch-name-${lang === 'ch'}`}
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="name"
                                onChange={handleChange}
                                value={data.name.ch}
                                variant="outlined"
                                disabled={loading}
                            />
                            <Box mt={1}>
                                <Editor
                                    oldData={data}
                                    lang="ch"
                                    name="desc"
                                    onChange={update}
                                    value={data.desc.ch}
                                    disabled={loading}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                }
                jpNode={
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <TextField
                                key={`jp-text-${lang === 'jp'}`}
                                fullWidth
                                label="Text Over Cover"
                                margin="dense"
                                name="textOverCover"
                                onChange={handleChange}
                                value={data.textOverCover.jp}
                                variant="outlined"
                                disabled={loading}
                            />
                            <TextField
                                key={`jp-name-${lang === 'jp'}`}
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="name"
                                onChange={handleChange}
                                value={data.name.jp}
                                variant="outlined"
                                disabled={loading}
                            />
                            <Box mt={1}>
                                <Editor
                                    oldData={data}
                                    lang="jp"
                                    name="desc"
                                    onChange={update}
                                    value={data.desc.jp}
                                    disabled={loading}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                }
            />
            <Divider />
            <CardActions style={{padding: 16}}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSave('publish')}
                    disabled={!isValid || loading}
                >
                    Publish
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleSave('draft')}
                    disabled={!isValid || loading}
                >
                    Save Draft
                </Button>
                <Button
                    color="default"
                    variant="outlined"
                    component={CustomRouterLink}
                    to='/articles/'
                >
                    Cancel
                </Button>
            </CardActions>
        </Card>
    );
};

AccountDetails.propTypes = {
    title: PropTypes.string.isRequired,
};

export default AccountDetails;
