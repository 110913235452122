import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Password, AccountProfile, AccountDetails, Email } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Settings = (props) => {
    const classes = useStyles();
    const {
        detailIsLoading,
        user, 
        userErrors,
        userTouched,
        updateDetail,
        postUpdateDetail, 

        // email
        email,
        emailErrors,
        emailTouched,
        emailIsLoading,
        emailIsValid,
        emailSuccessMessage,
        emailFailMessage,
        updateEmail,
        postUpdateEmail,

        // password
        password,
        passwordErrors,
        passwordTouched,
        passwordIsLoading,
        passwordIsValid,
        passwordSuccessMessage,
        passwordFailMessage,
        updatePassword,
        postUpdatePassword,

        // profile
        profileIsLoading, 
        postProfile,
        postRemoveProfile,
    } = props;

    React.useEffect(() => {
        const { getUser } = props;
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    lg={3}
                    md={6}
                    xl={4}
                    xs={12}
                >
                    <AccountProfile 
                        imageSrc={user.profile}
                        name={user.name} 
                        loading={profileIsLoading} 
                        postUpdate={postProfile}
                        postRemove={postRemoveProfile}
                        failMessage={passwordFailMessage}
                    />
                </Grid>
                <Grid
                    item
                    lg={9}
                    md={6}
                    xl={8}
                    xs={12}
                >
                    <AccountDetails 
                        loading={detailIsLoading}
                        touched={userTouched}
                        errors={userErrors}
                        uid={user.uid}
                        fname={user.fname} 
                        lname={user.lname} 
                        desc={user.desc} 
                        updateDetail={updateDetail}
                        postUpdateDetail={postUpdateDetail}
                    />
                </Grid>
                <Hidden smDown>
                    <Grid
                        item
                        lg={3}
                        md={6}
                        xl={4}
                        xs={12}
                    />
                </Hidden>
                <Grid
                    item
                    lg={9}
                    md={6}
                    xl={8}
                    xs={12}
                >
                    <Email
                        loading={emailIsLoading}
                        touched={emailTouched}
                        errors={emailErrors}
                        uid={user.uid}
                        email={email.email}
                        password={email.password}
                        updateEmail={updateEmail}
                        postUpdateEmail={postUpdateEmail}
                        isValid={emailIsValid}
                        successEmail={emailSuccessMessage}
                        failEmail={emailFailMessage}
                    />
                </Grid>
                <Hidden smDown>
                    <Grid
                        item
                        lg={3}
                        md={6}
                        xl={4}
                        xs={12}
                    />
                </Hidden>
                <Grid
                    item
                    lg={9}
                    md={6}
                    xl={8}
                    xs={12}
                >
                    <Password 
                        loading={passwordIsLoading}
                        touched={passwordTouched}
                        errors={passwordErrors}
                        uid={user.uid}
                        data={password}
                        update={updatePassword}
                        postUpdate={postUpdatePassword}
                        isValid={passwordIsValid}
                        successMessage={passwordSuccessMessage}
                        failMessage={passwordFailMessage}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

Settings.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.object.isRequired,
    profileIsLoading: PropTypes.bool.isRequired,
    updateDetail: PropTypes.func.isRequired,
};

export default Settings;
