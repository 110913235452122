import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import List from './List';
import LoadingList from './LoadingList';

const useStyles = makeStyles(() => ({
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const UsersTable = props => {
    const { 
        status,
        packages,
        list,
        resetData,
        loading,
        postDelete,
        postClose,
        postBringBack,
        postPackage,
        loadingMore,
        // showLoadingMore,
        // startIndex,
        // endIndex,
        // loadmoreFunc,
        // showlessFunc,
    } = props;

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Purpose</TableCell>
                                    <TableCell>Sell Price (THB)</TableCell>
                                    <TableCell>Rent Price (THB/Month)</TableCell>
                                    <TableCell>Bedroom</TableCell>
                                    {status === "promote" && <TableCell>Reachs</TableCell>}
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ?
                                    _.times(10, i =>
                                        <LoadingList status={status} key={i} />
                                    )
                                    :
                                    list.map(item => (
                                        <List
                                            data={item}
                                            key={item.uid}
                                            resetData={resetData}
                                            postDelete={postDelete}
                                            postClose={postClose}
                                            postBringBack={postBringBack}
                                            packages={packages}
                                            postPackage={postPackage}
                                        />
                                    ))
                                }
                                {
                                    loadingMore &&
                                    _.times(10, i =>
                                        <LoadingList key={i} status={status} />
                                    )
                                }
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
        </Card>
    );
};

export default UsersTable;
