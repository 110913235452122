import React from 'react';
import { makeStyles } from '@material-ui/styles';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
} from 'react-instantsearch-dom';
import { Toolbar, Table } from './components';

const searchClient = algoliasearch('SJS3S5FAVR', 'fbf789ef62160863f04f7f39da3e7fe7');

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
    }
}));

const UserList = (props) => {
    const classes = useStyles();
    const {
        loading,
        list,
        isSearching,
        getList,
        postDelete,
        resetData,
        querySearch,
        loadingMore,
        showLoadingMore,
        startIndex,
        endIndex,
        numberOfDocs,
        loadmore,
        showless,
        queryByIndex,
    } = props;

    const lastVisibleUid = list[list.length - 1] ? list[list.length - 1].uid : '';

    React.useEffect(() => {
        getList();
    }, [getList]);

    return (
        <div className={classes.root}>
            <InstantSearch searchClient={searchClient} indexName="developers">
                <Toolbar 
                    changeSearch={querySearch}
                    resetData={resetData}
                />
                <div className={classes.content}>
                    <Table
                        isSearching={isSearching} 
                        list={list} 
                        resetData={resetData}
                        loading={loading}
                        postDelete={postDelete}
                        loadingMore={loadingMore}
                        showLoadingMore={showLoadingMore}
                        startIndex={startIndex}
                        endIndex={endIndex}
                        numberOfDocs={numberOfDocs}
                        loadmoreFunc={() => loadmore(lastVisibleUid)}
                        showlessFunc={showless}
                        queryFunc={queryByIndex}
                    />
                </div>
            </InstantSearch>
        </div>
    );
};

export default UserList;
