import validate from 'validate.js';
import firebase from 'common/firebase';
import { showSnack } from './main';

const schema = {
    descTh: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
export const SET_LANG = 'SET_LANG';
export const RESET_TERM = 'RESET_TERM';
export const UPDATE_TERM = 'UPDATE_TERM';
export const FETCH_TERM = 'FETCH_TERM';
export const FETCH_TERM_DATA_DONE = 'FETCH_TERM_DATA_DONE';
export const FETCH_TERM_DONE = 'FETCH_TERM_DONE';
export const FETCH_TERM_FAIL = 'FETCH_TERM_FAIL';

// Sync Action Creator
export function changeLang(lang) {
    return {
        type: SET_LANG,
        lang
    };
};
export function reset() {
    return {
        type: RESET_TERM,
    };
};
export function fetch() {
    return {
        type: FETCH_TERM,
    };
};
export function fetchDataDone(data) {
    return {
        type: FETCH_TERM_DATA_DONE,
        data,
    };
};
export function fetchDone(message) {
    return {
        type: FETCH_TERM_DONE,
        message,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_TERM_FAIL,
        message,
    };
};
export function update(oldData, lang, fieldName, newValue) {
    oldData[lang] = newValue;
    
    const errors = validate({ descTh: oldData.th }, schema);

    return {
        type: UPDATE_TERM,
        lang,
        newValue,
        errors: errors,
    };
};

// Async Action Creator
export function getData(uid) {
    return async (dispatch) => {
        dispatch(fetch());

        const ref = firebase.firestore().collection("webData").doc(uid);

        try {
            const result = await ref.get();
            const data = result.data();
            dispatch(fetchDataDone(data));
        } catch (error) {
            console.log(error.message);
            dispatch(showSnack('fail', "No such document!"));
            dispatch(fetchFail("No such document!"));
        }
    };
}

export function postEdit(uid,data) {
    return function (dispatch) {
        dispatch(fetch());
        
        const ref = firebase.firestore().collection("webData").doc(uid);

        return ref.set(data, { merge: true }).then((docRef) => {
            dispatch(fetchDone('Updated successfully.'));
            dispatch(showSnack('success', 'Updated successfully.'));
        }).catch((error) => {
            console.log(error);
            dispatch(fetchFail(error.message));
        });
    };
}