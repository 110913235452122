import {
    SET_PROJECT_LANG,
    SET_PROJECT_TYPE,
    SET_PROJECT_DEVELOPER,
    SET_PROJECT_IMAGE,
    SET_PROJECT_GEO,
    UPLOAD_PROJECT_GALLERY,
    UPLOAD_PROJECT_GALLERY_DONE,
    REMOVE_PROJECT_GALLERY,
    UPDATE_PROJECT,
    UPDATE_PROJECT_NO_LANG,
    FETCH_PROJECT,
    FETCH_PROJECT_DATA_DONE,
    FETCH_PROJECT_DONE,
    FETCH_PROJECT_FAIL,
    RESET_PROJECT,
    FETCH_PROJECT_LIST_DONE,
    DELETE_PROJECT,
    UPDATE_PROJECT_SEARCH,
    FETCH_PROJECT_LOADMORE,
    FETCH_PROJECT_LOADMORE_DONE,
    FETCH_PROJECT_SHOWLESS,
    UPDATE_PROJECT_INDEX,
    FETCH_PROJECT_INDEX_DONE,
} from 'actions/project';

const initialState = {
    loading: false,
    lang: 'th',
    isSearching: false,
    list: [],
    loadingMore: false,
    showLoadingMore: true,
    startIndex: 1,
    endIndex: 25,
    numberOfDocs: 0,
    data: {
        search: '',
        title: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
        desc: {
            th: '',
            en: '',
            ch: '',
            jp: '',
        },
        img: '',
        typeId: '',
        devId: {},
        yearDone: '',
        unitNumber: '',
        gallery: [],
        location: {
            lat: 13.7563,
            lng: 100.5018,
        },
        createdBy: '',
        timestamp: '',
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_PROJECT:
            return {
                ...initialState,
                data: {
                    title: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                    desc: {
                        th: '',
                        en: '',
                        ch: '',
                        jp: '',
                    },
                    img: '',
                    typeId: '',
                    devId: {},
                    yearDone: '',
                    unitNumber: '',
                    gallery: [],
                    location: {
                        lat: 13.7563,
                        lng: 100.5018,
                    },
                    createdBy: '',
                    timestamp: '',
                },
            };
        case SET_PROJECT_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case SET_PROJECT_TYPE:
            return {
                ...state,
                data: {
                    ...state.data,
                    typeId: action.typeId,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case SET_PROJECT_DEVELOPER:
            return {
                ...state,
                data: {
                    ...state.data,
                    devId: action.devId,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case SET_PROJECT_IMAGE:
            return {
                ...state,
                data: {
                    ...state.data,
                    img: action.img,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case SET_PROJECT_GEO:
            return {
                ...state,
                data: {
                    ...state.data,
                    location: {
                        lat: action.lat,
                        lng: action.lng,
                    },
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case UPDATE_PROJECT_NO_LANG:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: action.newValue,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case UPDATE_PROJECT:
            if (action.lang === 'th' && action.fieldName === 'title') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        search: action.newValue,
                        title: {
                            ...state.data.title,
                            th: action.newValue,
                        },
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: {
                        ...state.data[action.fieldName],
                        [action.lang]: action.newValue,
                    },
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case UPDATE_PROJECT_SEARCH:
            return {
                ...state,
                isSearching: action.newValue !== '',
            };
        case FETCH_PROJECT:
            return {
                ...state,
                loading: true,
                loadingMore: false,
                showLoadingMore: true,
            };
        case FETCH_PROJECT_LOADMORE:
            return {
                ...state,
                loadingMore: true,
            };
        case FETCH_PROJECT_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_PROJECT_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_PROJECT_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        case FETCH_PROJECT_LIST_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                showLoadingMore: action.list.length < action.numberOfDocs,
                startIndex: 1,
                endIndex: action.list.length,
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_PROJECT_INDEX_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                showLoadingMore:
                    state.startIndex + action.list.length - 1 <
                    action.numberOfDocs,
                endIndex: state.startIndex + action.list.length - 1,
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_PROJECT_LOADMORE_DONE:
            return {
                ...state,
                loadingMore: false,
                endIndex: state.endIndex + action.list.length,
                showLoadingMore:
                    state.endIndex + action.list.length < state.numberOfDocs,
                list: [...state.list, ...action.list],
                numberOfDocs:
                    action.list.length < 25
                        ? state.endIndex + action.list.length
                        : action.numberOfDocs,
            };
        case FETCH_PROJECT_SHOWLESS:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: true,
                list: [...state.list.slice(0, state.list.length - 25)],
                endIndex: state.endIndex - 25,
            };
        case UPDATE_PROJECT_INDEX:
            return {
                ...state,
                startIndex: action.startIndex,
            };
        case DELETE_PROJECT:
            const index = state.list.findIndex(data => data.uid === action.uid);
            let newList = [...state.list];
            newList.splice(index, 1);
            return {
                ...state,
                list: newList,
            };
        case UPLOAD_PROJECT_GALLERY:
            let galList = [...state.data.gallery];
            galList.push('loading');
            return {
                ...state,
                data: {
                    ...state.data,
                    gallery: galList,
                },
            };
        case UPLOAD_PROJECT_GALLERY_DONE:
            let galList2 = [...state.data.gallery];
            galList2[action.index] = action.imgURL;
            return {
                ...state,
                data: {
                    ...state.data,
                    gallery: galList2,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case REMOVE_PROJECT_GALLERY:
            let galList3 = [...state.data.gallery];
            galList3.splice(action.index, 1);
            return {
                ...state,
                data: {
                    ...state.data,
                    gallery: galList3,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        default:
            return state;
    }
};
export default projectsReducer;
