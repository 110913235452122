import React, { forwardRef } from 'react';
import { NavLink as RouterLink, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardActions,
    Divider,
    Button,
    TextField,
} from '@material-ui/core';
import _ from 'lodash';
import TabLang from 'components/TabLang';

const useStyles = makeStyles((theme) => ({
    appbar: {
        boxShadow: 'none',
        backgroundColor: '#fff',
    },
    tab: {
        textTransform: 'none',
    },
    selected: {
        color: "#00abbd",
    },
    tabWrapper:{
        flexDirection: 'row',
    },
    detail:{
        padding: 16,
    },
    avatar: {
        marginRight: theme.spacing(1),
        width: 24,
        height: 24,
        backgroundColor: "#00abbd",
        display: 'inline-block',
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const AccountDetails = (props) => {
    const {
        uid,
        title,
        loading,
        lang,
        tag,
        isValid,
        successMessage,
        updateTag,
        postTagCreate,
        changeLang,
    } = props;
    const classes = useStyles();

    // Typing
    const handleChange = event => {
        updateTag(tag, lang, event.target.value);
    };

    // Submit Form
    const handleSave = () => {
        if (_.isEmpty(uid)) {
            postTagCreate(tag);
        }
        else{
            postTagCreate(uid,tag);
        }
    };

    if (!_.isEmpty(successMessage)) {
        return (
            <Redirect to="/tags/all" />
        );
    }

    return (
        <Card className={classes.root}>
            <form
                autoComplete="off"
                noValidate
            >
                <CardHeader
                    title={title}
                />
                <Divider />
                <TabLang
                    lang={lang}
                    changeLang={changeLang}
                    thActive={!_.isEmpty(tag.name.th)}
                    thNode={
                        <>
                            <TextField
                                key={`th-name-${lang === 'th'}`}
                                fullWidth
                                label="Name"
                                margin="normal"
                                name="name"
                                onChange={handleChange}
                                required
                                value={tag.name.th}
                                variant="outlined"
                                disabled={loading}
                            />
                        </>
                    }
                    enActive={!_.isEmpty(tag.name.en)}
                    enNode={
                        <>
                            <TextField
                                key={`en-name-${lang === 'en'}`}
                                fullWidth
                                label="Name"
                                margin="normal"
                                name="name"
                                onChange={handleChange}
                                value={tag.name.en}
                                variant="outlined"
                                disabled={loading}
                            />
                        </>
                    }
                    chActive={!_.isEmpty(tag.name.ch)}
                    chNode={
                        <>
                            <TextField
                                key={`ch-name-${lang === 'ch'}`}
                                fullWidth
                                label="Name"
                                margin="normal"
                                name="name"
                                onChange={handleChange}
                                value={tag.name.ch}
                                variant="outlined"
                                disabled={loading}
                            />
                        </>
                    }

                    jpActive={!_.isEmpty(tag.name.jp)}
                    jpNode={
                        <>
                            <TextField
                                key={`jp-name-${lang === 'jp'}`}
                                fullWidth
                                label="Name"
                                margin="normal"
                                name="name"
                                onChange={handleChange}
                                value={tag.name.jp}
                                variant="outlined"
                                disabled={loading}
                            />
                        </>
                    }
                />
                <Divider />
                <CardActions style={{padding: 16}}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!isValid}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <Button
                        color="default"
                        variant="outlined"
                        component={CustomRouterLink}
                        to='/tags/all'
                    >
                        Cancel
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

export default AccountDetails;
