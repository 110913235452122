import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import Loadmore from 'components/Loadmore';
import List from './List';
import LoadingList from './LoadingList';

const useStyles = makeStyles(() => ({
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const UsersTable = props => {
    const classes = useStyles();
    const { 
        className, 
        list, 
        resetAds,
        loading,
        postDeleteAds,
        toggleStatus,
        loadingMore,
        showLoadingMore,
        startIndex,
        endIndex,
        numberOfDocs,
        loadmoreFunc,
        showlessFunc,
        queryFunc,
    } = props;

    const groupedList = _.groupBy(list, 'active');
    const activeList = groupedList['true'] ? _.orderBy(groupedList['true'], ['position', 'timestamp'], ['acs', 'desc']) : [];
    const noneActiveList = groupedList['false'] ? groupedList['false'] : [];
    const newList = _.concat(activeList, noneActiveList);
    
    // handle hide toggle button
    const positionAll = _.groupBy(activeList, 'position');
    let hideThisPosition = [];
    _.forEach(positionAll, function (eachPosition, key) {
        if (key === 'popup' && eachPosition && eachPosition.length >= 1) {
            hideThisPosition.push(key);
        } else if (key !== 'popup' && eachPosition && eachPosition.length >= 3){
            hideThisPosition.push(key);
        }
    });

    return (
        <Card className={clsx(classes.root, className)}>
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Position</TableCell>
                                    <TableCell>Desktop</TableCell>
                                    <TableCell>Mobile</TableCell>
                                    <TableCell>Seens</TableCell>
                                    <TableCell>Unique Seens</TableCell>
                                    <TableCell>Clicks</TableCell>
                                    <TableCell>Unique Clicks</TableCell>
                                    <TableCell>Show/Hide</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ?
                                    _.times(10, i =>
                                        <LoadingList key={i} />
                                    )
                                    :
                                    newList.map(item => (
                                        <List 
                                            list={item} 
                                            key={item.uid} 
                                            resetAds={resetAds} 
                                            postDeleteAds={postDeleteAds} 
                                            toggleStatus={toggleStatus}
                                            selected={!item.active}
                                            hideToggle={!item.active && _.includes(hideThisPosition, item.position)}
                                        />
                                    ))
                                }
                                {
                                    loadingMore &&
                                    _.times(10, i =>
                                        <LoadingList key={i} />
                                    )
                                }
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            {(!loading && !loadingMore) &&
                <Loadmore
                    startIndex={startIndex}
                    endIndex={endIndex}
                    listLength={numberOfDocs}
                    loadmoreFunc={loadmoreFunc}
                    showlessFunc={showlessFunc}
                    queryFunc={queryFunc}
                    showlessDisabled={list.length <= 25}
                    showLoadingMore={showLoadingMore}
                />
            }
        </Card>
    );
};

UsersTable.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
};

export default UsersTable;
