import validate from 'validate.js';
// import uuidv1 from 'uuid/v1';
import _ from 'lodash';
import moment from 'moment';
import firebase from 'common/firebase';
import { showSnack } from './main';
import { updateAuthInboxNoti } from './auth';

const functions = firebase.app().functions('asia-east2');

const schema = {
    text: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
// -- CREATE/UPDATE
export const RESET_INBOX = 'RESET_INBOX';
export const UPDATE_INBOX = 'UPDATE_INBOX';
export const FETCH_INBOX = 'FETCH_INBOX';
export const FETCH_INBOX_DONE = 'FETCH_INBOX_DONE';
export const FETCH_INBOX_FAIL = 'FETCH_INBOX_FAIL';
export const FETCH_CHAT = 'FETCH_CHAT';
export const FETCH_CHAT_DONE = 'FETCH_CHAT_DONE';
export const FETCH_CHAT_FAIL = 'FETCH_CHAT_FAIL';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const FETCH_CHAT_MESSAGE = 'FETCH_CHAT_MESSAGE';
export const FETCH_CHAT_MESSAGE_DONE = 'FETCH_CHAT_MESSAGE_DONE';
export const UPDATE_INBOX_SEEN = 'UPDATE_INBOX_SEEN';

// Sync Action Creator
// -- Create/Update --
export function updateChat(newValue) {
    const validateData = {
        text: newValue,
    };

    const errors = validate(validateData, schema);
    return {
        type: UPDATE_CHAT,
        newValue,
        errors,
    };
};

export function resetData() {
    return {
        type: RESET_INBOX,
    };
};
export function updateInbox(img, name, listenerId, inboxId) {
    const creator = firebase.auth().currentUser;
    const { uid } = creator;
    return {
        type: UPDATE_INBOX,
        img,
        name,
        listenerId,
        inboxId,
        uid,
    };
};
export function fetch() {
    return {
        type: FETCH_INBOX,
    };
};
export function fetchDone(list) {
    return {
        type: FETCH_INBOX_DONE,
        list,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_INBOX_FAIL,
        message,
    };
};
export function fetchChat() {
    return {
        type: FETCH_CHAT,
    };
};
export function fetchChatDone(messages) {
    return {
        type: FETCH_CHAT_DONE,
        messages,
    };
};
export function updateInboxSeen(inboxId) {
    return {
        type: UPDATE_INBOX_SEEN,
        inboxId,
    };
};
export function fetchChatFail(message) {
    return {
        type: FETCH_CHAT_FAIL,
        message,
    };
};
export function fetchMessage() {
    return {
        type: FETCH_CHAT_MESSAGE,
    };
};
export function fetchMessageDone(data) {
    return {
        type: FETCH_CHAT_MESSAGE_DONE,
        data,
    };
};

// Async Action Creator
export function getMyInbox() {
    return async (dispatch) => {
        dispatch(fetch());

        const uid = 'admin';
        const getAPI = functions.httpsCallable('getMyInbox');
        const seenRef = firebase.firestore().collection("inboxSeen").where('userId', '==', uid).where('value', '==', false);
        try {
            const result = await getAPI(uid);
            const listArr = result.data;
            
            // add not seen noti
            const seenSnap = await seenRef.get();

            seenSnap.forEach((doc)=>{
                const seen = doc.data();
                const lastIndex = _.findLastIndex(listArr, (o) => { return o.uid === seen.inboxId; });
                listArr[lastIndex].seen = seen ? seen.value : true;
            });

            dispatch(fetchDone(listArr));
        } catch (error){
            dispatch(showSnack('warning', error.message));
            dispatch(fetchFail(error.message));
        }
        return true;
    };
};
export function getChat(inboxId) {
    return async (dispatch) => {
        dispatch(fetchChat());
        const adminId = 'admin';
        const getAPI = functions.httpsCallable('getChat');
        const inboxSeenRef = firebase.firestore().collection("inboxSeen");
        const userRef = firebase.firestore().collection("users");

        try {
            // update inboxSeen
            const seenQuery = await inboxSeenRef.where('inboxId', '==', inboxId).where('userId', '==', adminId).get();
            if (seenQuery.docs.length > 0) {
                const seenDoc = seenQuery.docs[0];
                await inboxSeenRef.doc(seenDoc.id).set({ value: true }, { merge: true });
            }
            // check all noti and update inboxNoti
            const inboxNotSeenQuery = await inboxSeenRef.where('userId', '==', adminId).where('value', '==', false).get();
            if (inboxNotSeenQuery.docs.length === 0){
                await userRef.doc(adminId).set({ inboxNoti: false }, { merge: true });
                dispatch(updateAuthInboxNoti(false));
            }

            const result = await getAPI(inboxId);
            const listArr = result.data;
            const userIdArr = _.keys(_.groupBy(listArr, 'createdBy'));

            _.map(userIdArr, (userId) => {
                const lastIndex = _.findLastIndex(listArr,(o) => { return o.createdBy === userId; });
                listArr[lastIndex].isLast = true;
            });

            dispatch(fetchChatDone(listArr));
        } catch (error) {
            console.log(error.message);
            dispatch(fetchChatFail(error.message));
        }
    };
};
export function postChat(inboxId, message, messageArr, listenerId) {
    return (dispatch) => {
        dispatch(fetchMessage());
        const adminId = 'admin';
        const inputData = {
            message,
            createdBy: adminId,
            timestamp: moment().valueOf(),
        };

        // add chat
        const docRef = firebase.firestore().collection("inboxSeen").where('inboxId', '==', inboxId).where('userId', '==', listenerId);
        const inboxRef = firebase.firestore().collection("inbox").doc(inboxId);
        const userRef = firebase.firestore().collection("users").doc(listenerId);
        return inboxRef.collection("chat").add(inputData).then(async () => {
            // update not seen to listener profile
            await userRef.set({ inboxNoti: true }, { merge: true });
            // update inbox message and timestamp
            await inboxRef.set({message, updatedAt: inputData.timestamp}, { merge: true });
            // update inboxSeen value
            const docQuery = await docRef.get();
            if (docQuery.docs.length > 0){
                // update not seen to listener inbox
                const seenDoc = docQuery.docs[0];
                await firebase.firestore().collection("inboxSeen").doc(seenDoc.id).set({ value: false }, { merge: true });
            }else{
                // add not seen to listener inbox
                await firebase.firestore().collection("inboxSeen").add({
                    value: false,
                    inboxId,
                    userId: listenerId,
                });
            }

            const messageList = messageArr;
            inputData.uid = moment().valueOf();
            inputData.timestamp = moment(inputData.timestamp).format('D/MM/YY HH:mm');
            messageList.push(inputData);
            _.map(messageList, (messageData) => {
                const messageObj = messageData;
                messageObj.isLast = false;
            });

            const userIdArr = _.keys(_.groupBy(messageArr, 'createdBy'));

            _.map(userIdArr, (userId) => {
                const lastIndex = _.findLastIndex(messageArr, (o) => { return o.createdBy === userId; });
                messageList[lastIndex].isLast = true;
            });

            dispatch(fetchMessageDone(messageArr));
        }).catch((error) => {
            dispatch(showSnack('warning', error.message));
        });
    };
};