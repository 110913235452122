import {
    SET_TOPIC_TAG,
    REMOVE_TOPIC_TAG,
    UPDATE_TOPIC,
    FETCH_TOPIC,
    FETCH_TOPIC_DATA_DONE,
    FETCH_TOPIC_DONE,
    FETCH_TOPIC_FAIL,
    RESET_TOPIC,
    FETCH_TOPIC_LIST_DONE,
    DELETE_TOPIC,
    CHANGE_TOPIC_PIN,
    CHANGE_TOPIC_HIDE,
    UPDATE_TOPIC_SEARCH,
    FETCH_TOPIC_LOADMORE,
    FETCH_TOPIC_LOADMORE_DONE,
    FETCH_TOPIC_SHOWLESS,
    UPDATE_TOPIC_INDEX,
    FETCH_TOPIC_INDEX_DONE,
} from 'actions/webboard';

const initialState = {
    loading: false,
    isSearching: false,
    list: [],
    loadingMore: false,
    showLoadingMore: true,
    startIndex: 1,
    endIndex: 25,
    numberOfDocs: 0,
    data: {
        title: '',
        desc: '',
        tagId: [],
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const locationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOPIC_TAG:
            return {
                ...state,
                data: {
                    ...state.data,
                    tagId: action.tags,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case REMOVE_TOPIC_TAG: {
            const tagList = [...state.data.tagId];
            tagList.splice(action.index, 1);
            return {
                ...state,
                data: {
                    ...state.data,
                    tagId: tagList,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        }
        case RESET_TOPIC:
            return {
                ...initialState,
                data: {
                    title: '',
                    desc: '',
                    tagId: [],
                    accept: true,
                    writer: {
                        name: '',
                        img: '',
                    },
                    date: '',
                    view: 0,
                    comment: 0,
                },
            };
        case UPDATE_TOPIC:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: action.newValue,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case CHANGE_TOPIC_PIN:
            const index2 = state.list.findIndex(data => data.uid === action.uid);
            let newList2 = [...state.list];
            newList2[index2] = {
                ...newList2[index2],
                isPin: action.newStatus
            };
            return {
                ...state,
                list: newList2,
            };
        case CHANGE_TOPIC_HIDE:
            const index3 = state.list.findIndex(data => data.uid === action.uid);
            let newList3 = [...state.list];
            newList3[index3] = {
                ...newList3[index3],
                isHide: action.newStatus
            };
            return {
                ...state,
                list: newList3,
            };
        case UPDATE_TOPIC_SEARCH:
            return {
                ...state,
                isSearching: action.newValue !== '',
            };
        case FETCH_TOPIC:
            return {
                ...state,
                loading: true,
                loadingMore: false,
                showLoadingMore: true,
            };
        case FETCH_TOPIC_LOADMORE:
            return {
                ...state,
                loadingMore: true,
            };
        case FETCH_TOPIC_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_TOPIC_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_TOPIC_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        case FETCH_TOPIC_LIST_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                showLoadingMore: action.list.length < action.numberOfDocs,
                startIndex: 1,
                endIndex: action.list.length,
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_TOPIC_INDEX_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
                loadingMore: false,
                showLoadingMore: (state.startIndex + action.list.length - 1) < action.numberOfDocs,
                endIndex: (state.startIndex + action.list.length - 1),
                numberOfDocs: action.numberOfDocs,
            };
        case FETCH_TOPIC_LOADMORE_DONE:
            return {
                ...state,
                loadingMore: false,
                endIndex: (state.endIndex + action.list.length),
                showLoadingMore: (state.endIndex + action.list.length) < state.numberOfDocs,
                list: [
                    ...state.list,
                    ...action.list,
                ],
                numberOfDocs: action.list.length < 25 ? state.endIndex + action.list.length : action.numberOfDocs,
            };
        case FETCH_TOPIC_SHOWLESS:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: true,
                list: [
                    ...state.list.slice(0, state.list.length - 25),
                ],
                endIndex: state.endIndex - 25,
            };
        case UPDATE_TOPIC_INDEX:
            return {
                ...state,
                startIndex: action.startIndex,
            };
        case DELETE_TOPIC:
            const index = state.list.findIndex(data => data.uid === action.uid);
            let newList = [...state.list];
            newList.splice(index, 1);
            return {
                ...state,
                list: newList
            };
        default:
            return state;
    }
};
export default locationsReducer;
