import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: 60,
        height: 60,
        textTransform: 'uppercase',
    },
    name: {
        marginTop: theme.spacing(1)
    }
}));

const Profile = props => {
    const { className, user,...rest } = props;

    const classes = useStyles();

    const userData = {
        name: user.name,
        avatar: user.profile,
    };

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            {
                (userData.avatar === "" || userData.avatar === null) ?
                    <Avatar
                        color="primary"
                        className={classes.avatar}
                        component={RouterLink}
                        src={userData.avatar}
                        to="/settings"
                        style={{ backgroundColor: "#00abbd" }}
                    >
                        {userData.name && userData.name.charAt(0)}
                    </Avatar>
                    :
                    <Avatar
                        alt="Person"
                        className={classes.avatar}
                        component={RouterLink}
                        src={userData.avatar}
                        to="/settings"
                    />
            }
            <Typography
                className={classes.name}
                variant="h5"
            >
                {userData.name}
            </Typography>
        </div>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
