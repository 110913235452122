import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import _ from 'lodash';

// language
import TH from 'common/lang/th.svg';
import EN from 'common/lang/en.svg';
import CH from 'common/lang/ch.svg';
import JP from 'common/lang/jp.svg';

const useStyles = makeStyles(theme => ({
    root: {
        left: -12,
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2),
        width: 24,
        height: 24,
        textTransform: 'uppercase',
        backgroundColor: "#00abbd",
        display: 'inline-block',
        border: '1px solid #dddddd',
        boxSizing: 'content-box',
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const UsersTableList = props => {
    const classes = useStyles();
    const langList = {
        th: <Avatar key="th" className={classes.avatar} src={TH} />,
        en: <Avatar key="en" className={classes.avatar} src={EN} />,
        ch: <Avatar key="ch" className={classes.avatar} src={CH} />,
        jp: <Avatar key="jp" className={classes.avatar} src={JP} />,
    }
    const { 
        data,
        resetData,
        postDelete,
    } = props;

    const activeLang = _.keys(_.pickBy(data.name));

    const [state, setState] = React.useState({
        anchorEl: null,
        openDialog: false,
    });


    function handleClickOpenDialog() {
        setState({
            ...state,
            anchorEl: null,
            openDialog: true
        });
    }

    function handleCloseDialog() {
        setState({
            ...state,
            openDialog: false,
        });
    }
    function handleDelete() {
        postDelete(data.uid);
        setState({
            ...state,
            openDialog: false,
        });
    }

    // Menu
    function handleClick(event) {
        setState({
            ...state, 
            anchorEl: event.currentTarget
        });
    }
    function handleClose() {
        setState({
            ...state,
            anchorEl: null
        });
    }
    
    return (
        <TableRow
            className={classes.tableRow}
            hover
        >
            <TableCell>
                {data.createdAt}
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <Typography variant="body1">{data.name.th}</Typography>
                </div>
            </TableCell>
            <TableCell>
                {
                    activeLang.map((lang) => (
                        langList[lang]
                    ))
                }
            </TableCell>
            <TableCell>
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                {/* -- Menu -- */}
                <Menu
                    id="simple-menu"
                    anchorEl={state.anchorEl}
                    keepMounted
                    open={Boolean(state.anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem 
                        component={CustomRouterLink}
                        to={`/developers/edit/${data.uid}`}
                        onClick={() => resetData()}
                    >
                        Edit
                    </MenuItem>
                    <MenuItem onClick={handleClickOpenDialog}>Delete</MenuItem>
                </Menu>
                {/* -- Dialog -- */}
                <Dialog
                    open={state.openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" disableTypography>
                        <Typography variant="h4">Delete Developer</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete developer?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{padding: 16}}>
                        <Button onClick={handleDelete} color="primary" >
                            Delete
                        </Button>
                        <Button onClick={handleCloseDialog} color="primary" autoFocus>
                            No Thank
                        </Button>
                    </DialogActions>
                </Dialog>
            </TableCell>
        </TableRow>             
    );
};

export default UsersTableList;
