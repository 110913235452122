import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import ImageUpload from './image';
import Upload from './upload';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
}));

const ImageField = props => {
    const classes = useStyles();
    const {
        list,
        oldData,
        postGallery,
        removeGallery,
    } = props;

    return (
        <Box className={classes.root}>
            {
                list.map((item, index) => (
                    <ImageUpload
                        key={index}
                        first={index === 0}
                        loading={item === 'loading'}
                        url={item}
                        remove={() => removeGallery(index, oldData)}
                    />
                ))
            }
            {
                list.length < 10 && 
                    <Upload
                        postGallery={postGallery}
                        index={list.length}
                        oldData={oldData}
                    />
            }
        </Box>
    );
};

export default ImageField;
