import { connect } from 'react-redux';
import {
    changeType,
    changeProject,
    changeGeo,
    update,
    postEdit,
    postGallery,
    removeGallery,
    getEdit,
} from 'actions/property';

import ProjectEdit from 'views/Property/Edit';

const mapStateToProps = state => ({
    loading: state.property.loading,
    data: state.property.data,
    isValid: state.property.isValid,
    successMessage: state.property.successMessage,
});

const mapDispatchToProps = {
    changeType,
    changeProject,
    changeGeo,
    update,
    postEdit,
    postGallery,
    removeGallery,
    getEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectEdit);

