import { connect } from 'react-redux';
import { 
    updateUserCreate,
    postUserCreate, 
    postProfile, 
    removeProfile,
} from 'actions/userCreate';

import UserCreate from 'views/User/Create';

const mapStateToProps = state => ({
    loading: state.userCreate.loading,
    user: state.userCreate.user,
    userErrors: state.userCreate.userErrors,
    userIsValid: state.userCreate.userIsValid,
    userTouched: state.userCreate.userTouched,
    userSuccessMessage: state.userCreate.userSuccessMessage,
    userFailMessage: state.userCreate.userFailMessage,
    profileIsLoading: state.userCreate.profileIsLoading,

});

const mapDispatchToProps = {
    updateUserCreate,
    postUserCreate,
    postProfile,
    removeProfile,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserCreate);

