import React from 'react';
import { withRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    TextField,
    Typography,
    Box,
    CircularProgress,
} from '@material-ui/core';
import _ from 'lodash';
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    inputForm: {
        position: 'relative',
    },
    progress: {
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
    },
    title: {
        marginTop: theme.spacing(3)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signUpButton: {
        margin: theme.spacing(2, 0)
    }
}));

const ResetPassword = props => {
    const classes = useStyles();
    const { 
        location,
        isValid,
        loading,
        data,
        errors,
        touched,
        successMessage,
        failMessage,
        updatePassword,
        postResetPassword,
    } = props;
    
    const params = queryString.parse(location.search);

    const handleChange = event => {
        event.persist();
        updatePassword(data, event.target.name, event.target.value);
    };

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)) {
            return touched[field] && errors[field] ? true : false;
        }
        return false;
    };

    const handleSignIn = () => {
        postResetPassword(data, params.oobCode, params.continueUrl, params.lang);
    };

    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <form className={classes.form}>
                    <Typography
                        className={classes.title}
                        variant="h2"
                    >
                        Reset Password
                    </Typography>
                    <div className={classes.inputForm}>
                        {loading && <CircularProgress className={classes.progress} />}
                        <TextField
                            className={classes.textField}
                            error={hasError('password')}
                            fullWidth
                            helperText={
                                hasError('password') ? errors.password[0] : null
                            }
                            label="Password"
                            name="password"
                            onChange={handleChange}
                            type="password"
                            value={data.password}
                            variant="outlined"
                            disabled={loading}
                        />
                        <TextField
                            className={classes.textField}
                            error={hasError('confirmPassword')}
                            fullWidth
                            helperText={
                                hasError('confirmPassword') ? errors.confirmPassword[0] : null
                            }
                            label="Confirm Password"
                            name="confirmPassword"
                            onChange={handleChange}
                            type="password"
                            value={data.confirmPassword}
                            variant="outlined"
                            disabled={loading}
                        />
                        {
                            failMessage !== '' &&
                            <Box mt={2}>
                                <Typography
                                    style={{ color: "#de0032" }}
                                    variant="caption"
                                >
                                    {failMessage}
                                </Typography>
                            </Box>
                        }
                        {
                            successMessage !== '' &&
                            <Box mt={2}>
                                <Typography
                                    color="primary"
                                    variant="caption"
                                >
                                    {successMessage}
                                </Typography>
                            </Box>
                        }
                        <Button
                            className={classes.signUpButton}
                            color="primary"
                            disabled={!isValid || loading}
                            fullWidth
                            size="large"
                            type="button"
                            variant="contained"
                            onClick={handleSignIn}
                        >
                            Sign in
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default withRouter(ResetPassword);
