import React, { forwardRef } from 'react';
import { NavLink as RouterLink, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardActions,
    Divider,
    Button,
    TextField,
    Typography,
    Box,
} from '@material-ui/core';
import _ from 'lodash';
import Category from 'components/Category';
import Map from 'components/Map';
import TabLang from 'components/TabLang';

const useStyles = makeStyles(() => ({
    // avatar: {
    //     marginRight: theme.spacing(1),
    //     width: 24,
    //     height: 24,
    //     backgroundColor: "#00abbd",
    //     display: 'inline-block',
    // },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const AccountDetails = (props) => {
    const classes = useStyles();
    const {
        uid,
        title,
        loading,
        lang,
        data,
        isValid,
        successMessage,
        changeLang,
        changeCategory,
        changeGeo,
        update,
        postCreate,
    } = props;

    // Typing
    const handleChange = event => {
        update(data, lang, event.target.name, event.target.value);
    };

    const handleSave = () => {
        if (_.isEmpty(uid)) {
            postCreate(data);
        }
        else {
            postCreate(uid, data);
        }
    };

    if (!_.isEmpty(successMessage)) {
        return (
            <Redirect to="/locations/" />
        );
    }

    return (
        <Card className={classes.root}>
            <form
                autoComplete="off"
                noValidate
            >
                <CardHeader
                    title={title}
                />
                <Divider />
                <Box p={2}>
                    <Box mb={2}>
                        <Category
                            loading={loading}
                            category={data.categoryId}
                            changeValue={(category) => changeCategory(category, data)}
                        />
                    </Box>
                    <Box mb={2}>
                        <Typography variant="body1">Choose Location :</Typography>
                    </Box>
                    <Map 
                        loading={loading}
                        category={data.categoryId}
                        lat={data.lat}
                        lng={data.lng}
                        changeGeo={(lat, lng) => changeGeo(lat, lng, data)}
                    />
                </Box>
                <Divider />
                <TabLang 
                    lang={lang}
                    changeLang={changeLang}
                    thActive={!_.isEmpty(data.name.th) || !_.isEmpty(data.desc.th)}
                    enActive={!_.isEmpty(data.name.en) || !_.isEmpty(data.desc.en)}
                    chActive={!_.isEmpty(data.name.ch) || !_.isEmpty(data.desc.ch)}
                    jpActive={!_.isEmpty(data.name.jp) || !_.isEmpty(data.desc.jp)}
                    thNode={
                        <>
                            <TextField
                                key={`th-name-${lang === 'th'}`}
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="name"
                                onChange={handleChange}
                                required
                                disabled={loading}
                                value={data.name.th}
                                variant="outlined"
                            />
                            <TextField
                                key={`th-desc-${lang === 'th'}`}
                                fullWidth
                                label="Description"
                                margin="dense"
                                name="desc"
                                onChange={handleChange}
                                required
                                disabled={loading}
                                value={data.desc.th}
                                variant="outlined"
                            />
                        </>
                    }
                    enNode={
                        <>
                            <TextField
                                key={`en-name-${lang === 'en'}`}
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="name"
                                onChange={handleChange}
                                value={data.name.en}
                                variant="outlined"
                                disabled={loading}
                            />
                            <TextField
                                key={`en-desc-${lang === 'en'}`}
                                fullWidth
                                label="Description"
                                margin="dense"
                                name="desc"
                                id="desc"
                                onChange={handleChange}
                                value={data.desc.en}
                                variant="outlined"
                                disabled={loading}
                            />
                        </>
                    }
                    chNode={
                        <>
                            <TextField
                                key={`ch-name-${lang === 'ch'}`}
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="name"
                                onChange={handleChange}
                                value={data.name.ch}
                                variant="outlined"
                                disabled={loading}
                            />
                            <TextField
                                key={`ch-desc-${lang === 'ch'}`}
                                fullWidth
                                label="Description"
                                margin="dense"
                                name="desc"
                                onChange={handleChange}
                                value={data.desc.ch}
                                variant="outlined"
                                disabled={loading}
                            />
                        </>
                    }
                    jpNode={
                        <>
                            <TextField
                                key={`jp-name-${lang === 'jp'}`}
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="name"
                                onChange={handleChange}
                                value={data.name.jp}
                                variant="outlined"
                                disabled={loading}
                            />
                            <TextField
                                key={`jp-desc-${lang === 'jp'}`}
                                fullWidth
                                label="Description"
                                margin="dense"
                                name="desc"
                                onChange={handleChange}
                                value={data.desc.jp}
                                variant="outlined"
                                disabled={loading}
                            />
                        </>
                    }
                />
                <Divider />
                <CardActions style={{padding: 16}}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSave}
                        disabled={!isValid}
                    >
                        Save
                    </Button>
                    <Button
                        color="default"
                        variant="outlined"
                        component={CustomRouterLink}
                        to='/locations/all'
                    >
                        Cancel
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

AccountDetails.propTypes = {
    title: PropTypes.string.isRequired,
};

export default AccountDetails;
