/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import algoliasearch from 'algoliasearch/lite';
import { 
    connectAutoComplete, 
    InstantSearch,
} from 'react-instantsearch-dom';
import _ from 'lodash';

const searchClient = algoliasearch(
    'SJS3S5FAVR',
    'fbf789ef62160863f04f7f39da3e7fe7'
);

const MyAutocomplete = ({ loading, update, selectedValue, hits, refine }) => {

    const unselected = _(hits).differenceBy(selectedValue, 'uid').map(
        _.partial(_.pick, _, 'uid', 'nameTh', 'nameEn', 'nameCh', 'nameJp')
    ).value();

    console.log(selectedValue);

    return (
        <Autocomplete
            noOptionsText="No suggestions"
            loading={loading}
            options={unselected}
            getOptionLabel={option => option.nameTh ? option.nameTh : ''}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Choose Article"
                    margin="normal"
                    fullWidth
                    disabled={loading}
                    onChange={(event) => {
                        refine(event.currentTarget.value);
                    }}
                />
            )}
            value={selectedValue ? selectedValue : ''}
            onChange={(event, value) => {
                update(value);
            }}
        />
    );
};
const ConnectedAutocomplete = connectAutoComplete(MyAutocomplete);

function ArticleField(props) {
    
    const {
        loading,
        update,
        selectedValue,
    } = props;

    // console.log(selectedValue);

    return (
        <InstantSearch indexName="articles" searchClient={searchClient}>
            <ConnectedAutocomplete
                loading={loading}
                update={update}
                selectedValue={selectedValue}
            />
        </InstantSearch>
    );
}

ArticleField.propTypes = {
    loading: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
};

MyAutocomplete.propTypes = {
    loading: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
    hits: PropTypes.array.isRequired,
    refine: PropTypes.func.isRequired,
};

export default ArticleField;