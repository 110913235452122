import React from 'react';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';
import UsersToolbar from './components/Toolbar';
import UsersTable from './components/Table';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    progress: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 'auto',
    },
}));

const UserList = (props) => {
    const classes = useStyles();
    const { loading, userList, getUserList, resetUserCreate, toggleStatus, postDeleteUser } = props;
    _.forEach(userList, function (user) {
        user.createdAt = Number(moment(user.createdAt).format("x"));
    });    
    const userListSorted = _.orderBy(userList, ['createdAt'], ['desc']);

    React.useEffect(() => {
        getUserList();
    }, [getUserList]);

    return (
        <div className={classes.root}>
            <UsersToolbar resetUserCreate={resetUserCreate} />
            <div className={classes.content}>
                <UsersTable 
                    users={userListSorted} 
                    loading={loading} 
                    toggleStatus={toggleStatus} 
                    postDeleteUser={postDeleteUser}
                />
            </div>
        </div>
    );
};

export default UserList;
