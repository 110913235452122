import { connect } from 'react-redux';
import {
    getList,
    postDelete,
    resetData,
    querySearch,
    toggleStatus,
    loadmore,
    showless,
    queryByIndex,
} from 'actions/articles';
import TagList from 'views/Article/Publish';

const mapStateToProps = state => ({
    loading: state.articles.loading,
    list: state.articles.list,
    isSearching: state.articles.isSearching,
    loadingMore: state.articles.loadingMore,
    showLoadingMore: state.articles.showLoadingMore,
    startIndex: state.articles.startIndex,
    endIndex: state.articles.endIndex,
    numberOfDocs: state.articles.numberOfDocs,
});

const mapDispatchToProps = {
    getList,
    postDelete,
    resetData,
    querySearch,
    toggleStatus,
    loadmore,
    showless,
    queryByIndex,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagList);