import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    Divider,
    Button,
    Box,
    Grid,
} from '@material-ui/core';
import _ from 'lodash';
import TabLang from 'components/TabLang';
import Editor from 'components/Editor';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
        marginRight: 16,
    },
}));

const AccountDetails = (props) => {
    const classes = useStyles();
    const {
        uid,
        lang,
        loading,
        data,
        isValid,
        update,
        postEdit,
        changeLang,
    } = props;

    // Submit Form
    const handleSave = () => {
        postEdit(uid, data);
    };

    return (
        <Card className={classes.root}>
            <TabLang
                lang={lang}
                changeLang={changeLang}
                thActive={!_.isEmpty(_.get(data, 'th'))}
                thNode={
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <Box mt={1}>
                                <Editor
                                    oldData={data}
                                    lang="th"
                                    name="th"
                                    onChange={update}
                                    value={_.get(data, 'th', '')}
                                    disabled={loading}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                }

                enActive={!_.isEmpty(_.get(data, 'en'))}
                enNode={
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <Box mt={1}>
                                <Editor
                                    oldData={data}
                                    lang="en"
                                    name="en"
                                    onChange={update}
                                    value={_.get(data, 'en', '')}
                                    disabled={loading}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                }

                chActive={!_.isEmpty(_.get(data, 'ch'))}
                chNode={
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <Box mt={1}>
                                <Editor
                                    oldData={data}
                                    lang="ch"
                                    name="ch"
                                    onChange={update}
                                    value={_.get(data, 'ch', '')}
                                    disabled={loading}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                }

                jpActive={!_.isEmpty(_.get(data, 'jp'))}
                jpNode={
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <Box mt={1}>
                                <Editor
                                    oldData={data}
                                    lang="jp"
                                    name="jp"
                                    onChange={update}
                                    value={_.get(data, 'jp')}
                                    disabled={loading}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                }
            />
            <Divider />
            <CardActions style={{ padding: 16 }}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </CardActions>
        </Card>
    );
};

export default AccountDetails;