import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    primary: {
        light: "#e4f8fa",
        main: "#00abbd",
        dark: "#009cac",
        contrastText: white,
    },
    secondary: {
        contrastText: white,
        light: "#f8b9a9",
        main: "#f06642",
        dark: "#c55437",
    },
    success: {
        contrastText: white,
        light: "#f8b9a9",
        main: "#f06642",
        dark: "#c55437",
    },
    info: {
        contrastText: white,
        dark:  "#2e2e2e",
        main: "#666666",
        light: "#c7c7c7"
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400]
    },
    error: {
        contrastText: white,
        dark: "#B60029",
        main: "#de0032",
        light: "#ed738f"
    },
    text: {
        primary: "#2e2e2e",
        secondary: "#666666",
        disabled: "#c7c7c7",
        hint: "#e9e9e9",
        link: "#00abbd"
    },
    background: {
        default: "#f9f9f9",
        paper: white
    },
    icon: "#666666",
    divider: colors.grey[200]
};
