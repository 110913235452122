import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import moment from 'moment';
import firebase from 'common/firebase';
import Geocode from 'react-geocode';
import { showSnack } from './main';

const functions = firebase.app().functions('asia-east2');
Geocode.setApiKey('AIzaSyCoKTlqbwnFRJqTgwMVxV2I2muPaluuUnY');

const schema = {
    type: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    projectId: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    lat: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    lng: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    title: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    purpose: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    sellPrice: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    rentPrice: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    areaSize: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    gallery: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
// -- CREATE/UPDATE
export const SET_PROPERTY_TYPE = 'SET_PROPERTY_TYPE';
export const SET_PROPERTY_PROJECT = 'SET_PROPERTY_PROJECT';
export const SET_PROPERTY_GEO = 'SET_PROPERTY_GEO';
export const UPLOAD_PROPERTY_GALLERY = 'UPLOAD_PROPERTY_GALLERY';
export const UPLOAD_PROPERTY_GALLERY_DONE = 'UPLOAD_PROPERTY_GALLERY_DONE';
export const REMOVE_PROPERTY_GALLERY = 'REMOVE_PROPERTY_GALLERY';
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const FETCH_PROPERTY = 'FETCH_PROPERTY';
export const FETCH_PROPERTY_DATA_DONE = 'FETCH_PROPERTY_DATA_DONE';
export const FETCH_PROPERTY_DONE = 'FETCH_PROPERTY_DONE';
export const FETCH_PROPERTY_FAIL = 'FETCH_PROPERTY_FAIL';
export const RESET_PROPERTY = 'RESET_PROPERTY';
export const UPDATE_PROPERTY_TIME = 'UPDATE_PROPERTY_TIME';

// -- LIST
export const FETCH_PROPERTY_LIST_DONE = 'FETCH_PROPERTY_LIST_DONE';
export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const FETCH_PROPERTY_LOADMORE = 'FETCH_PROPERTY_LOADMORE';
export const FETCH_PROPERTY_LOADMORE_DONE = 'FETCH_PROPERTY_LOADMORE_DONE';
export const FETCH_PROPERTY_SHOWLESS = 'FETCH_PROPERTY_SHOWLESS';
export const UPDATE_PROPERTY_INDEX = 'UPDATE_PROPERTY_INDEX';

// Sync Action Creator
// -- Create/Update --
export function changeType(typeId, oldData) {
    const validateData = {
        type: typeId,
        projectId: typeId === 'project' ? '' : 'location',
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        title: oldData.title,
        purpose: oldData.purpose,
        sellPrice: oldData.sellPrice,
        rentPrice: oldData.rentPrice,
        areaSize: oldData.areaSize,
        gallery: oldData.gallery,
    };
    const errors = validate(validateData, schema);

    return {
        type: SET_PROPERTY_TYPE,
        typeId,
        errors,
    };
}
export function changeProject(proId, oldData) {
    const validateData = {
        type: oldData.type,
        projectId: proId,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        title: oldData.title,
        purpose: oldData.purpose,
        sellPrice: oldData.sellPrice,
        rentPrice: oldData.rentPrice,
        areaSize: oldData.areaSize,
        gallery: oldData.gallery,
    };
    const errors = validate(validateData, schema);
    return {
        type: SET_PROPERTY_PROJECT,
        proId,
        errors,
    };
}
export function changeGeo(lat, lng, oldData) {
    const validateData = {
        type: oldData.type,
        projectId: oldData.projectId,
        lat,
        lng,
        title: oldData.title,
        purpose: oldData.purpose,
        sellPrice: oldData.sellPrice,
        rentPrice: oldData.rentPrice,
        areaSize: oldData.areaSize,
        gallery: oldData.gallery,
    };
    const errors = validate(validateData, schema);

    return {
        type: SET_PROPERTY_GEO,
        lat,
        lng,
        errors,
    };
}
export function resetData() {
    return {
        type: RESET_PROPERTY,
    };
}
export function fetch() {
    return {
        type: FETCH_PROPERTY,
    };
}
export function fetchGallery() {
    return {
        type: UPLOAD_PROPERTY_GALLERY,
    };
}
export function fetchGalleryDone(imgURL, index, oldData) {
    let galList = [...oldData.gallery];
    galList[index] = imgURL;
    const validateData = {
        type: oldData.type,
        projectId: oldData.projectId,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        title: oldData.title,
        purpose: oldData.purpose,
        sellPrice: oldData.sellPrice,
        rentPrice: oldData.rentPrice,
        areaSize: oldData.areaSize,
        gallery: galList,
    };
    const errors = validate(validateData, schema);

    return {
        type: UPLOAD_PROPERTY_GALLERY_DONE,
        imgURL,
        index,
        errors: errors,
    };
}
export function removeGallery(index, oldData) {
    let galList = [...oldData.gallery];
    galList.splice(index, 1);
    const validateData = {
        type: oldData.type,
        projectId: oldData.projectId,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        title: oldData.title,
        purpose: oldData.purpose,
        sellPrice: oldData.sellPrice,
        rentPrice: oldData.rentPrice,
        areaSize: oldData.areaSize,
        gallery: galList,
    };
    const errors = validate(validateData, schema);

    return {
        type: REMOVE_PROPERTY_GALLERY,
        index,
        errors,
    };
}
export function fetchDataDone(data) {
    return {
        type: FETCH_PROPERTY_DATA_DONE,
        data,
    };
}
export function fetchDone(message) {
    return {
        type: FETCH_PROPERTY_DONE,
        message,
    };
}
export function fetchFail(message) {
    return {
        type: FETCH_PROPERTY_FAIL,
        message,
    };
}
export function update(oldData, fieldName, newValue) {
    let validateData = {
        type: oldData.type,
        projectId: oldData.projectId,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        title: oldData.title,
        purpose: oldData.purpose,
        sellPrice: oldData.sellPrice,
        rentPrice: oldData.rentPrice,
        areaSize: oldData.areaSize,
        gallery: oldData.gallery,
    };

    if (fieldName === 'title') {
        validateData.title = newValue;
    }
    if (fieldName === 'purpose') {
        validateData.purpose = newValue;
    }
    if (fieldName === 'sellPrice') {
        validateData.sellPrice = newValue;
        if (oldData.purpose === 'sell') {
            validateData.rentPrice = 'xxx';
        }
    }
    if (fieldName === 'rentPrice') {
        validateData.rentPrice = newValue;
        if (oldData.purpose === 'rent') {
            validateData.sellPrice = 'xxx';
        }
    }
    if (fieldName === 'areaSize') {
        validateData.areaSize = newValue;
    }

    const errors = validate(validateData, schema);

    return {
        type: UPDATE_PROPERTY,
        fieldName,
        newValue,
        errors: errors,
    };
}

// -- LIST --
export function fetchListDone(data) {
    return {
        type: FETCH_PROPERTY_LIST_DONE,
        list: data.list,
        packages: data.packages,
    };
}
export function deleteData(uid) {
    return {
        type: DELETE_PROPERTY,
        uid,
    };
}
export function fetchLoadmore() {
    return {
        type: FETCH_PROPERTY_LOADMORE,
    };
}
export function showless() {
    return {
        type: FETCH_PROPERTY_SHOWLESS,
    };
}
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_PROPERTY_LOADMORE_DONE,
        list: data.list,
        packages: data.packages,
    };
}
export function updateIndex(startIndex, endIndex) {
    return {
        type: UPDATE_PROPERTY_INDEX,
        startIndex,
        endIndex,
    };
}
export function updateTime(field, value) {
    return {
        type: UPDATE_PROPERTY_TIME,
        field,
        value,
    };
}

function slugify(text) {
    const uri = text.toString().toLowerCase().trim()
        // eslint-disable-next-line no-useless-escape
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
        .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single _
        .replace(/^-+|-+$/g, ''); // remove leading, trailing -

    return encodeURIComponent(uri);
}

// Async Action Creator
export function postGallery(file, index, oldData) {
    if (!file) {
        return {
            type: FETCH_PROPERTY_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return function(dispatch) {
        dispatch(fetchGallery(index));
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef
            .child(`properties/${uuidv1()}-${file.name}`)
            .put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then(downloadURL => {
                dispatch(fetchGalleryDone(downloadURL, index, oldData));
            });
        });
    };
}

export function getList(status, start, end) {
    return async function(dispatch) {
        dispatch(fetch());

        const getList = functions.httpsCallable('propertyBackendGetList');
        try {
            const result = await getList({ status, start, end });
            console.log(result.data);
            const output = {
                packages: result.data.packages,
                list: result.data.list,
            };
            dispatch(fetchListDone(output));
        } catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
export function postCreate(data) {
    return async dispatch => {
        dispatch(fetch());

        const adminCreator = firebase.auth().currentUser;
        if (adminCreator) {
            const ref = firebase.firestore().collection('properties');
            const urlString = slugify(data.title);

            const inputData = data;
            inputData.url = urlString;
            const isLocationBase = inputData.projectId === 'location';
            let projData = '';
            if (!isLocationBase) {
                const projRef = await firebase
                    .firestore()
                    .collection('projects')
                    .doc(inputData.projectId.uid)
                    .get();
                projData = projRef.data();
            }
            const latitude = isLocationBase ? inputData.location.lat : projData.location._lat;
            const longitude = isLocationBase ? inputData.location.lng : projData.location._long;
            // Type Search
            let typeSearch = '';
            if (isLocationBase) {
                typeSearch = (inputData.bedroomNum === '' && inputData.toiletNum === '') ? 'land' : 'house';
            } else {
                typeSearch = projData.typeId;
            }

            // Location Text
            Geocode.setLanguage('en');
            const locationEn = await Geocode.fromLatLng(latitude, longitude);
            const addressEn = locationEn.results[0].formatted_address;
            Geocode.setLanguage('th');
            const locationTh = await Geocode.fromLatLng(latitude, longitude);
            const addressTh = locationTh.results[0].formatted_address;
            Geocode.setLanguage('zh');
            const locationCh = await Geocode.fromLatLng(latitude, longitude);
            const addressCh = locationCh.results[0].formatted_address;
            Geocode.setLanguage('ja');
            const locationJp = await Geocode.fromLatLng(latitude, longitude);
            const addressJp = locationJp.results[0].formatted_address;

            const locationText = {
                th: addressTh,
                en: addressEn,
                ch: addressCh,
                jp: addressJp,
            };

            inputData.projectId = isLocationBase ? '' : inputData.projectId.uid;
            inputData.location = new firebase.firestore.GeoPoint(latitude, longitude);
            inputData.locationText = locationText;
            inputData.typeSearch = typeSearch;
            inputData.sellPrice =
                inputData.purpose === 'sell' || inputData.purpose === 'sellrent'
                    ? Number(inputData.sellPrice)
                    : '';
            inputData.rentPrice =
                inputData.purpose === 'rent' || inputData.purpose === 'sellrent'
                    ? Number(inputData.rentPrice)
                    : '';
            inputData.landSize = Number(inputData.landSize);
            inputData.areaSize = Number(inputData.areaSize);
            inputData.sellPricePerArea =
                inputData.purpose === 'sell' || inputData.purpose === 'sellrent'
                    ? Number(inputData.sellPrice) / Number(inputData.areaSize)
                    : '';
            inputData.rentPricePerArea =
                inputData.purpose === 'rent' || inputData.purpose === 'sellrent'
                    ? Number(inputData.rentPrice) / Number(inputData.areaSize)
                    : '';
            inputData.floor = Number(inputData.floor);
            inputData.createdAt = moment().valueOf();
            inputData.updatedAt = moment().valueOf();
            inputData.lastSeen = moment().valueOf();
            console.log(inputData);

            return ref
                .add(inputData)
                .then(() => {
                    dispatch(fetchDone('Property created successfully.'));
                    dispatch(
                        showSnack('success', 'Property created successfully.'),
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            dispatch(fetchFail('You are not sign in.'));
        }
    };
}

export function getEdit(uid) {
    return function(dispatch) {
        dispatch(fetch());

        const articleGetList = functions.httpsCallable('propertyBackendGetId');
        return articleGetList(uid)
            .then(function(result) {
                dispatch(fetchDataDone(result.data.data));
            })
            .catch(function(error) {
                console.log(error);
                dispatch(fetchFail(error.message));
            });
    };
}

export function postEdit(uid, data) {
    return async dispatch => {
        dispatch(fetch());

        const ref = firebase.firestore().collection('properties');
        const urlString = slugify(data.title);
        
        const inputData = data;
        inputData.url = urlString;
        const isLocationBase = inputData.projectId === 'location';
        let projData = '';
        if (!isLocationBase) {
            const projRef = await firebase
                .firestore()
                .collection('projects')
                .doc(inputData.projectId.uid)
                .get();
            projData = projRef.data();
        }
        const latitude = isLocationBase ? inputData.location.lat : projData.location._lat;
        const longitude = isLocationBase ? inputData.location.lng : projData.location._long;
        // Type Search
        let typeSearch = '';
        if (isLocationBase) {
            typeSearch = (inputData.bedroomNum === '' && inputData.toiletNum === '') ? 'land' : 'house';
        } else {
            typeSearch = projData.typeId;
        }

        // Location Text
        Geocode.setLanguage('en');
        const locationEn = await Geocode.fromLatLng(latitude, longitude);
        const addressEn = locationEn.results[0].formatted_address;
        Geocode.setLanguage('th');
        const locationTh = await Geocode.fromLatLng(latitude, longitude);
        const addressTh = locationTh.results[0].formatted_address;
        Geocode.setLanguage('zh');
        const locationCh = await Geocode.fromLatLng(latitude, longitude);
        const addressCh = locationCh.results[0].formatted_address;
        Geocode.setLanguage('ja');
        const locationJp = await Geocode.fromLatLng(latitude, longitude);
        const addressJp = locationJp.results[0].formatted_address;

        const locationText = {
            th: addressTh,
            en: addressEn,
            ch: addressCh,
            jp: addressJp,
        };

        inputData.projectId = isLocationBase ? '' : inputData.projectId.uid;
        inputData.location = new firebase.firestore.GeoPoint(latitude, longitude);
        inputData.locationText = locationText;
        inputData.typeSearch = typeSearch;
        inputData.sellPrice =
            inputData.purpose === 'sell' || inputData.purpose === 'sellrent'
                ? Number(inputData.sellPrice)
                : '';
        inputData.rentPrice =
            inputData.purpose === 'rent' || inputData.purpose === 'sellrent'
                ? Number(inputData.rentPrice)
                : '';
        inputData.landSize = Number(inputData.landSize);
        inputData.areaSize = Number(inputData.areaSize);
        inputData.sellPricePerArea =
            inputData.purpose === 'sell' || inputData.purpose === 'sellrent'
                ? Number(inputData.sellPrice) / Number(inputData.areaSize)
                : '';
        inputData.rentPricePerArea =
            inputData.purpose === 'rent' || inputData.purpose === 'sellrent'
                ? Number(inputData.rentPrice) / Number(inputData.areaSize)
                : '';
        inputData.floor = Number(inputData.floor);
        inputData.updatedAt = moment().valueOf();
        console.log(inputData);

        return ref
            .doc(uid)
            .set(inputData, { merge: true })
            .then(() => {
                dispatch(fetchDone('Property updated successfully.'));
                dispatch(
                    showSnack('success', 'Property updated successfully.'),
                );
            })
            .catch(error => {
                console.log(error);
            });
    };
}

export function postDelete(uid) {
    return function(dispatch) {
        const docRef = firebase
            .firestore()
            .collection('properties')
            .doc(uid);
        return docRef
            .delete()
            .then(function() {
                dispatch(deleteData(uid));
                dispatch(showSnack('success', 'Delete property successfully.'));
            })
            .catch(function(error) {
                console.error('Error removing document: ', error.message);
            });
    };
}

export function postClose(uid) {
    return function(dispatch) {
        const docRef = firebase
            .firestore()
            .collection('properties')
            .doc(uid);
        return docRef
            .set({ status: 'close' }, { merge: true })
            .then(() => {
                dispatch(fetchDone('Property updated successfully.'));
                dispatch(getList('open'));
                dispatch(
                    showSnack('success', 'Property updated successfully.'),
                );
            })
            .catch(error => {
                console.log(error);
            });
    };
}

export function postBringBack(uid) {
    return function(dispatch) {
        const docRef = firebase
            .firestore()
            .collection('properties')
            .doc(uid);
        return docRef
            .set({ status: 'open' }, { merge: true })
            .then(() => {
                dispatch(fetchDone('Property updated successfully.'));
                dispatch(getList('close'));
                dispatch(
                    showSnack('success', 'Property updated successfully.'),
                );
            })
            .catch(error => {
                console.log(error);
            });
    };
}

export function postPackage(packageData, uid) {
    return function(dispatch) {
        const docRef = firebase
            .firestore()
            .collection('properties')
            .doc(uid);
        const data = {
            status: 'promote',
            packageId: packageData.uid,
            reachAfterPromote: 0,
            maximumReachPromote: packageData.views,
            startPromoteAt: moment().valueOf(),
            updatedAt: moment().valueOf(),
        };

        return docRef
            .set(data, { merge: true })
            .then(() => {
                dispatch(fetchDone('Property promoted successfully.'));
                dispatch(getList('open'));
                dispatch(
                    showSnack('success', 'Property promoted successfully.'),
                );
            })
            .catch(error => {
                console.log(error);
            });
    };
}
