import { connect } from 'react-redux';
import {
    changeType,
    changeProject,
    changeGeo,
    update,
    postCreate,
    postGallery,
    removeGallery,
} from 'actions/property';

import ProjectCreate from 'views/Property/Create';

const mapStateToProps = state => ({
    loading: state.property.loading,
    data: state.property.data,
    isValid: state.property.isValid,
    successMessage: state.property.successMessage,
});

const mapDispatchToProps = {
    changeType,
    changeProject,
    changeGeo,
    update,
    postCreate,
    postGallery,
    removeGallery,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreate);
