import { connect } from 'react-redux';
import { updateSignIn, signIn, postSignIn, resetAuth } from 'actions/auth';
import SignIn from 'views/SignIn';

const mapStateToProps = state => ({
    loading: state.auth.loading,
    data: state.auth.data,
    errors: state.auth.errors,
    isValid: state.auth.isValid,
    touched: state.auth.touched,
    message: state.auth.message,
});

const mapDispatchToProps = {
    updateSignIn,
    signIn,
    postSignIn,
    resetAuth
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignIn);

