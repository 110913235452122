import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import UserList from './List';
import LoadingUserList from './LoadingList';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const UsersTable = props => {
    const { className, users, loading, toggleStatus, postDeleteUser } = props;
    const classes = useStyles();

    return (
        <Card className={clsx(classes.root, className)}>
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Registration date</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { loading ? 
                                    _.times(5, i =>
                                        <LoadingUserList key={i} />
                                    )
                                    :
                                    users.map(user => (
                                        <UserList 
                                            loading={loading} 
                                            user={user} 
                                            key={user.uid} 
                                            toggleStatus={toggleStatus} 
                                            postDeleteUser={postDeleteUser}
                                        />
                                    )) 
                                }
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
        </Card>
    );
};

UsersTable.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    users: PropTypes.array.isRequired
};

export default UsersTable;
