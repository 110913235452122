import { connect } from 'react-redux';
import {
    updateAds,
    postImage,
    postAdsEdit,
    getAdsEdit,
} from 'actions/ads';

import AdsEdit from 'views/Ads/Edit';

const mapStateToProps = state => ({
    loading: state.ads.loading,
    ads: state.ads.ads,
    errors: state.ads.errors,
    touched: state.ads.touched,
    isValid: state.ads.isValid,
    successMessage: state.ads.successMessage,
    failMessage: state.ads.failMessage,
});

const mapDispatchToProps = {
    updateAds,
    postImage,
    postAdsEdit,
    getAdsEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdsEdit);

