import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    }
}));

const UsersToolbar = props => {
    const { className, resetUserCreate, ...rest } = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.row}>
                <span className={classes.spacer} />
                <Button
                    color="primary"
                    variant="contained"
                    component={CustomRouterLink}
                    to="/users/create"
                    onClick={() => resetUserCreate()}
                >
                    <AddIcon style={{ padding: 0, marginRight: 4, width: 16, height: 16 }} /> create new user
                </Button>
            </div>
        </div>
    );
};

UsersToolbar.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string
};

export default UsersToolbar;
