import { connect } from 'react-redux';
import {
    getList,
    postDelete,
    resetData,
    querySearch,
    loadmore,
    showless,
    queryByIndex,
} from 'actions/articles';
import { 
    showSnack 
} from 'actions/main';
import TagList from 'views/Article/Draft';

const mapStateToProps = state => ({
    loading: state.articles.loading,
    list: state.articles.list,
    search: state.articles.search,
    searchList: state.articles.searchList,
    loadingMore: state.articles.loadingMore,
    showLoadingMore: state.articles.showLoadingMore,
    startIndex: state.articles.startIndex,
    endIndex: state.articles.endIndex,
    numberOfDocs: state.articles.numberOfDocs,
});

const mapDispatchToProps = {
    getList,
    postDelete,
    resetData,
    querySearch,
    loadmore,
    showless,
    queryByIndex,
    showSnack, 
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagList);