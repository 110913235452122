import validate from 'validate.js';
import firebase from 'common/firebase';

// Action
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const IS_LOADING = 'IS_LOADING';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const LOADING_DONE = 'LOADING_DONE';
export const LOADING_FAIL = 'LOADING_FAIL';

const schema = {
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    },
    confirmPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    }
};

// Sync Action Creator
export function updatePassword(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;
    const errors = validate(oldData, schema);

    return {
        type: UPDATE_PASSWORD,
        fieldName,
        newValue,
        errors: errors,
    };
};
export function isLoading() {
    return {
        type: IS_LOADING,
    };
};
export function resetPassword() {
    return {
        type: RESET_PASSWORD,
    };
};
export function loadingDone(successMessage) {
    return {
        type: LOADING_DONE,
        successMessage,
    };
};
export function loadingFail(failMessage) {
    return {
        type: LOADING_FAIL,
        failMessage,
    };
};

// Async Action Creator
export function postResetPassword(data, actionCode, continueUrl, lang) {
    if (data.password !== data.confirmPassword){
        return {
            type: LOADING_FAIL,
            failMessage: 'Password and confirm password does not match.',
        };
    }

    return function (dispatch) {
        dispatch(isLoading());
        return firebase.auth().verifyPasswordResetCode(actionCode).then(function (email) {
            const accountEmail = email;
            
            // Change Password 
            firebase.auth().confirmPasswordReset(actionCode, data.password).then(function (resp) {
                // Sign In
                firebase.auth().signInWithEmailAndPassword(accountEmail, data.password).catch(function (error) {
                    dispatch(loadingFail(error.message));
                });
            }).catch(function (error) {
                dispatch(loadingFail(error.message));
            });
        }).catch(function (error) {
            dispatch(loadingFail(error.message));
        });
    };
}