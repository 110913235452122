/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import ChatCard from 'components/Chat';
import WhtLogo from 'common/aalv.png';

const styles = (theme) => ({
    card: {
        textAlign: 'center',
        minHeight: 'calc(100vh - 219px)',
        padding: '50px 0 50px 0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 419px)',
            padding: '50px 16px',
        },
    },
    container: {
        minHeight: 'calc(100vh - 219px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: 0,
        },
    },
    button: {
        width: 384,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
});

function MyInbox(props) {
    const {
        classes,
        // lang,
        loading,
        list,
        chat,
        getMyInbox,
        resetData,
        getChat,
        updateInbox,
        updateChat,
        postChat,
        updateInboxSeen,
    } = props;

    React.useEffect(() => {
        getMyInbox();
    }, [getMyInbox]);

    return (
        <Container className={classes.container}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
            >
                <Grid item xs={12}>
                    <Box mt={4}>
                        <ChatCard
                            loading={loading}
                            list={list}
                            chat={chat}
                            getMyInbox={getMyInbox}
                            resetData={resetData}
                            getChat={getChat}
                            userId="admin"
                            userImg={WhtLogo}
                            userName="All About Living"
                            updateInbox={updateInbox}
                            updateChat={updateChat}
                            postChat={postChat}
                            updateInboxSeen={updateInboxSeen}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

MyInbox.propTypes = {
    // lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired,
    chat: PropTypes.object.isRequired,
    getMyInbox: PropTypes.func.isRequired,
    resetData: PropTypes.func.isRequired,
    getChat: PropTypes.func.isRequired,
    updateInbox: PropTypes.func.isRequired,
    updateChat: PropTypes.func.isRequired,
    postChat: PropTypes.func.isRequired,
    updateInboxSeen: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(MyInbox));
