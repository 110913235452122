import { connect } from 'react-redux';
import {
    changeLang,
    update,
    postCreate,
} from 'actions/developer';

import DeveloperCreate from 'views/Developer/Create';

const mapStateToProps = state => ({
    loading: state.developer.loading,
    lang: state.developer.lang,
    data: state.developer.data,
    isValid: state.developer.isValid,
    successMessage: state.developer.successMessage,
});

const mapDispatchToProps = {
    changeLang,
    update,
    postCreate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeveloperCreate);

