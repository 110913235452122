import validate from 'validate.js';
import firebase from 'common/firebase';
import { showSnack } from './main';
import moment from 'moment';

const functions = firebase.app().functions('asia-east2');

const schema = {
    nameTh: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
// -- CREATE/UPDATE
export const SET_TAG_LANG = 'SET_TAG_LANG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const FETCH_TAG = 'FETCH_TAG';
export const FETCH_TAG_DATA_DONE = 'FETCH_TAG_DATA_DONE';
export const FETCH_TAG_DONE = 'FETCH_TAG_DONE';
export const FETCH_TAG_FAIL = 'FETCH_TAG_FAIL';
export const RESET_TAG = 'RESET_TAG';

// -- LIST
export const FETCH_TAG_LIST_DONE = 'FETCH_TAG_LIST_DONE';
export const DELETE_TAG = 'DELETE_TAG';
export const FETCH_TAG_LOADMORE = 'FETCH_TAG_LOADMORE';
export const FETCH_TAG_LOADMORE_DONE = 'FETCH_TAG_LOADMORE_DONE';
export const FETCH_TAG_SHOWLESS = 'FETCH_TAG_SHOWLESS';
export const UPDATE_TAG_INDEX = 'UPDATE_TAG_INDEX';
export const FETCH_TAG_INDEX_DONE = 'FETCH_TAG_INDEX_DONE';
export const UPDATE_TAG_SEARCH = 'UPDATE_TAG_SEARCH';

// Sync Action Creator
// -- Create/Update --
export function changeSearch(text) {
    return {
        type: UPDATE_TAG_SEARCH,
        text,
    };
};
export function changeLang(lang) {
    return {
        type: SET_TAG_LANG,
        lang
    };
};
export function resetTag() {
    return {
        type: RESET_TAG,
    };
};
export function fetchTag() {
    return {
        type: FETCH_TAG,
    };
};
export function fetchTagDataDone(tag) {
    return {
        type: FETCH_TAG_DATA_DONE,
        tag,
    };
};
export function fetchTagDone(message) {
    return {
        type: FETCH_TAG_DONE,
        message,
    };
};
export function fetchTagFail(message) {
    return {
        type: FETCH_TAG_FAIL,
        message,
    };
};
export function updateTag(oldData, lang, newValue) {
    oldData.name[lang] = newValue;

    const validateData = {
        nameTh: oldData.name.th,
    }
    const errors = validate(validateData, schema);

    return {
        type: UPDATE_TAG,
        lang,
        newValue,
        errors: errors,
    };
};

// -- LIST --
export function fetchTagListDone(data) {
    return {
        type: FETCH_TAG_LIST_DONE,
        tagList: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function fetchIndexDone(data) {
    return {
        type: FETCH_TAG_INDEX_DONE,
        tagList: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function deleteTag(uid) {
    return {
        type: DELETE_TAG,
        uid,
    };
};
export function fetchLoadmore() {
    return {
        type: FETCH_TAG_LOADMORE,
    };
};
export function showless() {
    return {
        type: FETCH_TAG_SHOWLESS,
    };
};
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_TAG_LOADMORE_DONE,
        list: data.list,
        numberOfDocs: data.numberOfDocs,
    };
};
export function updateIndex(startIndex, endIndex) {
    return {
        type: UPDATE_TAG_INDEX,
        startIndex, 
        endIndex,
    };
};

// Async Action Creator
export function getTagList() {
    return async function (dispatch) {
        dispatch(fetchTag());

        const tagGetList = functions.httpsCallable('tagBackendGetList');
        try {
            const result = await tagGetList();
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchTagListDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchTagFail(error.message));
        }
    };
}

export function postTagCreate(data) {
    return function (dispatch) {
        dispatch(fetchTag());

        const tagRef = firebase.firestore().collection("tags");
        tagRef.where("search", "==", data.search).get().then((searchResults) => {
            if (searchResults.size > 0){
                dispatch(showSnack('fail', 'Tag name has already been created"'));
                return dispatch(fetchTagFail('Tag name has already been created"'));
            }

            const inputData = {
                ...data,
                timestamp: moment().valueOf(),
            }

            return tagRef.add(inputData).then(() => {
                dispatch(getTagList());
                dispatch(fetchTagDone('Tag created successfully.'));
                dispatch(showSnack('success', 'Tag created successfully.'));
            }).catch((error) => {
                console.log(error);
            });
        }).catch((error) => {
            console.log(error);
        });
    };
}

export function getTagEdit(uid) {
    return function (dispatch) {
        dispatch(fetchTag());
        const docRef = firebase.firestore().collection("tags").doc(uid);

        return docRef.get().then((doc) => {
            if (doc.exists) {
                const allData = doc.data();
                const tag = {
                    name: allData.name,
                };
                dispatch(fetchTagDataDone(tag));
            } else {
                // doc.data() will be undefined in this case
                dispatch(showSnack('fail', "No such document!"));
                dispatch(fetchTagFail("No such document!"));
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
            dispatch(fetchTagFail(error.message));
        });
    };
}

export function postTagEdit(uid, data) {
    return function (dispatch) {
        dispatch(fetchTag());

        const tagRef = firebase.firestore().collection("tags");
        tagRef.where("search", "==", data.search).get().then((searchResults) => {
            let resultLength = 0;
            searchResults.forEach(function (doc) {
                if (doc.id !== uid){
                    resultLength+=1;
                }
            });

            if (resultLength > 0) {
                dispatch(showSnack('fail', 'Tag name has already been created"'));
                return dispatch(fetchTagFail('Tag name has already been created"'));
            }

            return tagRef.doc(uid).set(data, { merge: true }).then(() => {
                dispatch(fetchTagDone('Tag updated successfully.'));
                dispatch(showSnack('success', 'Tag updated successfully.'));
            }).catch((error) => {
                console.log(error);
                dispatch(fetchTagFail(error.message));
            });
        }).catch((error) => {
            console.log(error);
        });
    };
}

export function postDeleteTag(uid) {
    return function (dispatch) {
        const tagRef = firebase.firestore().collection("tags").doc(uid);
        return tagRef.delete().then(function () {
            dispatch(deleteTag(uid));
            dispatch(showSnack('success', 'Delete tag successfully.'));
        }).catch(function (error) {
            console.error("Error removing document: ", error.message);
        });
    };
};

export function loadmore(lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const tagLoadmore = functions.httpsCallable('tagBackendLoadmore');
        try {
            const result = await tagLoadmore(lastVisibleUid);
            const output = {
                numberOfDocs: result.data.numberOfDocs,
                list: result.data.list,
            }
            dispatch(fetchLoadmoreDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchTagFail(error.message));
        }
    };
}

export function queryByIndex(startIndex,endIndex) {
    return async (dispatch) => {
        dispatch(fetchTag());
        if (endIndex <= startIndex){
            dispatch(showSnack("fail","End index is less than start index."));
            dispatch(fetchTagFail("endIndex < startIndex"));
        }else{
            const tagQuery = functions.httpsCallable('tagBackendQuery');
            try {
                const result = await tagQuery({ startIndex, endIndex });
                const output = {
                    numberOfDocs: result.data.numberOfDocs,
                    list: result.data.list,
                }
                dispatch(updateIndex(startIndex, endIndex));
                dispatch(fetchIndexDone(output));
            }
            catch (error) {
                console.log(error);
                dispatch(fetchTagFail(error.message));
            }
        }
    };
}