import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TableCell, TableRow, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        left: -12,
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(2),
        width: 24,
        height: 24,
        textTransform: 'uppercase',
        backgroundColor: '#00abbd',
        display: 'inline-block',
        border: '1px solid #dddddd',
        boxSizing: 'content-box',
    },
}));

const UsersTableList = props => {
    const classes = useStyles();
    const { data } = props;

    return (
        <TableRow className={classes.tableRow} hover>
            <TableCell>{data.createdAt}</TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <Typography variant="body1">{data.createdBy}</Typography>
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <Typography variant="body1">{data.packageName}</Typography>
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <Typography variant="body1">{data.price}</Typography>
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.nameContainer}>
                    <Typography variant="body1">{data.propId}</Typography>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default UsersTableList;
