import firebase from 'common/firebase';
import { showSnack } from './main';
const functions = firebase.app().functions('asia-east2');

// Action
export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const FETCH_USER_LIST_DONE = 'FETCH_USER_LIST_DONE';
export const FETCH_USER_LIST_FAIL = 'FETCH_USER_LIST_FAIL';
export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';
export const DELETE_USER = 'DELETE_USER';

// Sync Action Creator
export function fetchUserList() {
    return {
        type: FETCH_USER_LIST,
    };
};
export function fetchUserListDone(userList) {
    return {
        type: FETCH_USER_LIST_DONE,
        userList,
    };
};
export function fetchUserListFail(message) {
    return {
        type: FETCH_USER_LIST_FAIL,
        message,
    };
};
export function changeUserStatus(uid,newStatus) {
    return {
        type: CHANGE_USER_STATUS,
        uid,
        newStatus,
    };
};
export function deleteUser(uid) {
    return {
        type: DELETE_USER,
        uid,
    };
};

// Async Action Creator
export function getUserList() {
    return function (dispatch) {
        dispatch(fetchUserList());
        const adminGetList = functions.httpsCallable('adminGetList');
        return adminGetList().then(function (result) {
            dispatch(fetchUserListDone(result.data));
        }).catch(function (error) {
            console.log(error);
            dispatch(fetchUserListFail(error.message));
        });
    };
}

export function toggleStatus(uid, newStatus) {
    const data = {
        uid, 
        disabled: newStatus,
    };

    return function (dispatch) {
        dispatch(changeUserStatus(uid, newStatus));
        const adminToggleDisable = functions.httpsCallable('adminToggleDisable');
        return adminToggleDisable(data).then(function (result) {
            if (result.data === true){
                dispatch(showSnack('success', 'Change user status successfully.'));
            }
        }).catch(function (error) {
            console.log(error);
        });
    };
};

export function postDeleteUser(uid) {
    const data = { uid };
    
    return function (dispatch) {
        const adminDelete = functions.httpsCallable('adminDelete');
        return adminDelete(data).then(function (result) {
            if (result.data === true) {
                dispatch(deleteUser(uid));
                dispatch(showSnack('success', 'Delete user successfully.'));
            }
        }).catch(function (error) {
            console.log(error);
        });
    };
};