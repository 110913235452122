import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import CloseIcon from './close.svg';

const styles = (theme) => ({
    navbar: {
        position: 'relative',
        boxSizing: 'border-box',
        width: '100%',
        display: 'block',
        backgroundColor: "#fff",
        borderBottom: 'solid 1px #e9e9e9',
        padding: '12px 10px 12px 10px',
        [theme.breakpoints.down('sm')]: {
            padding: '12px 9px',
        },
    },
    close: {
        verticalAlign: 'middle',
        width: 20,
        height: 20,
        cursor: 'pointer',
    },
    iconButton: {
        padding: 4,
    },
    logoInner: {
        textAlign: 'left',
    },
    title: {
        textAlign: 'center',
    },
});

function Navbar(props) {
    const { classes, title, onClick } = props;

    return (
        <div className={classes.navbar}>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item xs={1}>
                    <Box className={classes.logoInner} >
                        <IconButton className={classes.iconButton} onClick={onClick}>
                            <img className={classes.close} src={CloseIcon} alt="close" />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box className={classes.title} >
                        <Typography variant="subtitle1" color="textPrimary">
                            {title}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

Navbar.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

export default withStyles(styles)(Navbar);