import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import AccountDetails from './components/UpdateDetails';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const UserCreate = (props) => {
    const classes = useStyles();
    const {
        loading,
        user,
        userErrors,
        userTouched,
        userFailMessage,
        userSuccessMessage,
        userIsValid,
        profileIsLoading,
        updateUserEdit,
        postUserEdit,
        postProfile,
        removeProfile,
        getUserEdit,
        location,
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];

    React.useEffect(() => {
        getUserEdit(uid);
    }, [getUserEdit, uid]);

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    xs={12}
                >
                    <AccountDetails 
                        loading={loading}
                        touched={userTouched}
                        errors={userErrors}
                        isValid={userIsValid}
                        user={user}
                        updateFunc={updateUserEdit}
                        postUpdateFunc={postUserEdit}
                        userFailMessage={userFailMessage}
                        userSuccessMessage={userSuccessMessage}
                        profileIsLoading={profileIsLoading}
                        postProfile={postProfile}
                        removeProfile={removeProfile}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(UserCreate);
