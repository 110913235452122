import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    tag: {
        padding: '0 2px',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            padding: 0,
        }
    }
});

function Tag(props) {
    const { text, classes } = props;
    return (
        <Chip
            className={classes.tag}
            color='primary'
            size="small"
            label={text}
        />
    );
}

Tag.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
};

export default withStyles(styles)(Tag);