import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography
} from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  progress: {
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 2,
  },
}));

const Password = props => {
  const classes = useStyles();
  const { 
    loading,
    touched,
    errors,
    uid,
    data,
    update,
    postUpdate,
    isValid,
    successMessage,
    failMessage,
  } = props;

  const handleChange = event => {
    update(data, event.target.name, event.target.value);
  };

  const hasError = field => {
    if (_.has(errors, field) && _.has(touched, field)) {
      return touched[field] && errors[field] ? true : false;
    }
    return false;
  };

  const handleSubmit = () => {
    data.uid = uid;
    postUpdate(data);
  };

  return (
    <Card className={classes.root}>
      <form>
        <CardHeader
          subheader="Change password"
          title="Password"
        />
        <Divider />
        <CardContent style={{ position: 'relative' }}>
          {loading && <CircularProgress className={classes.progress} />}
          <TextField
            fullWidth
            label="Old Password"
            name="oldPassword"
            onChange={handleChange}
            type="password"
            value={data.oldPassword}
            variant="outlined"
            error={hasError("oldPassword")}
            helperText={
              hasError("oldPassword") ? errors.oldPassword[0] : null
            }
            disabled={loading}
            autoComplete="new-password"
          />
          <TextField
            fullWidth
            label="New Password"
            name="newPassword"
            style={{ marginTop: '1rem' }}
            onChange={handleChange}
            type="password"
            value={data.newPassword}
            variant="outlined"
            error={hasError('newPassword')}
            helperText={
              hasError('newPassword') ? errors.newPassword[0] : null
            }
            disabled={loading}
            autoComplete="new-password"
          />
          <TextField
            fullWidth
            label="Confirm New Password"
            name="confirmNewPassword"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={data.confirmNewPassword}
            variant="outlined"
            error={hasError('confirmNewPassword')}
            helperText={
              hasError('confirmNewPassword') ? errors.confirmNewPassword[0] : null
            }
            disabled={loading}
            autoComplete="new-password"
          />
          {
            failMessage !== '' &&
            <Box mt={2}>
              <Typography
                style={{ color: "#de0032" }}
                variant="caption"
              >
                {failMessage}
              </Typography>
            </Box>
          }
          {
            successMessage !== '' &&
            <Box mt={2}>
              <Typography
                color="primary"
                variant="caption"
              >
                {successMessage}
              </Typography>
            </Box>
          }
        </CardContent>
        <Divider />
        <CardActions style={{padding: 16}}>
          <Button
            color="primary"
            variant="outlined"
            disabled={!isValid || loading}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};


export default Password;
