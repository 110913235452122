export const SHOW_SNACK = 'SHOW_SNACK';
export const HIDE_SNACK = 'HIDE_SNACK';

// Sync Action Creator
export function showSnack(status,message) {
    return {
        type: SHOW_SNACK,
        status, 
        message,
    };
};
export function hideSnack() {
    return {
        type: HIDE_SNACK,
    };
};