import {
    UPDATE_ADS,
    FETCH_ADS,
    FETCH_ADS_DONE,
    FETCH_ADS_DATA_DONE,
    FETCH_ADS_FAIL,
    RESET_ADS,
    FETCH_ADS_LIST_DONE,
    CHANGE_ADS_STATUS,
    DELETE_ADS,
    FETCH_ADS_LOADMORE,
    FETCH_ADS_LOADMORE_DONE,
    FETCH_ADS_SHOWLESS,
    UPDATE_ADS_INDEX,
    FETCH_ADS_INDEX_DONE,
} from '../actions/ads';

const initialState = {
    loading: false,
    adsList: [],
    loadingMore: false,
    showLoadingMore: true,
    startIndex: 1,
    endIndex: 25,
    numberOfDocs: 0,
    ads: {
        page: '',
        position: '',
        desktop: '',
        mobile: '',
        url: '',
    },
    errors: {},
    touched: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const adsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ADS:
            return {
                ...state,
                ads: {
                    ...state.ads,
                    [action.fieldName]: action.newValue
                },
                touched: {
                    ...state.touched,
                    [action.fieldName]: true,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case FETCH_ADS:
            return {
                ...state,
                loading: true,
                loadingMore: false,
                showLoadingMore: true,
            };
        case FETCH_ADS_LOADMORE:
            return {
                ...state,
                loadingMore: true,
            };
        case FETCH_ADS_DATA_DONE:    
            return {
                ...state,
                loading: false,
                ads: action.ads,
            };
        case FETCH_ADS_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_ADS_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        case RESET_ADS:
            return {
                ...initialState,
                ads: {
                    page: '',
                    position: '',
                    desktop: '',
                    mobile: '',
                    url: '',
                },
            };
        case FETCH_ADS_LIST_DONE:
            return {
                ...state,
                loading: false,
                loadingMore: false,
                startIndex: 1,
                showLoadingMore: action.adsList.length < action.numberOfDocs,
                numberOfDocs: action.numberOfDocs,
                endIndex: action.adsList.length,
                adsList: action.adsList,
            };
        case FETCH_ADS_INDEX_DONE:
            return {
                ...state,
                loading: false,
                loadingMore: false,
                showLoadingMore: (state.startIndex + action.adsList.length - 1) < action.numberOfDocs,
                numberOfDocs: action.numberOfDocs,
                endIndex: (state.startIndex + action.adsList.length - 1),
                adsList: action.adsList,
            };
        case FETCH_ADS_LOADMORE_DONE:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: (state.endIndex + action.list.length) < state.numberOfDocs,
                endIndex: state.endIndex + action.list.length,
                adsList: [
                    ...state.adsList,
                    ...action.list,
                ],
                numberOfDocs: action.list.length < 25 ? state.endIndex + action.list.length : action.numberOfDocs,
            };
        case FETCH_ADS_SHOWLESS:
            return {
                ...state,
                loadingMore: false,
                showLoadingMore: true,
                adsList: [
                    ...state.adsList.slice(0, state.adsList.length - 25),
                ],
                endIndex: state.endIndex - 25,
            };
        case UPDATE_ADS_INDEX:
            return {
                ...state,
                startIndex: action.startIndex,
            };
        case CHANGE_ADS_STATUS:
            const index = state.adsList.findIndex(ads => ads.uid === action.uid);
            let adsList = [...state.adsList];
            adsList[index] = {
                ...adsList[index],
                active: action.newStatus
            };
            return {
                ...state,
                adsList
            };
        case DELETE_ADS:
            const index2 = state.adsList.findIndex(ads => ads.uid === action.uid);
            let newadsList = [...state.adsList];
            newadsList.splice(index2, 1);

            return {
                ...state,
                adsList: newadsList
            };
        default:
            return state;
    }
};
export default adsReducer;
