import { connect } from 'react-redux';
import {
    reset,
    update,
    getData,
    postEdit,
    changeLang,
} from 'actions/term';

import Page from 'views/Term/Condition';

const mapStateToProps = state => ({
    loading: state.term.loading,
    lang: state.term.lang,
    data: state.term.data,
    errors: state.term.errors,
    isValid: state.term.isValid,
});

const mapDispatchToProps = {
    reset,
    update,
    getData,
    postEdit,
    changeLang,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Page);

