import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    CardActions,
    Typography,
    Tooltip,
    Paper,
    InputBase,
    Divider,
    IconButton,
    Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(() => ({
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    actions: {
        justifyContent: 'center',
    },
    pageForm: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #eeeeee',
        boxShadow: 'none',
    },
    input: {
        marginLeft: 8,
        flex: 1,
        '& input':{
            textAlign: 'center',
            width: 100,
        }
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        width: 1,
        margin: 4,
    },
}));

const Loadmore = props => {
    const classes = useStyles();
    const {
        startIndex,
        endIndex,
        listLength,
        showLoadingMore,
        showlessDisabled,
        queryFunc,
        loadmoreFunc,
        showlessFunc,
    } = props;

    const [state, setState] = useState({
        open: false,
        start: 0,
        end: 0,
    });

    const handleToggle = () => {
        setState({
            ...state,
            open: !state.open,
            start: startIndex,
            end: endIndex,
        });
    };

    const handleShowMore = () => {
        loadmoreFunc();
        setState({
            ...state,
            open: false,
        });
    };
    const handleShowLess = () => {
        showlessFunc();
        setState({
            ...state,
            open: false,
        });
    };

    const handleChange = (event) => {
        console.log(event.target.name, event.target.value);
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = () => {
        queryFunc(Number(state.start), Number(state.end));
        setState({
            ...state,
            open: false,
        });
    };

    return (
        <CardActions className={classes.actions}>
            <Tooltip title="Show more">
                <div>
                    <IconButton 
                        aria-controls="simple-menu" 
                        aria-haspopup="true" 
                        onClick={handleShowMore}
                        disabled={!showLoadingMore}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </div>
            </Tooltip>
            { 
                state.open ?
                    <Paper className={classes.pageForm}>
                        <Tooltip title="Close">
                            <IconButton 
                                className={classes.iconButton} 
                                aria-label="close"
                                onClick={handleToggle}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider className={classes.divider} orientation="vertical" />
                        <InputBase
                            className={classes.input}
                            placeholder="Start"
                            type="number"
                            inputProps={{ 'aria-label': 'startIndex' }}
                            value={state.start}
                            name="start"
                            onChange={handleChange}
                        />
                        <Box>
                            <Typography variant="body1">
                                -
                            </Typography>
                        </Box>
                        <InputBase
                            className={classes.input}
                            placeholder="End"
                            type="number"
                            inputProps={{ 'aria-label': 'endIndex' }}
                            name="end"
                            value={state.end}
                            onChange={handleChange}
                        />
                        <Divider className={classes.divider} orientation="vertical" />
                        <Tooltip title="Query now">
                            <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={handleSubmit}>
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                    :
                    <Typography variant="caption" onClick={handleToggle}>
                        {startIndex} - {endIndex} of {listLength}
                    </Typography>
            }
            <Tooltip title="Show less">
                <div>
                    <IconButton 
                        aria-controls="simple-menu" 
                        aria-haspopup="true" 
                        onClick={handleShowLess}
                        disabled={showlessDisabled}
                    >
                        <ExpandLessIcon />
                    </IconButton>
                </div>
            </Tooltip>
        </CardActions>
    );
};

export default Loadmore;
