import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Divider, AppBar, Tabs, Tab, Button, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    spacer: {
        flexGrow: 1,
    },
    importButton: {
        marginRight: theme.spacing(1),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
    tabs: {
        marginTop: 16,
        marginBottom: 16,
    },
    appbar: {
        boxShadow: 'none',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    tab: {
        textTransform: 'none',
    },
}));

const UsersToolbar = props => {
    const {
        className,
        tabActive,
        resetData,
        changeSearch,
        start,
        end,
        update,
        ...rest
    } = props;

    const classes = useStyles();
    let tabNumber = 0;
    if (tabActive === 'promoted') {
        tabNumber = 1;
    }
    if (tabActive === 'soldrented') {
        tabNumber = 2;
    }

    const tabs = [
        {
            title: 'Normal',
            href: '/properties/normal',
        },
        {
            title: 'Promoted',
            href: '/properties/promoted',
        },
        {
            title: 'Sold/Rented',
            href: '/properties/soldrented',
        },
    ];

    const startDate = moment.unix(start / 1000).format('MM/DD/YYYY');
    const endDate = moment.unix(end / 1000).format('MM/DD/YYYY');

    function handleDateChange(field, date) {
        update(field, moment(date).valueOf());
    }

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <div className={classes.row}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box mr={2}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Start Date"
                            value={startDate}
                            onChange={date => handleDateChange('start', date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Box>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="End Date"
                        value={endDate}
                        onChange={date => handleDateChange('end', date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <span className={classes.spacer} />
                <Button
                    color="primary"
                    variant="contained"
                    component={CustomRouterLink}
                    onClick={() => resetData()}
                    to="/properties/create">
                    <AddIcon
                        style={{
                            padding: 0,
                            marginRight: 4,
                            width: 16,
                            height: 16,
                        }}
                    />{' '}
                    Create Property
                </Button>
            </div>
            <div className={classes.tabs}>
                <AppBar
                    position="static"
                    color="default"
                    className={classes.appbar}>
                    <Tabs
                        indicatorColor="primary"
                        value={tabNumber}
                        aria-label="simple tabs example">
                        {tabs.map((item, key) => (
                            <Tab
                                classes={{
                                    root: classes.tab,
                                }}
                                key={item.title}
                                component={CustomRouterLink}
                                id={`simple-tab-${key}`}
                                aria-controls={`simple-tabpanel-${key}`}
                                label={item.title}
                                to={item.href}
                            />
                        ))}
                    </Tabs>
                </AppBar>
                <Divider />
            </div>
        </div>
    );
};

UsersToolbar.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
};

export default UsersToolbar;
