import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    OutlinedInput,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
} from '@material-ui/core';
import Types from 'common/propertyRoomNumber';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
        marginRight: 16,
        // marginBottom: 16,
    },
}));

const CategoryCom = (props) => {
    const classes = useStyles();
    const { 
        value,
        loading,
        changeValue,
    } = props;

    const handleChange = event => {
        changeValue(event.target.value);
    };

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor="bedroomNum">
                Number of Bedrooms
            </InputLabel>
            <Select
                disabled={loading}
                value={value}
                onChange={handleChange}
                input={<OutlinedInput labelWidth={labelWidth} name="bedroomNum" id="bedroomNum" />}
            >
                {
                    Types.type.map((item) => (
                        <MenuItem key={item.uid} value={item.uid}>{item.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

CategoryCom.propTypes = {
    value: PropTypes.string.isRequired,
};

export default CategoryCom;
