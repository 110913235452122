import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import Loadmore from 'components/Loadmore';
import List from './List';
import LoadingList from './LoadingList';

const useStyles = makeStyles(() => ({
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const UsersTable = props => {
    const { 
        list,
        resetData,
        loading,
        postDelete,
        loadingMore,
        showLoadingMore,
        startIndex,
        endIndex,
        numberOfDocs,
        loadmoreFunc,
        showlessFunc,
        queryFunc,
        showSnack, 
    } = props;

    const classes = useStyles();

    return (
        <Card
            className={classes.root}
        >
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Cover</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Reachs</TableCell>
                                    <TableCell>Unique Reachs</TableCell>
                                    <TableCell>Language</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ?
                                    _.times(10, i =>
                                        <LoadingList key={i} />
                                    )
                                    :
                                    list.map(item => (
                                        <List
                                            data={item}
                                            key={item.uid}
                                            resetData={resetData}
                                            postDelete={postDelete}
                                            showSnack={showSnack}
                                        />
                                    ))
                                }
                                {
                                    loadingMore &&
                                    _.times(10, i =>
                                        <LoadingList key={i} />
                                    )
                                }
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            {(!loading && !loadingMore) &&
                <Loadmore
                    startIndex={startIndex}
                    endIndex={endIndex}
                    listLength={numberOfDocs}
                    loadmoreFunc={loadmoreFunc}
                    showlessFunc={showlessFunc}
                    queryFunc={queryFunc}
                    showlessDisabled={list.length <= 25}
                    showLoadingMore={showLoadingMore}
                />
            }
        </Card>
    );
};

export default UsersTable;
