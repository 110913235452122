import {
    SET_LANG,
    RESET_TERM,
    UPDATE_TERM,
    FETCH_TERM,
    FETCH_TERM_DATA_DONE,
    FETCH_TERM_DONE,
    FETCH_TERM_FAIL,
} from '../actions/term';

const initialState = {
    loading: false,
    lang: 'th',
    data: {
        th: '',
        en: '',
        ch: '',
        jp: '',
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const termReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case RESET_TERM:
            return {
                ...initialState,
                data: {
                    th: '',
                    en: '',
                    ch: '',
                    jp: '',
                },
            };
        case UPDATE_TERM:            
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.lang]: action.newValue,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case FETCH_TERM:
            return {
                ...state,
                loading: true,
            };
        case FETCH_TERM_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_TERM_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_TERM_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default termReducer;
