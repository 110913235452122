import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';

const styles = theme => ({
    button: {
        height: 56,
        paddingLeft: 24,
        paddingRight: 24,
        [theme.breakpoints.down('sm')]: {
            height: 48,
        }
    },
    icon:{
        marginLeft: 6,
    }
});

function MyButton(props) {
    const { color, children, classes, onClick } = props;
    return (
        <Button
            color={color}
            className={classes.button}
            onClick={onClick}
        >
            {children} <ArrowForward className={classes.icon}/>
        </Button>
    );
}

MyButton.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    color: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

MyButton.defaultProps = {
    onClick() { console.log('Button Click'); }
};

export default withStyles(styles)(MyButton);