import {
    UPDATE_USER_EDIT,
    FETCH_USER_EDIT,
    FETCH_USER_EDIT_DONE,
    FETCH_USER_EDIT_FAIL,
    FETCH_USER_EDIT_PROFILE,
    FETCH_USER_EDIT_PROFILE_DONE,
    FETCH_USER_EDIT_PROFILE_FAIL,
    UPDATE_USER_EDIT_PROFILE,
    REMOVE_USER_EDIT_PROFILE,
    RESET_USER_EDIT,
    FETCH_USER_EDIT_DATA_DONE,
} from '../actions/userUpdate';

const initialState = {
    loading: false,
    user: {
        fname: '',
        lname: '',
        desc: '',
        email: '',
        password: '',
        profile: null,
    },
    userErrors: {},
    userIsValid: false,
    userTouched: {},
    userSuccessMessage: '',
    userFailMessage: '',
    profileIsLoading: false,
    profileSuccessMessage: '',
    profileFailMessage: '',
};

const userEditReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_USER_EDIT:
        return {
            ...state,
            user: {
                ...state.user,
                [action.fieldName]: action.newValue
            },
            userTouched: {
                ...state.userTouched,
                [action.fieldName]: true,
            },
            userErrors: action.errors,
            userIsValid: !action.errors,
        };
    case FETCH_USER_EDIT:
        return {
            ...state,
            loading: true,
        };
    case FETCH_USER_EDIT_DONE:
        return {
            ...state,
            loading: false,
            userSuccessMessage: action.message,
        };
    case FETCH_USER_EDIT_FAIL:
        return {
            ...state,
            loading: false,
            userFailMessage: action.message,
        };
    case FETCH_USER_EDIT_PROFILE:
        return {
            ...state,
            profileIsLoading: true,
        };
    case FETCH_USER_EDIT_PROFILE_DONE:
        return {
            ...state,
            profileIsLoading: false,
            profileSuccessMessage: action.message,
            profileFailMessage: '',
        };
    case FETCH_USER_EDIT_PROFILE_FAIL:
        return {
            ...state,
            profileIsLoading: false,
            profileSuccessMessage: '',
            profileFailMessage: action.message,
        };
    case UPDATE_USER_EDIT_PROFILE:
        return {
            ...state,
            profileIsLoading: false,
            user: {
                ...state.user,
                profile: action.url,
            },
        };
    case REMOVE_USER_EDIT_PROFILE:
        return {
            ...state,
            user: {
                ...state.user,
                profile: null,
            },
        };
    case RESET_USER_EDIT:
        return {
            ...initialState,
            user: {
                fname: '',
                lname: '',
                desc: '',
                email: '',
                password: '',
                profile: null,
            },
        };
    case FETCH_USER_EDIT_DATA_DONE:
        return {
            ...state,
            loading: false,
            user: {
                ...action.user,
                password: '',
            },
        };
    default:
        return state;
    }
};
export default userEditReducer;